import styled from "styled-components";

const Button = styled.button`
  border-radius: ${props => props.theme.radius.element};
  background-color: ${props => props.theme.colors[props.color]};
  border: 1px solid ${props => props.theme.colors[props.color]};
  color: ${props => props.theme.colors.neutral5};
  padding: 0.5em;
  cursor: pointer;
  text-align: center;
  display: ${props => props.display};
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  min-width: 2.25em;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.neutral5};
  }

  &:disabled,
  &[disabled] {
    background-color: ${props => props.theme.colors.neutral2};
    border-color: ${props => props.theme.colors.neutral2};
    cursor: default;
  }
`;

Button.displayName = "Button";
Button.defaultProps = {
  color: "primary",
  display: "flex",
  width: "100%",
};

export default Button;
