import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import { GoKebabVertical, GoChevronDown, GoChevronUp } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardHeader,
  Row,
  Col,
  Card,
  DropdownItem,
  DropdownMenu,
  CardBody,
  HeadingFive,
  HeadingSix,
  InteractiveWrapper,
  LinkButton,
  Paragraph,
  EditableText,
  Text,
  CardFooter,
  Flex,
} from "../atomic";
import { update as updateMatch } from "../../actions/matches";
import { create as createFinal } from "../../actions/finals";
import Score from "../Rounds/Score";
import MatchTeam from "../Rounds/MatchTeam";
import RoundTypes from "../../../shared/constants/RoundTypes";
import { getTeams } from "../../selectors/teams";
import { getIsClubOwner } from "../../selectors/user";
import FinalInlineDate from "./FinalInlineDate";
import {
  hasMatchBeenPlayed,
  getWinner,
  getFinalsTypeFromNumberOfMatches,
} from "../../../shared/utils/matches"; //eslint-disable-line
import useBoolean from "../../hooks/useBoolean";

const DivisionFinal = ({ isMobile, title, rounds, visibleRounds: visibleRoundsFromProps }) => {
  const { clubId, seasonId, competitionId } = useParams();
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const { value: isEditing, setTrue: setEditing, setFalse: setUnediting } = useBoolean(false);
  const { value: isPending, setTrue: setPending, setFalse: setResolved } = useBoolean(false);
  const {
    value: shouldCreateNextRound,
    setTrue: setShouldCreateNextRound,
    setFalse: setShouldNotCreateNextRound,
  } = useBoolean(false);

  const { value: isOpen, toggle: toggleOpen, setTrue: openFinal } = useBoolean(true);

  const [edits, setEdits] = useState({});

  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const editFinal = useCallback(() => {
    setEditing();
    openFinal();
  }, [setEditing, openFinal]);

  const cancelEdit = useCallback(() => {
    setEdits({});
    setUnediting();
  }, [setUnediting]);

  const updateEditableMatch = useCallback(match => {
    setEdits(newEdits => ({ ...newEdits, [match.id]: match }));
  }, []);

  const dispatchCreateNextFinal = useCallback(
    async final => {
      await dispatch(
        createFinal(clubId, seasonId, competitionId, {
          ...final,
          type: RoundTypes.elimination,
        }),
      );
    },
    [dispatch, clubId, seasonId, competitionId],
  );

  const gutter = useMemo(() => (isMobile ? 0 : 8), [isMobile]);
  const HomeWrapper = isMobile ? React.Fragment : CardHeader;
  const AwayWrapper = isMobile ? React.Fragment : CardFooter;

  useEffect(() => {
    if (shouldCreateNextRound) {
      const playableRounds = rounds.filter(round => round.id != null);
      const lastRound = playableRounds[playableRounds.length - 1];
      const { matches } = lastRound;
      const winners = matches.map(getWinner).filter(Boolean);

      const nextDateMoment = matches
        .reduce((date, match) => {
          const nextMatchDate = moment(match.time).add(1, "week");

          return date == null || nextMatchDate.isAfter(date) ? nextMatchDate : date;
        }, null)
        .startOf("day");

      const { years: year, months: month, date } = nextDateMoment.toObject();

      if (matches.length > 1 && winners.length === matches.length) {
        const order = playableRounds.length + 1;

        const newMatches = matches.reduce(
          (matchAggregate, match) => {
            const winner = getWinner(match);
            const lastMatch = matchAggregate[matchAggregate.length - 1];
            const { homeTeam, awayTeam } = lastMatch;

            if (!homeTeam || !awayTeam) {
              const aggregateWithoutLast = matchAggregate.slice(0, -1);

              const updatedMatch = {
                name: match.name,
                field: match.field,
                time: moment(match.time)
                  .set({ year, month, date })
                  .toDate(),
                ...lastMatch,
              };

              if (!homeTeam) {
                updatedMatch.homeTeam = winner;
                updatedMatch.homeTeamId = winner.id;
              } else if (!awayTeam) {
                updatedMatch.awayTeam = winner;
                updatedMatch.awayTeamId = winner.id;
              }

              return [...aggregateWithoutLast, updatedMatch];
            }

            return [
              ...matchAggregate,
              {
                name: match.name,
                field: match.field,
                time: moment(match.time)
                  .set({ year, month, date })
                  .toDate(),
                homeTeam: winner,
                homeTeamId: winner.id,
              },
            ];
          },
          [{}],
        );

        dispatchCreateNextFinal({
          name: `Finals Week ${order}`,
          date: nextDateMoment.toDate(),
          order,
          matches: newMatches.map(match => {
            const { homeTeam, awayTeam, ...filteredMatch } = match;
            return filteredMatch;
          }, []),
        });
      }

      setShouldNotCreateNextRound();
    }
  }, [
    shouldCreateNextRound,
    dispatchCreateNextFinal,
    rounds,
    setShouldCreateNextRound,
    setShouldNotCreateNextRound,
  ]);

  const dispatchUpdateMatches = useCallback(async () => {
    const matches = Object.values(edits);

    const promises = matches.map(match =>
      dispatch(updateMatch(clubId, seasonId, competitionId, match.roundId, match)),
    );

    setPending();
    await Promise.all(promises).finally(setResolved);

    setShouldCreateNextRound();
    cancelEdit();
  }, [
    edits,
    dispatch,
    clubId,
    seasonId,
    competitionId,
    cancelEdit,
    setPending,
    setResolved,
    setShouldCreateNextRound,
  ]);

  let winner = null;

  const lastRound = rounds[rounds.length - 1];

  if (lastRound && lastRound.matches.length === 1) {
    const lastMatch = lastRound.matches[lastRound.matches.length - 1];

    if (lastMatch && hasMatchBeenPlayed(lastMatch)) {
      winner = getWinner(lastMatch);
    }
  }

  const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  const HeadingTextContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
  `;

  const ButtonsContainer = styled.div`
    display: flex;
    align-items: "center";
    & > * {
      margin-left: 0.25em;
    }
  `;

  const isDisabled = !isEditing || isPending;
  const visibleRounds = visibleRoundsFromProps != null ? visibleRoundsFromProps : rounds;

  return (
    <Card border="neutral4">
      <InteractiveWrapper as={CardHeader} onClick={!isEditing ? toggleOpen : undefined}>
        <Heading>
          <HeadingTextContainer>
            <HeadingFive>{title}</HeadingFive>
            {isPending ? (
              <BeatLoader size={10} color="#333333" />
            ) : (
              isAuthenticated && (
                <DropdownMenu Trigger={<GoKebabVertical />} position="left">
                  <DropdownItem onClick={editFinal}>Edit</DropdownItem>
                </DropdownMenu>
              )
            )}
          </HeadingTextContainer>
          {!isEditing && (isOpen ? <GoChevronUp /> : <GoChevronDown />)}
          {isEditing && (
            <ButtonsContainer>
              <LinkButton onClick={cancelEdit}>Cancel</LinkButton>
              <Button color="success" onClick={dispatchUpdateMatches}>
                Save
              </Button>
            </ButtonsContainer>
          )}
        </Heading>
      </InteractiveWrapper>
      {isOpen && (
        <CardBody>
          {!isMobile && (
            <Row gutter={gutter}>
              {visibleRounds.map(round => (
                <Col key={`heading-${round.id}`} xs={4} gutter={gutter}>
                  <Row>
                    <Col xs={12}>
                      <Paragraph color="neutral2">
                        {getFinalsTypeFromNumberOfMatches(round.matches.length)}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
              ))}
              <Col xs={4} gutter={8}>
                <HeadingSix>Winner</HeadingSix>
              </Col>
            </Row>
          )}
          <Row middle="xs" gutter={gutter}>
            {visibleRounds.map(round => {
              const { id, matches } = round;

              return (
                <Col key={`round-container-${id}`} xs={12} sm={4} gutter={gutter}>
                  {matches.map(rawMatch => {
                    const match = edits[rawMatch.id] ?? rawMatch;

                    return (
                      <Row key={match.id}>
                        <Col xs={12}>
                          <Flex justifyContent="space-between">
                            {match.time != null && (
                              <FinalInlineDate
                                isDisabled={isDisabled}
                                date={match.time}
                                onChange={time => updateEditableMatch({ ...match, time })}
                              />
                            )}
                            {match.field != null && (
                              <EditableText
                                align="right"
                                isDisabled={isDisabled}
                                value={match.field}
                                onChange={field => updateEditableMatch({ ...match, field })}
                              >
                                {match.field}
                              </EditableText>
                            )}
                          </Flex>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <Row>
                              <Col xs={12}>
                                <HomeWrapper>
                                  <Row>
                                    <Col xs={10}>
                                      {!match.homeTeam && (
                                        <Text color="neutral3">Winner of Previous Round</Text>
                                      )}
                                      {match.homeTeam && (
                                        <MatchTeam
                                          isDisabled={isDisabled}
                                          team={match.homeTeam}
                                          match={match}
                                          teams={teams}
                                          onTeamUpdate={
                                            isDisabled
                                              ? null
                                              : updatedTeam =>
                                                  updatedTeam != null
                                                    ? updateEditableMatch({
                                                        ...match,
                                                        homeTeamId: updatedTeam.id,
                                                        homeTeam: updatedTeam,
                                                      })
                                                    : null
                                          }
                                          position="right"
                                        />
                                      )}
                                    </Col>
                                    <Col xs={2}>
                                      {match.homeTeam && (
                                        <Flex justifyContent="flex-end">
                                          <Score
                                            isDisabled={isDisabled}
                                            score={match.homeScore}
                                            onSubmit={async homeScore =>
                                              updateEditableMatch({
                                                ...match,
                                                homeScore,
                                              })
                                            }
                                          />
                                        </Flex>
                                      )}
                                    </Col>
                                  </Row>
                                </HomeWrapper>
                              </Col>
                              <Col xs={12}>
                                <AwayWrapper>
                                  <Row>
                                    <Col xs={10}>
                                      {!match.awayTeam && (
                                        <Text color="neutral3">Winner of Previous Round</Text>
                                      )}
                                      {match.awayTeam && (
                                        <MatchTeam
                                          isDisabled={isDisabled}
                                          team={match.awayTeam}
                                          match={match}
                                          teams={teams}
                                          onTeamUpdate={updatedTeam =>
                                            updatedTeam != null
                                              ? updateEditableMatch({
                                                  ...match,
                                                  awayTeamId: updatedTeam.id,
                                                  awayTeam: updatedTeam,
                                                })
                                              : null
                                          }
                                          position="right"
                                        />
                                      )}
                                    </Col>
                                    <Col xs={2}>
                                      {match.awayTeam && (
                                        <Flex justifyContent="flex-end">
                                          <Score
                                            isDisabled={isDisabled}
                                            score={match.awayScore}
                                            onSubmit={async awayScore =>
                                              updateEditableMatch({
                                                ...match,
                                                awayScore,
                                              })
                                            }
                                          />
                                        </Flex>
                                      )}
                                    </Col>
                                  </Row>
                                </AwayWrapper>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              );
            })}
            <Col xs={isMobile ? null : 4} gutter={gutter}>
              {winner && <HeadingFive color="primary">{winner.name}</HeadingFive>}
              {!winner && <HeadingFive color="neutral3">Division Winner</HeadingFive>}
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};

DivisionFinal.propTypes = {
  title: PropTypes.string,
  rounds: PropTypes.array,
  visibleRounds: PropTypes.array,
  isMobile: PropTypes.bool,
};

DivisionFinal.defaultProps = {
  title: null,
  rounds: [],
  visibleRounds: null,
  isMobile: false,
};

export default DivisionFinal;
