import React, { useCallback, useRef } from "react";
import PointAdjustmentForm from "./PointAdjustmentForm";
import { LinkButton, Overlay } from "../atomic";

import useBoolean from "../../hooks/useBoolean";

function Points({ id, children, isDisabled, unadjustedPoints, onSubmit }) {
  const { value, setTrue, setFalse } = useBoolean(false);
  const triggerRef = useRef();

  const submitAndDismiss = useCallback(
    adjustment => {
      if (onSubmit != null) {
        onSubmit(adjustment);
        setFalse();
      }
    },
    [onSubmit, setFalse],
  );

  const resetAndDismiss = useCallback(
    async callback => {
      // eslint-disable-next-line
      const confirmed = confirm(
        "This will undo all changes you've made to this team's points. Are you sure you want to do this?",
      );

      if (confirmed) {
        await submitAndDismiss(0);
      }

      callback();
    },
    [submitAndDismiss],
  );

  return (
    <>
      <LinkButton
        id={id}
        color={isDisabled ? undefined : unadjustedPoints !== children ? "primary" : "secondary"}
        onClick={setTrue}
        padding={0}
        isDisabled={isDisabled}
        ref={triggerRef}
      >
        {children}
      </LinkButton>
      {value && (
        <>
          <Overlay onClick={setFalse} />
          <PointAdjustmentForm
            id="point-adjustment-form"
            defaultPoints={unadjustedPoints}
            points={children}
            triggerRef={triggerRef}
            onReset={resetAndDismiss}
            onSubmit={submitAndDismiss}
          />
        </>
      )}
    </>
  );
}

export default Points;
