import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GoThreeBars } from "react-icons/go";

const MobileHeader = styled.div`
  width: 100%;
  padding: 0.25em 0.5em;
  background-color: ${props => props.theme.colors[props.color]};
  position: fixed;
  color: ${props => props.theme.colors.neutral5};
  font-size: 2em;
  display: flex;
  align-items: center;
  z-index: 2;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;
MobileHeader.displayName = "MobileHeader";
MobileHeader.defaultProps = {
  color: "neutral5",
};

const Spacer = styled.div`
  width: 100%;
  height: 3rem;
  transition: height 150ms ease-in-out;
  z-index: 3;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    position: relative;
    transition: none;
  }
`;
Spacer.displayName = "Spacer";

const ClickOff = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${props => props.theme.colors.neutral5};
  opacity: 0.5;
  transition: opacity 150ms ease-in-out;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;
ClickOff.displayName = "ClickOff";

const FixedContainer = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  height: inherit;
  z-index: 1;
`;
FixedContainer.displayName = "FixedContainer";

const NavContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.colors.neutral5};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
  background-color: ${props => props.theme.colors[props.color]};
`;
NavContainer.displayName = "NavContainer";

const NavSection = styled.div``;
NavSection.displayName = "NavSection";

class NavBar extends Component {
  state = {
    expanded: false,
  };

  expandNav = () => {
    this.setState({ expanded: true });
  };

  hideNav = () => {
    this.setState({ expanded: false });
  };

  render() {
    const { LeftItems, RightItems, color, ...rest } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <Spacer expanded={expanded}>
          <FixedContainer>
            <NavContainer color={color} {...rest}>
              <NavSection>{LeftItems.map(Item => Item)}</NavSection>
              <NavSection>{RightItems.map(Item => Item)}</NavSection>
            </NavContainer>
          </FixedContainer>
          {expanded && <ClickOff onClick={this.hideNav} />}
        </Spacer>
        <MobileHeader color={color}>
          <GoThreeBars onClick={this.expandNav} />
        </MobileHeader>
      </>
    );
  }
}

NavBar.propTypes = {
  LeftItems: PropTypes.arrayOf(PropTypes.node),
  RightItems: PropTypes.arrayOf(PropTypes.node),
  color: PropTypes.string,
};

NavBar.defaultProps = {
  LeftItems: [],
  RightItems: [],
  color: "neutral1",
};

export default NavBar;
