import React from "react";
import PropTypes from "prop-types";
import { ListItem, SmallButton } from "../atomic";

const RemovableItemListItem = ({ children, onRemove }) => (
  <ListItem onClick={onRemove} border="neutral4">
    {children}
    <SmallButton color="error" onClick={e => e.preventDefault()}>
      -
    </SmallButton>
  </ListItem>
);

RemovableItemListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onRemove: PropTypes.func,
};

RemovableItemListItem.defaultProps = {
  children: () => {},
  onRemove: () => {},
};

export default RemovableItemListItem;
