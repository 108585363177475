import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, InputGroup, Input, InputAddon } from "../atomic";

// eslint-disable-next-line
class AutocompleteInput extends Component {
  static propTypes = {
    innerRef: PropTypes.func,
    onKeyPress: PropTypes.func,
    onAddClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    innerRef: () => {},
    onKeyPress: () => {},
    onAddClick: () => {},
    disabled: false,
  };

  render() {
    const { innerRef, onKeyPress, onAddClick, disabled, ...rest } = this.props;

    return (
      <InputGroup>
        <Input ref={innerRef} onKeyPress={onKeyPress} {...rest} />
        <InputAddon
          as={Button}
          color="primary"
          border="primary"
          ignoreDisabled
          onClick={onAddClick}
          disabled={disabled}
        >
          +
        </InputAddon>
      </InputGroup>
    );
  }
}

export default AutocompleteInput;
