import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import promise from "redux-promise-middleware";
import curriedApiToState from "./middleware/api-to-state";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

const middleware = applyMiddleware(routerMiddleware(history), thunk, promise, curriedApiToState);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export default preloadedState => {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(middleware),
  );

  return store;
};
