import React from "react";
import styled from "styled-components";
import Button from "./Button";

const RadioGroupItemRadio = styled.input`
  display: none;
`;
RadioGroupItemRadio.defaultProps = {
  type: "radio",
};
const RadioGroupItemLabel = styled(Button)`
  padding: 0.5em 1em;

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
RadioGroupItemLabel.defaultProps = {
  as: "label",
  color: "primary",
};

const RadioGroupItem = ({ children, checked, ...rest }) => (
  <RadioGroupItemLabel color={checked ? "primary" : "neutral3"}>
    <RadioGroupItemRadio defaultChecked={checked} {...rest} />
    {children}
  </RadioGroupItemLabel>
);

export default RadioGroupItem;
