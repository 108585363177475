import { useCallback, useState } from "react";

/**
 * Helper hook to toggle between boolean values
 */
function useGetElementRect() {
  const [rect, setRect] = useState(null);

  const getElementWidth = useCallback(node => {
    if (node != null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [getElementWidth, rect];
}

export default useGetElementRect;
