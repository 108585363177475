import React, { useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Link as LinkText,
  HeadingThree,
} from "../components/atomic";
import SignupForm from "../components/SignupForm";
import { create } from "../actions/users";
import { login } from "../actions/auth";
import { getUser } from "../selectors/user";

function Signup() {
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const signupAndLogin = useCallback(
    async newUser => {
      await dispatch(create(newUser));

      const auth = { email: newUser.email, password: newUser.password };
      await dispatch(login(auth));
    },
    [dispatch],
  );

  if (user.id) {
    return <Redirect push to="/" />;
  }

  return (
    <Container>
      <Row center="xs">
        <Col xs={12} md={10}>
          <HeadingThree align="center">Sign up for your account</HeadingThree>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} md={10}>
          <Card>
            <CardBody>
              <SignupForm onSubmit={signupAndLogin} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} md={10} align="right">
          <LinkText as={Link} to="/login" align="right">
            Already have an account? Log in
          </LinkText>
        </Col>
      </Row>
    </Container>
  );
}

export default connect()(Signup);
