import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Team from "../../components/Teams/Team";

const DraggableTeam = ({ teamID, index, isDragDisabled, ...rest }) => (
  <Draggable draggableId={teamID} index={index} isDragDisabled={isDragDisabled}>
    {provided => (
      <Team
        teamID={teamID}
        draggable={{
          ...provided.draggableProps,
          ...provided.dragHandleProps,
          ref: provided.innerRef,
        }}
      />
    )}
  </Draggable>
);

export default DraggableTeam;
