import React, { useCallback, useMemo } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { updateBulk } from "../actions/teams";
import ImportTeamsModal from "../components/modals/import-teams/ImportTeamsModal";
import { getDivisions, getTeams } from "../selectors/teams";
import { getIsClubOwner } from "../selectors/user";
import { getCompetitionByID } from "../selectors/competitions";
import { getEditCompetitionLink } from "../routes";
import { hasEnoughFieldsAndTimeslots } from "../../shared/utils/competitions";
import {
  Row,
  Col,
  Button,
  EditableText,
  ListItemGroup,
  Paragraph,
  DisabledParagraph,
  Link as LinkText,
  Card,
  CardBody,
  ExpandableCard
} from "../components/atomic";
import DraggableTeam from "./drag-containers/DraggableTeam";
import Team from "../components/Teams/Team";
import useBoolean from "../hooks/useBoolean";

const NO_DIVISION_ID = "none";

function Teams() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { clubId, seasonId, competitionId } = useParams();

  const competition = useSelector(getCompetitionByID(competitionId));
  const teams = useSelector(getTeams);
  const teamsByDivision = useSelector(getDivisions);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));
  const divisions = Object.keys(teamsByDivision);
  divisions.sort();

  const { value: isImportModalShown, toggle: toggleImportModal } = useBoolean(
    false
  );

  const dispatchUpdateBulk = useCallback(
    teamsToUpdate => {
      dispatch(updateBulk(clubId, seasonId, competitionId, teamsToUpdate));
    },
    [dispatch, clubId, seasonId, competitionId]
  );

  const getUpdateDivisionsCallback = useCallback(
    teams => division => {
      const newTeams = teams.map(team => ({ ...team, division }));
      dispatchUpdateBulk(newTeams);
    },
    [dispatchUpdateBulk]
  );

  const onDragEnd = useCallback(
    ({ destination, source, draggableId }) => {
      if (!destination) {
        return;
      }

      const destinationDroppableId =
        destination.droppableId === NO_DIVISION_ID
          ? ""
          : destination.droppableId;
      const sourceDroppableId =
        source.droppableId === NO_DIVISION_ID ? "" : source.droppableId;

      if (
        destinationDroppableId === sourceDroppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const team = {
        ...teamsByDivision[sourceDroppableId][source.index],
        division: destinationDroppableId,
        order: destination.index + 1
      };

      // Re-order
      if (sourceDroppableId === destinationDroppableId) {
        const division = teamsByDivision[destinationDroppableId].filter(
          _team => _team.id !== team.id
        );
        division.splice(destination.index, 0, team);

        const divisionToUpdate = division.map((_team, index) => ({
          ..._team,
          order: index + 1
        }));
        dispatchUpdateBulk(divisionToUpdate);
      } else {
        const sourceDivision = teamsByDivision[sourceDroppableId]
          .filter(_team => _team.id !== draggableId)
          .map((_team, index) => ({ ..._team, order: index + 1 }));

        const destinationDivision = Array.from(
          teamsByDivision[destinationDroppableId]
        );
        destinationDivision.splice(destination.index, 0, team);

        const destinationDivisionToUpdate = destinationDivision.map(
          (_team, index) => ({
            ..._team,
            order: index + 1
          })
        );

        const teamsToUpdate = sourceDivision.concat(
          destinationDivisionToUpdate
        );

        dispatchUpdateBulk(teamsToUpdate);
      }
    },
    [dispatchUpdateBulk, teamsByDivision]
  );

  const canMakeTeams = useMemo(
    () => hasEnoughFieldsAndTimeslots(competition, teams),
    [competition, teams]
  );
  const canImportTeams = teams.length === 0 && canMakeTeams;

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {teams.length === 0 && canMakeTeams && (
          <Row>
            <Col xs={12}>
              <DisabledParagraph>
                {`There doesn't seem to be any Teams yet for this competition...`}
              </DisabledParagraph>
            </Col>
          </Row>
        )}
        {isAuthenticated && (
          <Row center="xs">
            {canImportTeams && (
              <>
                <Col xs={12} md={5}>
                  <Button
                    color="secondary"
                    onClick={toggleImportModal}
                    display="block"
                  >
                    Import Teams
                  </Button>
                </Col>
                <Col xs={12} md={1}>
                  <Paragraph align="center">or</Paragraph>
                </Col>
              </>
            )}
            <Col xs={12} md={canImportTeams ? 5 : 12}>
              {canMakeTeams && (
                <Button
                  color="primary"
                  as={Link}
                  to={`${location.pathname}/add`}
                  disabled={!canMakeTeams}
                >
                  Add New Team
                </Button>
              )}
            </Col>
          </Row>
        )}
        {isAuthenticated && !canMakeTeams && (
          <Row>
            <Col xs={12}>
              <Card color="warning" style={{ width: "100%" }}>
                <CardBody>
                  {`There aren't enough fields or times to add more Teams. `}
                  <LinkText
                    bold
                    as={Link}
                    to={getEditCompetitionLink(clubId, seasonId, competitionId)}
                  >
                    Edit this Competition
                  </LinkText>{" "}
                  to add more.
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {teams.length > 0 && (
          <Row style={{ marginTop: "1em" }}>
            {divisions.map(division => (
              <Droppable
                droppableId={division || NO_DIVISION_ID}
                key={division}
              >
                {provided => (
                  <Col
                    xs={12}
                    md={4}
                    key={division}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <ExpandableCard
                      key={division}
                      title={
                        <EditableText
                          shouldMaintainWidth
                          isDisabled={!isAuthenticated || division == null}
                          onChange={getUpdateDivisionsCallback(
                            teamsByDivision[division]
                          )}
                          value={division}
                        >
                          {division || "No Division"}
                        </EditableText>
                      }
                      isOpen
                    >
                      <ListItemGroup>
                        {teamsByDivision[division].map((team, index) =>
                          isAuthenticated ? (
                            <DraggableTeam
                              key={team.id}
                              index={index}
                              teamID={team.id}
                            />
                          ) : (
                            <Team teamID={team.id} />
                          )
                        )}
                        {provided.placeholder}
                      </ListItemGroup>
                    </ExpandableCard>
                  </Col>
                )}
              </Droppable>
            ))}
          </Row>
        )}
      </DragDropContext>
      <ImportTeamsModal
        isOpen={isImportModalShown}
        toggle={toggleImportModal}
      />
    </>
  );
}
export default connect()(Teams);
