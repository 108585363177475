import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "../Select";

const TabsContainer = styled.div`
  margin: 0.75em 0;
`;

const MobileTabs = ({ tabs, onChange, activeTabId }) => (
  <TabsContainer>
    <Select value={activeTabId} onChange={event => onChange(event.target.value)}>
      {tabs.map(tab => (
        <option key={tab.id} value={tab.id}>
          {tab.name}
        </option>
      ))}
    </Select>
  </TabsContainer>
);

MobileTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  activeTabId: PropTypes.string,
};

MobileTabs.defaultProps = {
  onChange: () => {},
  activeTabId: null,
};

export default MobileTabs;
