import React, { useCallback, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { fetch as fetchSeasons, create as createSeason } from "../actions/seasons";
import LinkCard from "../components/LinkCard";
import AddSeasonModal from "../components/modals/add-season/AddSeasonModal";
import {
  Row,
  Col,
  Card,
  CardBody,
  HeadingFour,
  DisabledParagraph,
  Button,
} from "../components/atomic";
import AppSpinner from "../components/AppSpinner";
import { getAppStatus } from "../selectors/status";
import { getClubByID } from "../selectors/clubs";
import { getIsClubOwner } from "../selectors/user";
import AsyncStatus from "../constants/AsyncStatus";
import useBoolean from "../hooks/useBoolean";

function Seasons({ seasons, status }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clubId } = useParams();
  const { value: isModalVisible, toggle: toggleModal } = useBoolean(false);

  const club = useSelector(getClubByID(clubId));
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  useEffect(() => {
    dispatch(fetchSeasons(clubId));
  }, [dispatch, club, clubId]);

  const addSeason = useCallback(
    async season => {
      try {
        const action = await dispatch(createSeason(clubId, season));
        const { data } = action.value;

        history.push(`/clubs/${clubId}/seasons/${data.id}`);
      } catch (ex) {
        console.error(ex);
      }
    },
    [clubId, history, dispatch],
  );

  const isPending = status === AsyncStatus.pending;

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <HeadingFour color="primary">Seasons</HeadingFour>
            {isAuthenticated && <Button onClick={toggleModal}>Add New Season</Button>}
          </CardBody>
        </Card>
      </Col>
      {isPending && <AppSpinner />}
      {!isPending &&
        seasons.length > 0 &&
        seasons.map(season => (
          <Col xs={12} key={season.id}>
            <LinkCard to={`/clubs/${clubId}/seasons/${season.id}`}>{season.name}</LinkCard>
          </Col>
        ))}
      {!isPending && seasons.length === 0 && (
        <DisabledParagraph
          color="neutral2"
          display="block"
          width="100%"
          align="center"
        >{`There doesn't seem to be any Seasons available for ${club.name} yet...`}</DisabledParagraph>
      )}
      <AddSeasonModal isOpen={isModalVisible} toggle={toggleModal} onSubmit={addSeason} />
    </Row>
  );
}

export default connect(state => ({
  seasons: state.seasons.list,
  status: getAppStatus(state),
}))(Seasons);
