import styled from "styled-components";

const TableData = styled.td`
  text-align: ${props => props.align};
  padding: 0.5em;
  position: ${props => props.position};
  box-sizing: border-box;
  opacity: ${props => (props.invisible ? "0" : "1")};
  ${props => (props.invisible ? "opacity: 0;" : null)}
  ${props => (props.breakword ? "word-break: break-word; word-wrap: break-word;" : null)}
`;

TableData.defaultProps = {
  width: "initial",
  minWidth: "initial",
  align: "initial",
  position: "initial",
};

export default TableData;
