import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const Season = ({ match }) => <Redirect to={`${match.url}/competitions`} />;

Season.propTypes = {
  match: PropTypes.object,
};

Season.defaultProps = {
  match: {},
};

export default Season;
