import { createAction } from "redux-actions";
import ClubsAPI from "../api/clubs";

export const types = {
  get: "CLUB_GET",
  fetch: "CLUBS_FETCH",
  create: "CLUBS_CREATE",
  update: "CLUBS_UPDATE",
};

export const get = createAction(types.get, ClubsAPI.get, id => ({ id }));
export const fetch = createAction(types.fetch, ClubsAPI.fetch);
export const create = createAction(types.create, ClubsAPI.create);
export const update = createAction(types.update, ClubsAPI.update, club => ({
  id: club.id,
}));
