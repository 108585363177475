import React from "react";
import PropTypes from "prop-types";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { BeatLoader } from "react-spinners";
import { LinkButton, Toggle } from "../atomic";

const ArchivedCompetitions = ({ title, children, onClick, loading, ...rest }) => (
  <Toggle align="center" disableClickOff disableEscape>
    {({ active, toggle }) => (
      <>
        <LinkButton
          tag="h5"
          bold
          display="inline-flex"
          onClick={() => {
            onClick();
            toggle();
          }}
          {...rest}
        >
          {title}
          {active ? <GoChevronUp /> : <GoChevronDown />}
        </LinkButton>
        {loading && (
          <div className="text-center">
            <BeatLoader />
          </div>
        )}
        {active && !loading && children}
      </>
    )}
  </Toggle>
);

ArchivedCompetitions.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any,
  loading: PropTypes.bool,
};

ArchivedCompetitions.defaultProps = {
  onClick: () => {},
  children: null,
  loading: false,
};

export default ArchivedCompetitions;
