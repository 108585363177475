import { useCallback, useLayoutEffect, useState } from "react";

/**
 * Helper hook to trigger a given function when pressing escape while focused on a given element
 */
function useOnEscape(callback) {
  const [ref, setRef] = useState(null);

  const listener = useCallback(
    event => {
      if (event.keyCode === 27) {
        callback(event);
      }
    },
    [callback],
  );

  useLayoutEffect(() => {
    if (ref != null) {
      ref.addEventListener("keydown", listener);
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener("keydown", listener);
      }
    };
  }, [ref, listener]);

  return setRef;
}

export default useOnEscape;
