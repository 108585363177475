import styled from "styled-components";
import Text from "./Text";

const HeadingSix = styled(Text).attrs({ as: "h5" })`
  font-size: 1em;
  font-weight: 600;
  display: block;
  margin: 0.25em 0;
`;

export default HeadingSix;
