import React from "react";
import PropTypes from "prop-types";
import AddClubModalForm from "./AddClubModalForm";
import { Modal, ModalHeader } from "../../atomic";

export const AddClubModal = ({ toggle, onSubmit, ...rest }) => (
  <Modal toggle={toggle} {...rest}>
    <ModalHeader toggle={toggle}>Add New Club</ModalHeader>
    <AddClubModalForm onSubmit={onSubmit} />
  </Modal>
);

AddClubModal.propTypes = {
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddClubModal.defaultProps = {
  toggle: () => {},
  onSubmit: () => {},
};

export default AddClubModal;
