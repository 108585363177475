import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { SubmitButton } from "./Buttons";
import { Row, Col, Label, Input, SmallText } from "./atomic";

export const ForgotForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="manager@club.com.au"
          invalid={touched.email && !!errors.email}
          required
          autoFocus
          valid={values.success}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled={isSubmitting}
        />
        {values.success && (
          <SmallText color="success" display="block">
            Sent! Check your emails for more information
          </SmallText>
        )}
      </Col>
    </Row>
    <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
      Reset my password
    </SubmitButton>
  </form>
);

ForgotForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ForgotForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  isValid: true,
  handleSubmit: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: () => ({
    email: "",
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
  }),
  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    const { onSubmit } = props;
    const { email } = values;

    setFieldValue("success", false);

    try {
      if (onSubmit) await onSubmit({ email });
      setFieldValue("success", true);
    } catch (ex) {
      console.error(ex);
    }

    setSubmitting(false);
  },
})(ForgotForm);
