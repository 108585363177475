import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.neutral5};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.neutral3};
  }
`;
NavItemContainer.displayName = "NavItemContainer";

const NavItem = ({ Icon, children, ...rest }) => (
  <NavItemContainer {...rest}>{children}</NavItemContainer>
);

NavItem.propTypes = {
  children: PropTypes.any,
};

NavItem.defaultProps = {
  children: null,
};

export default NavItem;
