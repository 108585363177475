import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Toggle from "../utility/Toggle";
import Portal from "../utility/Portal";
import getAbsolutePosition from "../utils/getAbsolutePosition";

const DropdownContainer = styled.div`
  position: relative;
  user-select: none;
  display: inline-flex;
  padding: 0.25em;
`;
DropdownContainer.displayName = "DropdownContainer";

const TriggerContainer = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;
TriggerContainer.displayName = "TriggerContainer";

const Dropdown = ({ Trigger, MenuContainer, children, position, location }) => {
  const triggerRef = React.createRef();

  return (
    <Toggle>
      {({ active, toggle }) => (
        <DropdownContainer>
          <TriggerContainer
            ref={triggerRef}
            onClick={e => {
              e.stopPropagation();
              toggle();
            }}
          >
            {Trigger}
          </TriggerContainer>
          {active && (
            <Portal>
              <MenuContainer
                position={position}
                location={location || getAbsolutePosition(triggerRef.current)}
              >
                {children}
              </MenuContainer>
            </Portal>
          )}
        </DropdownContainer>
      )}
    </Toggle>
  );
};

Dropdown.propTypes = {
  Trigger: PropTypes.node.isRequired,
  MenuContainer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  position: PropTypes.string,
  location: PropTypes.object,
};

Dropdown.defaultProps = {
  position: "absolute",
  location: null,
};

export default Dropdown;
