import api from ".";

export const get = async (clubId, id) => api.get(`/clubs/${clubId}/seasons/${id}`);
export const fetch = async clubId => api.get(`/clubs/${clubId}/seasons`);
export const create = async (clubId, season) => api.post(`/clubs/${clubId}/seasons`, season);

export default {
  get,
  fetch,
  create,
};
