import styled from "styled-components";

const InputAddon = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors[props.color]};
  border-color: ${props => props.theme.colors[props.border]};
  border-radius: ${props => props.theme.radius.input};
  padding: 0.5em;
  min-width: 2.25em;
  width: initial;
`;
InputAddon.displayName = "InputAddon";
InputAddon.defaultProps = {
  color: "neutral3",
  border: "neutral3",
};

export default InputAddon;
