import React from "react";

import styled from "styled-components";
import {
  HeadingThree,
  HeadingFour as _HeadingFour,
  HeadingFive as _HeadingFive,
  HeadingSix as _HeadingSix,
  SmallText,
  Paragraph,
  ListItemGroup,
  ListItem
} from "../components/atomic";

const HeadingFour = styled(_HeadingFour)`
  margin: 1em 0 0.25em;
`;

const HeadingFive = styled(_HeadingFive)`
    margin 1em 0 0.25em;
  `;

const HeadingSix = styled(_HeadingSix)`
    margin 1em 0 0.25em;
  `;

function Privacy() {
  return (
    <div>
      <HeadingThree>Privacy Policy</HeadingThree>
      <SmallText>Last updated: October 3rd, 2020</SmallText>
      <Paragraph>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </Paragraph>
      <Paragraph>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the ​Privacy Policy Generator​.
      </Paragraph>
      <HeadingFour>Interpretation and Definitions</HeadingFour>
      <HeadingFive>Interpretation</HeadingFive>
      <Paragraph>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Paragraph>
      <HeadingFive>Definitions</HeadingFive>
      <Paragraph>For the purposes of this Privacy Policy:</Paragraph>
      <ListItemGroup listStyle="initial">
        <ListItem>
          Account means a unique account created for You to access our Service
          or parts of our Service.
        </ListItem>
        <ListItem>
          Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Agreement) refers to ROBN Pty Ltd, Liverpool NSW 2170.
        </ListItem>
        <ListItem>
          Cookies are small files that are placed on Your computer, mobile
          device or any other device by a website, containing the details of
          Your browsing history on that website among its many uses.
        </ListItem>
        <ListItem>Country refers to: New South Wales, Australia</ListItem>
        <ListItem>
          Device means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </ListItem>
        <ListItem>
          Personal Data is any information that relates to an identified or
          identifiable individual.
        </ListItem>
        <ListItem>Service refers to the Website.</ListItem>
        <ListItem>
          Service Provider means any natural or legal person who processes the
          data on behalf of the Company. It refers to third-party companies or
          individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used.
        </ListItem>
        <ListItem>
          Third-party Social Media Service refers to any website or any social
          network website through which a User can log in or create an account
          to use the Service.
        </ListItem>
        <ListItem>
          Usage Data refers to data collected automatically, either generated by
          the use of the Service or from the Service infrastructure itself (for
          example, the duration of a page visit).
        </ListItem>
        <ListItem>
          Website refers to ROBN, accessible from ​robn.com.au
        </ListItem>
        <ListItem>
          You means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </ListItem>
      </ListItemGroup>
      <HeadingFour>Collecting and Using Your Personal Data</HeadingFour>
      <HeadingFive>Types of Data Collected</HeadingFive>
      <HeadingSix>Personal Data</HeadingSix>
      <Paragraph>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Paragraph>
      <ListItemGroup>
        <ListItem>Email address</ListItem>
        <ListItem>First name and last name</ListItem>
        <ListItem>Date of birth</ListItem>
        <ListItem>Phone number</ListItem>
        <ListItem>Address, State, Province, ZIP/Postal code, City</ListItem>
        <ListItem>Usage Data</ListItem>
      </ListItemGroup>
      <HeadingSix>Usage Data</HeadingSix>
      <Paragraph>
        Usage Data is collected automatically when using the Service.
      </Paragraph>
      <Paragraph>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Paragraph>
      <HeadingSix>Tracking Technologies and Cookies</HeadingSix>
      <Paragraph>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service.
      </Paragraph>
      <Paragraph>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </Paragraph>
      <Paragraph>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
        Learn more about cookies: ​All About Cookies​.
      </Paragraph>
      <Paragraph>
        We use both session and persistent Cookies for the purposes set out
        below:
      </Paragraph>
      <ListItemGroup>
        <ListItem>
          Necessary / Essential Cookies
          <br />
          Type: Session Cookies Administered by: Us
          <br />
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </ListItem>
        <ListItem>
          Cookies Policy / Notice Acceptance Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </ListItem>
        <ListItem>
          Functionality Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </ListItem>
      </ListItemGroup>
      <Paragraph>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </Paragraph>
      <HeadingFive>Use of Your Personal Data</HeadingFive>
      <Paragraph>
        The Company may use Personal Data for the following purposes:
      </Paragraph>
      <ListItemGroup>
        <listItem>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </listItem>
        <listItem>
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </listItem>
        <ListItem>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </ListItem>
        <ListItem>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </ListItem>
        <ListItem>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </ListItem>
        <ListItem>
          To manage Your requests: To attend and manage Your requests to Us.
        </ListItem>
      </ListItemGroup>
      <Paragraph>
        We may share your personal information in the following situations:
      </Paragraph>
      <ListItemGroup>
        <ListItem>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          contact You.
        </ListItem>
        <ListItem>
          For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </ListItem>
        <ListItem>
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </ListItem>
        <ListItem>
          With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </ListItem>
        <ListItem>
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Service
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile.
        </ListItem>
      </ListItemGroup>
      <HeadingFive>Retention of Your Personal Data</HeadingFive>
      <Paragraph>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </Paragraph>
      <Paragraph>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Paragraph>
      <HeadingFive>Transfer of Your Personal Data</HeadingFive>
      <Paragraph>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </Paragraph>
      <Paragraph>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Paragraph>
      <Paragraph>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Paragraph>
      <HeadingFive>Disclosure of Your Personal Data</HeadingFive>
      <HeadingSix>Business Transactions</HeadingSix>
      <Paragraph>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Paragraph>
      <HeadingSix>Law enforcement</HeadingSix>
      <Paragraph>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Paragraph>
      <HeadingSix>Other legal requirements</HeadingSix>
      <Paragraph>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Paragraph>
      <ListItemGroup>
        <ListItem>Comply with a legal obligation</ListItem>
        <ListItem>
          Protect and defend the rights or property of the Company
        </ListItem>
        <ListItem>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </ListItem>
        <ListItem>
          Protect the personal safety of Users of the Service or the public
        </ListItem>
        <ListItem>Protect against legal liability</ListItem>
      </ListItemGroup>
      <HeadingFive>Security of Your Personal Data</HeadingFive>
      <Paragraph>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Paragraph>
      <HeadingFour>Links to Other Websites</HeadingFour>
      <Paragraph>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </Paragraph>
      <HeadingFour>Changes to this Privacy Policy</HeadingFour>
      <Paragraph>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </Paragraph>
      <Paragraph>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </Paragraph>
      <Paragraph>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Paragraph>
      <HeadingFour>Contact Us</HeadingFour>
      <Paragraph>
        If you have any questions about this Privacy Policy, You can contact us
        by emailing us: support@​robn.com.au
      </Paragraph>
    </div>
  );
}

export default Privacy;
