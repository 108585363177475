import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { SubmitButton } from "../../Buttons";
import { ToggleBox } from "../../Form";
import { Row, Col, Label, ModalBody } from "../../atomic";

const RoundContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  column-gap: 0.5em;
  row-gap: 0.5em;
`;

const SelectRoundsModalForm = ({ values, isValid, handleSubmit, isSubmitting, setFieldValue }) => (
  <form onSubmit={handleSubmit}>
    <ModalBody>
      <Row>
        <Col xs={12}>
          <Label>Which rounds do you want to include in this ladder?</Label>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RoundContainer>
            {values.rounds.map(round => (
              <ToggleBox
                id={round.id}
                key={round.id}
                checked={!round.excluded}
                onChange={event => {
                  const rounds = values.rounds.map(_round => {
                    if (_round.id !== round.id) {
                      return _round;
                    }
                    return {
                      ...round,
                      excluded: !event.target.checked,
                    };
                  });

                  setFieldValue("rounds", rounds);
                }}
              >
                {round.name}
              </ToggleBox>
            ))}
          </RoundContainer>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
            Save
          </SubmitButton>
        </Col>
      </Row>
    </ModalBody>
  </form>
);

SelectRoundsModalForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

SelectRoundsModalForm.defaultProps = {
  values: {},
  isValid: true,
  handleSubmit: () => {},
  isSubmitting: false,
  setFieldValue: () => {},
};

const mapPropsToValues = ({ rounds = [], exclusions = [] }) => {
  const defaults = {
    rounds: [],
  };

  const roundsWithExclusions = rounds.map(round => ({
    ...round,
    excluded: exclusions.some(exclusion => exclusion.id === round.id),
  }));

  return { ...defaults, rounds: roundsWithExclusions };
};

export default withFormik({
  isInitialValid: true,
  mapPropsToValues,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;
    const { rounds } = values;

    const excludedRounds = rounds.filter(round => round.excluded);

    try {
      if (onSubmit) await onSubmit(excludedRounds);
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(SelectRoundsModalForm);
