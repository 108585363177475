import { useCallback, useState, useMemo } from "react";

/**
 * Helper hook to get a number, and functions to incremenet and decrement
 */
function useCounter(initialValue) {
  const [value, set] = useState(initialValue);

  const increment = useCallback(() => set(val => val + 1), [set]);
  const decrement = useCallback(() => set(val => val - 1), [set]);

  return useMemo(
    () => ({
      value,
      increment,
      decrement,
      set,
    }),
    [value, increment, decrement, set],
  );
}

export default useCounter;
