import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../../Buttons";
import BlockDatePicker from "../../DatePickers/BlockDatePicker";
import { Label, Input, ModalBody, Row, Col } from "../../atomic";

const AddRoundModalForm = ({
  values,
  isValid,
  handleSubmit,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
}) => {
  const startDate = new Date();

  const numberOfRounds = values.number || 1;

  return (
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <Row>
          <Col xs={2}>
            <Label htmlFor="number">Rounds</Label>
            <Input
              id="number"
              name="number"
              align="right"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="1"
              value={values.number}
              autoFocus
            />
          </Col>
          <Col xs={10}>
            <Label htmlFor="date">Starting on</Label>
            <BlockDatePicker
              id="date"
              name="date"
              customInput={<Input onBlur={handleBlur} />}
              autoComplete="off"
              onChange={date => setFieldValue("date", date)}
              selected={values.date}
              placeholderText={startDate.toLocaleDateString()}
              disabledKeyboardNavigation
              shouldCloseOnSelect
              todayButton="Today"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Add {numberOfRounds} Round
              {numberOfRounds > 1 ? "s" : ""}
            </SubmitButton>
          </Col>
        </Row>
      </ModalBody>
    </form>
  );
};

AddRoundModalForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

AddRoundModalForm.defaultProps = {
  values: {},
  isValid: true,
  handleSubmit: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  isSubmitting: false,
  setFieldValue: false,
};

const mapPropsToValues = ({ date }) => ({ number: 1, date });

const validationSchema = yup.object().shape({
  number: yup.number().required(),
  date: yup.date().required(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;
    setSubmitting(true);

    try {
      if (onSubmit) {
        await onSubmit(values);
      }
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(AddRoundModalForm);
