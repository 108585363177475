import React from "react";
import { Redirect } from "react-router";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Forgot from "./containers/Forgot";
import AppContainer from "./containers/AppContainer";
import PolicyContainer from "./containers/PolicyContainer";
import Clubs from "./containers/Clubs";
import Club from "./containers/Club";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import Seasons from "./containers/Seasons";
import Season from "./containers/Season";
import Competitions from "./containers/Competitions";
import Competition from "./containers/Competition";
import AddCompetition from "./containers/AddCompetition";
import EditCompetition from "./containers/EditCompetition";
import AddTeam from "./containers/AddTeam";
import EditTeam from "./containers/EditTeam";
import Teams from "./containers/Teams";
import Rounds from "./containers/Rounds";
import Ladders from "./containers/Ladders";
import Finals from "./containers/Finals";
import Reporting from "./containers/Reporting";

export const getCompetitionLink = (clubId, seasonId, competitionId) =>
  `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}`;
export const getEditCompetitionLink = (clubId, seasonId, competitionId) =>
  `${getCompetitionLink(clubId, seasonId, competitionId)}/edit`;
export const getCompetitionTeamsLink = (clubId, seasonId, competitionId) =>
  `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}`;
export const getEditTeamLink = (clubId, seasonId, competitionId, teamId) =>
  `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams/${teamId}/edit`;

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/clubs" />,
    routes: []
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/signup",
    component: Signup
  },
  {
    path: "/forgot",
    component: Forgot
  },
  {
    path: "/policies",
    component: PolicyContainer,
    routes: [
      {
        path: "/policies/privacy",
        component: Privacy
      },
      { path: "/policies/terms", component: Terms }
    ]
  },
  {
    path: "/clubs",
    component: AppContainer,
    routes: [
      {
        path: "/clubs",
        component: Clubs,
        exact: true
      },
      {
        path: "/clubs/:clubId(\\d+)",
        component: Club,
        exact: true
      },
      {
        path: "/clubs/:clubId(\\d+)/seasons",
        component: Seasons,
        exact: true
      },
      {
        path: "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)",
        component: Season,
        exact: true
      },
      {
        path: "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions",
        component: Competitions,
        exact: true
      },
      {
        path: "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/add",
        component: AddCompetition,
        exact: true
      },
      {
        path:
          "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId/edit",
        component: EditCompetition,
        exact: true
      },
      {
        path:
          "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/:tabId(teams|rounds|ladder|finals|reporting)?",
        component: Competition,
        exact: true,
        routes: [
          {
            path:
              "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/teams",
            component: Teams,
            exact: true
          },
          {
            path:
              "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/rounds",
            component: Rounds,
            exact: true
          },
          {
            path:
              "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/ladder",
            component: Ladders,
            exact: true
          },
          {
            path:
              "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/finals",
            component: Finals,
            exact: true
          },
          {
            path:
              "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/reporting",
            component: Reporting,
            exact: true
          }
        ]
      },
      {
        path:
          "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/teams/:teamId/edit",
        component: EditTeam,
        exact: true
      },
      {
        path:
          "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)/teams/add",
        component: AddTeam,
        exact: true
      }
    ]
  }
];

export default routes;
