import { createAction } from "redux-actions";
import fileDownload from "js-file-download";
import FinalsAPI from "../api/finals";

export const types = {
  fetch: "FINALS_FETCH",
  create: "FINALS_CREATE",
  createBatch: "FINALS_CREATE_BATCH",
  remove: "FINAL_REMOVE",
  set: "FINAL_SET",
  update: "FINAL_UPDATE",
};

export const fetch = createAction(types.fetch, FinalsAPI.fetch);
export const set = createAction(types.set);
export const create = createAction(types.create, FinalsAPI.create);
export const createBatch = createAction(types.createBatch, FinalsAPI.createBatch);
export const remove = createAction(
  types.remove,
  FinalsAPI.remove,
  (clubId, seasonId, competitionId, id) => ({
    id,
    competitionId,
  }),
);
export const update = createAction(
  types.update,
  FinalsAPI.update,
  (clubId, seasonId, competitionId, round) => ({ id: round.id }),
);

export const download = async (clubId, seasonId, id, type, fileName) => {
  const data = await FinalsAPI.download(clubId, seasonId, id, { type });
  fileDownload(data.data, fileName);
};
