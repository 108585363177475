import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Checkbox } from "../atomic";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
  margin-bottom: 0;
  border: 1px dotted ${props => props.theme.colors.primary};
  text-align: center;
  cursor: pointer;
  border-radius: ${props => props.theme.radius.element};
  transition: all 150ms linear;

  input[type="checkbox"]:checked + & {
    border-style: solid;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.neutral5};
  }
`;

const ToggleBox = ({ id, children, checked, onChange, ...rest }) => (
  <Container {...rest}>
    <Checkbox id={id} defaultChecked={checked} onChange={onChange} display="none" />
    <Label htmlFor={id}>{children}</Label>
  </Container>
);

ToggleBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

ToggleBox.defaultProps = {
  children: null,
  checked: false,
  onChange: () => {},
};

export default ToggleBox;
