import styled from "styled-components";
import Text from "./Text";

const Link = styled(Text)`
  cursor: pointer;
  color: ${props => props.theme.colors[props.color]};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.colors[props.color]};
    ${props =>
      props.underline &&
      `
          border-bottom: 1px solid ${props.theme.colors[props.color]};
      `}
  }
`;

Link.defaultProps = {
  color: "primary",
  as: "a",
  underline: "true"
};

export default Link;
