import { createAction } from "redux-actions";
import MatchesAPI from "../api/matches";

export const types = {
  update: "MATCH_UPDATE",
};

export const update = createAction(
  types.update,
  MatchesAPI.update,
  (clubId, seasonId, competitionId, roundId) => ({ roundId }),
);
