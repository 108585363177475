import styled from "styled-components";
import Text from "./Text";

const HeadingThree = styled(Text).attrs({ as: "h3" })`
  font-weight: 600;
  display: block;
  margin: 0.25em 0;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.75em;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2em;
  }
`;

export default HeadingThree;
