import React from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";

import theme from "../components/atomic/theme";
import { Input } from "../components/atomic";
import { getCompetitionByID } from "../selectors/competitions";
import { getRounds } from "../selectors/rounds";
import { getTeams } from "../selectors/teams";
import { getClashesForTeam, doesTeamPreferMatch } from "../../shared/utils/matches";

const { useMemo, useState } = React;

function getReportForTeamMatches(team, playedMatches, allMatches) {
  const clashes = getClashesForTeam(team, allMatches);
  const timePreferenceMismatches = [];
  const timesPlayed = {};

  playedMatches.forEach(match => {
    if (!doesTeamPreferMatch(team, match)) {
      timePreferenceMismatches.push(match);
    }

    const existingTimesPlayed = timesPlayed[match.time] || [];
    timesPlayed[match.time] = existingTimesPlayed.concat(match);
  });

  return {
    clashes,
    timesPlayed,
    timePreferenceMismatches,
  };
}

function useTeamReporting(teams, rounds) {
  const teamReport = useMemo(() => {
    const matches = rounds
      .reduce((aggregate, round) => {
        const matchesWithRoundNames = round.matches.map(match => ({
          ...match,
          roundName: round.name,
        }));

        return aggregate.concat(matchesWithRoundNames);
      }, [])
      .filter(match => match.homeTeamId != null || match.awayTeamId != null);

    const matchesByTeams = matches.reduce((aggregate, match) => {
      const { homeTeamId, awayTeamId } = match;
      const existingHomeMatches = aggregate[homeTeamId] || [];
      const existingAwayMatches = aggregate[awayTeamId] || [];

      return {
        ...aggregate,
        [homeTeamId]: existingHomeMatches.concat(match),
        [awayTeamId]: existingAwayMatches.concat(match),
      };
    }, {});

    return teams.reduce(
      (report, team) => ({
        ...report,
        [team.id]: getReportForTeamMatches(team, matchesByTeams[team.id], matches),
      }),
      {},
    );
  }, [teams, rounds]);

  return teamReport;
}

function useReportingData(teams, timeslots, rounds) {
  const reportByTeam = useTeamReporting(teams, rounds);

  return useMemo(
    () =>
      teams.map(team => {
        const preferences = team.preferences.map(preference => moment(preference).format("x"));
        const timeslotData = timeslots.reduce((aggregate, timeslot) => {
          const timesPlayed = reportByTeam[team.id].timesPlayed[timeslot] || [];
          return {
            ...aggregate,
            [moment(timeslot).format("x")]: timesPlayed.length,
          };
        }, {});

        return {
          team: team.name,
          clashes: reportByTeam[team.id].clashes.length,
          ...timeslotData,
          missedTimes: reportByTeam[team.id].timePreferenceMismatches.length,
          preferences,
        };
      }),
    [teams, timeslots, reportByTeam],
  );
}

function useGetTimeslots(rounds, timeslots) {
  return useMemo(() => {
    const matchTimeslots = rounds.reduce(
      (aggregate, round) =>
        round.matches.reduce(
          (_aggregate, match) => ({
            ..._aggregate,
            [match.time]: true,
          }),
          aggregate,
        ),
      {},
    );

    const totalTimeslots = timeslots.reduce(
      (aggregate, timeslot) => ({
        ...aggregate,
        [timeslot.time]: true,
      }),
      matchTimeslots,
    );

    return Object.keys(totalTimeslots);
  }, [rounds, timeslots]);
}

function useColumns(rounds, timeslots) {
  const errorRowStyles = {
    backgroundColor: theme.colors.error,
    color: theme.colors.neutral5,
  };

  const totalTimeslots = useGetTimeslots(rounds, timeslots);

  const columns = useMemo(() => {
    const timeslotColumns = totalTimeslots.map(timeslot => {
      const selector = moment(timeslot).format("x");

      return {
        name: moment(timeslot).format("h:mm A"),
        center: true,
        selector,
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row[selector] > 0 && !row.preferences.includes(selector),
            style: errorRowStyles,
          },
        ],
      };
    });

    const sortedTimeslotColumns = orderBy(timeslotColumns, "selector");

    return [
      {
        name: "Team",
        selector: "team",
        sortable: true,
        defaultSortField: true,
      },
      {
        name: "Clashes",
        center: true,
        selector: "clashes",
        sortable: true,
      },
      ...sortedTimeslotColumns,
      {
        name: "Missed Times",
        center: true,
        selector: "missedTimes",
        sortable: true,
      },
    ];
  }, [totalTimeslots, errorRowStyles]);

  return [columns, totalTimeslots];
}

function useFilter(teams) {
  const [value, setValue] = useState("");
  const filteredTeams = useMemo(
    () =>
      value.trim() === ""
        ? teams
        : teams.filter(team => team.name.toLowerCase().includes(value.toLowerCase())),
    [value, teams],
  );

  return [value, setValue, filteredTeams];
}

function SubHeaderComponent({ value, setValue }) {
  return (
    <div>
      <Input
        type="text"
        placeholder="Filter by Team Name"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}

SubHeaderComponent.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

function Reporting() {
  const { competitionId } = useParams();

  const competition = useSelector(getCompetitionByID(competitionId));
  const rounds = useSelector(getRounds);
  const teams = useSelector(getTeams);
  const [value, setValue, filteredTeams] = useFilter(teams);

  const [columns, timeslots] = useColumns(rounds, competition.timeslots);
  const data = useReportingData(filteredTeams, timeslots, rounds);

  return (
    <DataTable
      className="react-data-table"
      title="Competition Report"
      columns={columns}
      data={data}
      defaultSortField="team"
      fixedHeader
      fixedHeaderScrollHeight="400px"
      subHeader
      subHeaderComponent={<SubHeaderComponent value={value} setValue={setValue} />}
    />
  );
}

export default connect()(Reporting);
