import React from "react";
import styled from "styled-components";
import { Spinner } from "./atomic";

const Container = styled.div`
  margin: 4em 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const AppSpinner = ({ ...rest }) => (
  <Container>
    <Spinner {...rest} />
  </Container>
);

export default AppSpinner;
