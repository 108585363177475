import styled from "styled-components";

const Divider = styled.div`
  margin: 0.5em 0;
  border-bottom: 1px solid ${props => props.theme.colors[props.color]};
`;

Divider.defaultProps = {
  color: "neutral4",
};

export default Divider;
