import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Portal from "../utility/Portal";
import Toggle from "../utility/Toggle";
import getAbsolutePosition from "../utils/getAbsolutePosition";
import SmallText from "../typography/SmallText";
import getWidthOfElement from "../utils/getWidthOfElement";
import getHeightOfElement from "../utils/getHeightOfElement";

const TooltipContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: ${props => props.location.top};
  left: ${props => props.location.left};
  box-shadow: 0 0 0.5rem ${props => props.theme.colors.neutral3};
  background-color: ${props => props.theme.colors.neutral0};
  border-radius: ${props => props.theme.radius.small};
  padding: 0.25em 0.5em;
`;
const TriggerContainer = styled.div`
  position: relative;
  display: inline-block;
  padding: 0.25em;
`;
const Arrow = styled.div`
  &:before {
    top: ${props => props.top};
    left: ${props => props.left};
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
`;
Arrow.defaultProps = {
  top: "0",
  left: "0",
};

const BottomArrow = styled(Arrow)`
  &:before {
    top: ${props => props.top};
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
`;

class TooltipHoverContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.any,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  state = {
    location: this.props.location,
    width: 0,
  };

  componentDidMount() {
    const { location } = this.state;
    const width = getWidthOfElement(this.containerRef.current);
    const height = getHeightOfElement(this.containerRef.current);

    this.setState({
      location: {
        top: `calc(${location.top}px - 2em)`,
        left: `${location.left - width / 2 + 4}px`,
      },
      width,
      height,
    });
  }

  render() {
    const { children } = this.props;
    const { location, width, height } = this.state;

    return (
      <TooltipContainer ref={this.containerRef} location={location}>
        <SmallText color="neutral5">{children}</SmallText>
        <BottomArrow left={`${width / 2}px`} top={`${height}px`} />
      </TooltipContainer>
    );
  }
}

const Tooltip = ({ Trigger, children, ...rest }) => {
  const triggerRef = React.createRef();

  return (
    <Toggle disableEscape disableClickOff>
      {({ active, toggle }) => (
        <>
          <TriggerContainer
            ref={triggerRef}
            onMouseEnter={() => toggle(true)}
            onMouseLeave={() => toggle(false)}
          >
            {Trigger}
          </TriggerContainer>
          {active && (
            <Portal>
              <TooltipHoverContainer location={getAbsolutePosition(triggerRef.current)} {...rest}>
                {children}
              </TooltipHoverContainer>
            </Portal>
          )}
        </>
      )}
    </Toggle>
  );
};
Tooltip.propTypes = {
  Trigger: PropTypes.any.isRequired,
  children: PropTypes.any,
};
Tooltip.defaultProps = {
  children: null,
};

export default Tooltip;
