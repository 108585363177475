import React from "react";
import PropTypes from "prop-types";
import AddCustomDivisionModalForm from "./AddCustomDivisionModalForm";
import { Modal, ModalHeader } from "../../atomic";

export const AddCustomDivisionModal = ({ toggle, onSubmit, ladders, ...rest }) => (
  <Modal toggle={toggle} {...rest}>
    <ModalHeader toggle={toggle}>Add Final</ModalHeader>
    <AddCustomDivisionModalForm ladders={ladders} onSubmit={onSubmit} />
  </Modal>
);

AddCustomDivisionModal.propTypes = {
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddCustomDivisionModal.defaultProps = {
  toggle: () => {},
  onSubmit: () => {},
};

export default AddCustomDivisionModal;
