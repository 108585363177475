import { useCallback, useEffect, useRef } from "react";
import useBoolean from "./useBoolean";

function useOverlay({ value: isInitiallyVisible, disableEscape, disableClickOff }) {
  const useBooleanObject = useBoolean(isInitiallyVisible);
  const { value: isActive, setFalse: closeOverlay } = useBooleanObject;
  const containerRef = useRef();

  const closeOnEvent = useCallback(
    event => {
      const currentRef = containerRef.current;

      const handleClick =
        event.type === "click" && currentRef && !currentRef.contains(event.target);

      const handleEscape = event.keyCode === 27;

      if (handleClick || handleEscape) {
        closeOverlay();
      }
    },
    [containerRef, closeOverlay],
  );

  useEffect(() => {
    if (isActive) {
      if (!disableEscape) {
        document.addEventListener("keydown", closeOnEvent);
      }

      if (!disableClickOff) {
        document.addEventListener("click", closeOnEvent);
      }
    } else {
      if (!disableEscape) {
        document.removeEventListener("keydown", closeOnEvent);
      }

      if (!disableClickOff) {
        document.removeEventListener("click", closeOnEvent);
      }
    }
  }, [isActive, closeOnEvent, disableEscape, disableClickOff]);

  return { ...useBooleanObject, containerRef };
}

export default useOverlay;
