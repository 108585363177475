import styled from "styled-components";
import Text from "./Text";

const HeadingFive = styled(Text).attrs({ as: "h5" })`
  font-size: 1.25em;
  font-weight: 500;
  display: block;
  margin: 0.25em 0;
`;

export default HeadingFive;
