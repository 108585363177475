import styled from "styled-components";
import { Col as _Col } from "react-styled-flexboxgrid";

const Col = styled(_Col)`
  text-align: ${props => props.align || "initial"};
`;

Col.displayName = "Col";

export default Col;
