import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import TeamForm from "../components/Teams/TeamForm";
import {
  update as updateTeam,
  fetch as fetchTeams,
  remove as removeTeam,
  fetchForClub as fetchTeamsForClub,
} from "../actions/teams";
import { get as getCompetition } from "../actions/competitions";
import { getTeamById, getTeamStatusById, getTeams, getTeamsForClub } from "../selectors/teams";
import { getClubById } from "../selectors/clubs";
import { getSeasonById } from "../selectors/seasons";
import { getCompetitionById } from "../selectors/competitions";
import { getCompetitionTeamsLink } from "../routes";
import { Row, Col, Card, HeadingFour, CardBody } from "../components/atomic";
import { getAppStatus } from "../selectors/status";
import AsyncStatus from "../constants/AsyncStatus";
import AppSpinner from "../components/AppSpinner";

export class EditTeam extends Component {
  static propTypes = {
    club: PropTypes.object,
    clubId: PropTypes.number.isRequired,
    season: PropTypes.object,
    seasonId: PropTypes.number.isRequired,
    competitionId: PropTypes.number.isRequired,
    competition: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    clubTeams: PropTypes.array,
    dispatchFetchTeams: PropTypes.func,
    dispatchFetchTeamsForClub: PropTypes.func,
    dispatchGetCompetition: PropTypes.func,
    dispatchRemoveTeam: PropTypes.func,
    dispatchUpdateTeam: PropTypes.func,
    appStatus: PropTypes.string,
    status: PropTypes.string,
  };

  static defaultProps = {
    dispatchFetchTeams: () => {},
    dispatchFetchTeamsForClub: () => {},
    dispatchGetCompetition: () => {},
    dispatchRemoveTeam: () => {},
    dispatchUpdateTeam: () => {},
    club: {},
    season: {},
    competition: {},
    team: null,
    teams: [],
    clubTeams: [],
    appStatus: AsyncStatus.unfetched,
    status: null,
  };

  componentDidMount() {
    const {
      clubId,
      seasonId,
      competitionId,
      dispatchFetchTeams,
      dispatchFetchTeamsForClub,
      dispatchGetCompetition,
    } = this.props;

    dispatchFetchTeamsForClub(clubId, null);
    dispatchGetCompetition(clubId, seasonId, competitionId);
    dispatchFetchTeams(clubId, seasonId, competitionId);
  }

  removeTeam = () => {
    const { clubId, seasonId, competitionId, team, dispatchRemoveTeam } = this.props;
    const confirmed = window.confirm(
      `Are you sure you want to delete ${team.name}? This can't be undone.`,
    );

    if (confirmed) {
      dispatchRemoveTeam(clubId, seasonId, competitionId, team.id);
    }
  };

  updateTeam = async team => {
    const { clubId, seasonId, competitionId, dispatchUpdateTeam, history } = this.props;

    try {
      await dispatchUpdateTeam(clubId, seasonId, competitionId, team);

      history.push(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams`);
    } catch (ex) {
      console.error(ex);
    }
  };

  render() {
    const {
      club,
      clubId,
      season,
      seasonId,
      competitionId,
      competition,
      appStatus,
      status,
      team,
      teams,
      clubTeams,
    } = this.props;

    if (appStatus === AsyncStatus.pending) {
      return <AppSpinner />;
    }

    if (!team) {
      return <Redirect push to={getCompetitionTeamsLink(club.id, season.id, competitionId)} />;
    }

    const { timeslots: competitionTimeslots = [] } = competition;
    const timeslots = competitionTimeslots.map(timeslot => timeslot.time);

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <HeadingFour color="primary">Updating {team.name}</HeadingFour>
                  </Col>
                  <Col xs={12}>
                    <TeamForm
                      status={status}
                      clubId={clubId}
                      seasonId={seasonId}
                      competitionId={competitionId}
                      team={team}
                      teams={teams}
                      clubTeams={clubTeams}
                      timeslots={timeslots}
                      onSubmit={this.updateTeam}
                      saveButtonText={`Update ${team.name}`}
                      onDelete={e => {
                        e.preventDefault();
                        this.removeTeam();
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(
  (state, { match }) => ({
    club: getClubById(state, Number(match.params.clubId)),
    clubId: Number(match.params.clubId),
    season: getSeasonById(state, Number(match.params.seasonId)),
    seasonId: Number(match.params.seasonId),
    competition: getCompetitionById(state, Number(match.params.competitionId)),
    competitionId: Number(match.params.competitionId),
    team: getTeamById(state, Number(match.params.teamId)),
    teamId: Number(match.params.teamId),
    teams: getTeams(state),
    clubTeams: getTeamsForClub(state),
    appStatus: getAppStatus(state),
    status: getTeamStatusById(state, Number(match.params.teamId)),
  }),
  {
    dispatchGetCompetition: getCompetition,
    dispatchFetchTeams: fetchTeams,
    dispatchFetchTeamsForClub: fetchTeamsForClub,
    dispatchUpdateTeam: updateTeam,
    dispatchRemoveTeam: removeTeam,
  },
)(EditTeam);
