import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GoKebabVertical, GoChevronDown, GoChevronUp } from "react-icons/go";
import cloneDeep from "lodash.clonedeep";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import {
  remove as removeRound,
  update as updateRound
} from "../../actions/rounds";
import RoundInlineDate from "./RoundInlineDate";
import { getRoundByID, getRoundStatusByID } from "../../selectors/rounds";
import { getTeams } from "../../selectors/teams";
import { getIsClubOwner } from "../../selectors/user";
import { getTeamsWithByes } from "../../../shared/utils/matches";
import MatchGroups from "./MatchGroups";
import AsyncStatus from "../../constants/AsyncStatus";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  EditableText,
  HeadingFive,
  LinkButton,
  CardFooter,
  Text,
  InteractiveWrapper
} from "../atomic";
import useBoolean from "../../hooks/useBoolean";

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeadingTextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: "center";
  & > * {
    margin-left: 0.25em;
  }
`;

function Round({
  roundID,
  isHighlighted,
  isExpanded: isInitiallyExpanded,
  isExpandable = true
}) {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();

  const rawRound = useSelector(getRoundByID(roundID));
  const roundStatus = useSelector(getRoundStatusByID(roundID));
  const teams = useSelector(getTeams);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const [round, setRound] = useState(rawRound);
  const { value: isOpen, toggle: toggleOpen, setTrue: openRound } = useBoolean(
    isInitiallyExpanded
  );

  useEffect(() => {
    setRound(rawRound);
  }, [rawRound]);

  const {
    value: isEditing,
    setTrue: setEditing,
    setFalse: setUnediting
  } = useBoolean(false);

  const editRound = useCallback(() => {
    setEditing();
    openRound();
    setRound(cloneDeep(rawRound));
  }, [setEditing, openRound, rawRound]);

  const cancelEdit = useCallback(() => {
    setUnediting();
    setRound(rawRound);
  }, [setUnediting, setRound, rawRound]);

  const dispatchRemoveRound = useCallback(async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${round.name}? This can't be undone.`
    );

    if (confirmed) {
      await dispatch(removeRound(clubId, seasonId, competitionId, roundID));
    }
  }, [clubId, seasonId, competitionId, roundID, round, dispatch]);

  const dispatchUpdateRound = useCallback(async () => {
    await dispatch(updateRound(clubId, seasonId, competitionId, round)).finally(
      setUnediting
    );
  }, [dispatch, clubId, seasonId, competitionId, round, setUnediting]);

  const updateMatchInRound = useCallback((match, updatedRound) => {
    const matchIndex = updatedRound.matches.findIndex(
      _match => _match.id === match.id
    );

    if (matchIndex > -1) {
      updatedRound.matches[matchIndex] = match;
    }
  }, []);

  const updateMatchOrMatchesInRound = useCallback(
    matchOrMatches => {
      const updatedRound = cloneDeep(round);

      if (Array.isArray(matchOrMatches)) {
        matchOrMatches.forEach(match =>
          updateMatchInRound(match, updatedRound)
        );
      } else {
        updateMatchInRound(matchOrMatches, updatedRound);
      }

      setRound(updatedRound);
    },
    [setRound, round, updateMatchInRound]
  );

  const updateDateInRound = useCallback(
    date => setRound({ ...cloneDeep(round), date }),
    [round, setRound]
  );

  const updateRoundNameInRound = useCallback(
    name => setRound({ ...cloneDeep(round), name }),
    [round, setRound]
  );

  if (round == null) {
    return null;
  }

  const { name, matches } = round;
  const teamsWithByes = getTeamsWithByes(matches, teams);
  const teamNamesWithByes = teamsWithByes.map(team => team.name);
  const isPending = roundStatus === AsyncStatus.pending;

  return (
    <Card border={isHighlighted ? "primary" : "neutral4"}>
      <InteractiveWrapper
        as={CardHeader}
        onClick={!isEditing && isExpandable ? toggleOpen : null}
      >
        <Heading>
          <HeadingTextContainer>
            <EditableText
              shouldMaintainWidth
              isDisabled={isPending || !isEditing}
              as={HeadingFive}
              value={name}
              onChange={updateRoundNameInRound}
            >
              {name}
            </EditableText>
            {isPending ? (
              <BeatLoader size={10} color="#333333" />
            ) : (
              isAuthenticated && (
                <DropdownMenu Trigger={<GoKebabVertical />} position="left">
                  <DropdownItem onClick={editRound}>Edit</DropdownItem>
                  <DropdownItem
                    as={Text}
                    color="error"
                    display="block"
                    onClick={dispatchRemoveRound}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              )
            )}
          </HeadingTextContainer>
          {!isEditing &&
            isExpandable &&
            (isOpen ? <GoChevronUp /> : <GoChevronDown />)}
          {isEditing && (
            <ButtonsContainer>
              <LinkButton onClick={cancelEdit} disabled={isPending}>
                Cancel
              </LinkButton>
              <Button
                color="success"
                disabled={isPending}
                onClick={dispatchUpdateRound}
              >
                Save
              </Button>
            </ButtonsContainer>
          )}
        </Heading>
        <RoundInlineDate
          isDisabled={!isEditing}
          date={round.date}
          onChange={updateDateInRound}
        />
      </InteractiveWrapper>
      {isOpen && (
        <>
          <CardBody>
            <MatchGroups
              updateMatch={
                isEditing && !isPending
                  ? updateMatchOrMatchesInRound
                  : undefined
              }
              matches={round.matches}
              teams={teams}
              status={roundStatus}
            />
          </CardBody>
          {teamNamesWithByes.length > 0 && (
            <CardFooter>
              <Text>
                <Text bold>Bye:</Text>
                {` ${teamNamesWithByes.join(", ")}`}
              </Text>
            </CardFooter>
          )}
        </>
      )}
    </Card>
  );
}

export default Round;
