import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "./Buttons";
import { Row, Col, Input, Label } from "./atomic";

export const SignupForm = ({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  isValid,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <Label for="first-name">First Name</Label>
      </Col>
      <Col xs={12}>
        <Input
          type="text"
          name="firstName"
          id="first-name"
          placeholder="John"
          invalid={touched.firstName && !!errors.firstName}
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Label for="last-name">Last Name</Label>
      </Col>
      <Col xs={12}>
        <Input
          type="text"
          name="lastName"
          id="last-name"
          placeholder="Citizen"
          invalid={touched.lastName && !!errors.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="manager@club.com.au"
          invalid={touched.email && !!errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Label for="password">Password</Label>
      </Col>
      <Col xs={12}>
        <Input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          invalid={touched.password && !!errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
          Sign Up
        </SubmitButton>
      </Col>
    </Row>
  </form>
);

SignupForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SignupForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  isValid: true,
  handleSubmit: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;

    try {
      if (onSubmit) await onSubmit(values);
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(SignupForm);
