import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as getClub } from "../actions/clubs";
import { get as getSeason } from "../actions/seasons";
import { get as getCompetition, update as updateCompetition } from "../actions/competitions";
import { getCompetitionById } from "../selectors/competitions";
import CompetitionForm from "../components/Competitions/CompetitionForm";
import { Card, CardBody, Row, Col, HeadingFour } from "../components/atomic";
import AsyncStatus from "../constants/AsyncStatus";
import { getAppStatus } from "../selectors/status";
import AppSpinner from "../components/AppSpinner";

export class EditCompetition extends Component {
  componentDidMount() {
    const {
      dispatchGetClub,
      clubId,
      dispatchGetSeason,
      seasonId,
      dispatchGetCompetition,
      competitionId,
    } = this.props;

    dispatchGetClub(clubId);
    dispatchGetSeason(clubId, seasonId);
    dispatchGetCompetition(clubId, seasonId, competitionId);
  }

  editCompetition = async competition => {
    const { clubId, seasonId, dispatchUpdateCompetition, history } = this.props;

    try {
      const action = await dispatchUpdateCompetition(clubId, seasonId, competition);
      const { data } = action.value;

      history.push(`/clubs/${clubId}/seasons/${seasonId}/competitions/${data.id}`);
    } catch (ex) {
      console.error(ex);
    }
  };

  render() {
    const { clubId, seasonId, competition, appStatus } = this.props;

    if (appStatus === AsyncStatus.pending) {
      return <AppSpinner />;
    }

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <HeadingFour color="primary">{`Updating ${competition.name}`}</HeadingFour>
                  </Col>
                  <Col xs={12}>
                    <CompetitionForm
                      clubId={clubId}
                      seasonId={seasonId}
                      competition={competition}
                      onSubmit={this.editCompetition}
                      saveButtonText={`Update "${competition.name}"`}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

EditCompetition.propTypes = {
  clubId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  competitionId: PropTypes.number.isRequired,
  competition: PropTypes.object,
  dispatchGetClub: PropTypes.func,
  dispatchGetSeason: PropTypes.func,
  dispatchGetCompetition: PropTypes.func,
  dispatchUpdateCompetition: PropTypes.func,
  appStatus: PropTypes.bool,
};

EditCompetition.defaultProps = {
  competition: {},
  dispatchGetClub: () => {},
  dispatchGetSeason: () => {},
  dispatchGetCompetition: () => {},
  dispatchUpdateCompetition: () => {},
  appStatus: AsyncStatus.unfetched,
};

export default connect(
  (state, { match }) => ({
    clubId: Number(match.params.clubId),
    seasonId: Number(match.params.seasonId),
    competition: getCompetitionById(state, Number(match.params.competitionId)),
    competitionId: Number(match.params.competitionId),
    appStatus: getAppStatus(state),
  }),
  {
    dispatchGetClub: getClub,
    dispatchGetSeason: getSeason,
    dispatchGetCompetition: getCompetition,
    dispatchUpdateCompetition: updateCompetition,
  },
)(EditCompetition);
