import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { GoKebabVertical } from "react-icons/go";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import { remove as removeRound, update as updateRound } from "../../actions/rounds";
import RoundInlineDate from "./RoundInlineDate";
import { getRoundByID, getRoundStatusByID } from "../../selectors/rounds";
import { getTeams } from "../../selectors/teams";
import { getIsClubOwner } from "../../selectors/user";
import { getTeamsWithByes } from "../../../shared/utils/matches";
import Match from "./Match";
import AsyncStatus from "../../constants/AsyncStatus";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Divider,
  DropdownMenu,
  DropdownItem,
  EditableText,
  HeadingFive,
  LinkButton,
  CardFooter,
  Text,
  InteractiveWrapper,
  Row,
  Col,
} from "../atomic";
import ScrollableTabs from "../ScrollableTabs/ScrollableTabs.react";
import ScrollableTabItem from "../ScrollableTabs/ScrollableTabItem.react";
import useBoolean from "../../hooks/useBoolean";
import usePrevious from "../../hooks/usePrevious";

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeadingTextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: "center";
  & > * {
    margin-left: 0.25em;
  }
`;

function Round({ roundID, isHighlighted }) {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();

  const rawRound = useSelector(getRoundByID(roundID));
  const roundStatus = useSelector(getRoundStatusByID(roundID));
  const teams = useSelector(getTeams);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const [round, setRound] = useState(rawRound);

  const times = useMemo(() => {
    const consolidatedTimes = new Set();
    rawRound.matches.forEach(match => consolidatedTimes.add(match.time));
    return Array.from(consolidatedTimes);
  }, [rawRound]);

  const [time, setTime] = useState(times[0]);

  const matches = useMemo(
    () => round.matches.filter(match => moment(match.time).isSame(time, "minute"), [round, time]),
    [round, time],
  );

  useEffect(() => {
    setRound(rawRound);
  }, [rawRound]);

  const previousRoundID = usePrevious(roundID);
  useEffect(() => {
    if (previousRoundID !== roundID) {
      setTime(times[0]);
    }
  }, [previousRoundID, roundID, setTime, times]);

  const { value: isEditing, setTrue: setEditing, setFalse: setUnediting } = useBoolean(false);

  const editRound = useCallback(() => {
    setEditing();
    setRound(cloneDeep(rawRound));
  }, [setEditing, rawRound]);

  const cancelEdit = useCallback(() => {
    setUnediting();
    setRound(rawRound);
  }, [setUnediting, setRound, rawRound]);

  const dispatchRemoveRound = useCallback(async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${round.name}? This can't be undone.`,
    );

    if (confirmed) {
      await dispatch(removeRound(clubId, seasonId, competitionId, roundID));
    }
  }, [clubId, seasonId, competitionId, roundID, round, dispatch]);

  const dispatchUpdateRound = useCallback(async () => {
    await dispatch(updateRound(clubId, seasonId, competitionId, round)).finally(setUnediting);
  }, [dispatch, clubId, seasonId, competitionId, round, setUnediting]);

  const updateMatchInRound = useCallback(
    match => {
      const updatedRound = cloneDeep(round);
      const matchIndex = updatedRound.matches.findIndex(_match => _match.id === match.id);

      if (matchIndex > -1) {
        updatedRound.matches[matchIndex] = match;
      }

      setRound(updatedRound);
    },
    [setRound, round],
  );

  const updateDateInRound = useCallback(date => setRound({ ...cloneDeep(round), date }), [
    round,
    setRound,
  ]);

  const updateRoundNameInRound = useCallback(name => setRound({ ...cloneDeep(round), name }), [
    round,
    setRound,
  ]);

  if (round == null) {
    return null;
  }

  const { name } = round;
  const teamsWithByes = getTeamsWithByes(round.matches, teams);
  const teamNamesWithByes = teamsWithByes.map(team => team.name);
  const isPending = roundStatus === AsyncStatus.pending;

  return (
    <Card border={isHighlighted ? "primary" : "neutral4"}>
      <InteractiveWrapper as={CardHeader}>
        <Heading>
          <HeadingTextContainer>
            <EditableText
              shouldMaintainWidth
              isDisabled={isPending || !isEditing}
              as={HeadingFive}
              value={name}
              onChange={updateRoundNameInRound}
            >
              {name}
            </EditableText>
            {isPending ? (
              <BeatLoader size={10} color="#333333" />
            ) : (
              isAuthenticated && (
                <DropdownMenu Trigger={<GoKebabVertical />} position="left">
                  <DropdownItem onClick={editRound}>Edit</DropdownItem>
                  <DropdownItem
                    as={Text}
                    color="error"
                    display="block"
                    onClick={dispatchRemoveRound}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              )
            )}
          </HeadingTextContainer>
          {isEditing && (
            <ButtonsContainer>
              <LinkButton onClick={cancelEdit} disabled={isPending}>
                Cancel
              </LinkButton>
              <Button color="success" disabled={isPending} onClick={dispatchUpdateRound}>
                Save
              </Button>
            </ButtonsContainer>
          )}
        </Heading>
        <RoundInlineDate isDisabled={!isEditing} date={round.date} onChange={updateDateInRound} />
      </InteractiveWrapper>
      <ScrollableTabs value={time} onChange={setTime}>
        {times.map(matchTime => (
          <ScrollableTabItem key={matchTime} value={matchTime}>
            {moment(matchTime).format("LT")}
          </ScrollableTabItem>
        ))}
      </ScrollableTabs>
      <CardBody>
        {matches.map(match => (
          <Row key={match.id}>
            <Col xs={12}>
              <Match
                match={match}
                matches={matches}
                teams={teams}
                updateMatch={isEditing ? updateMatchInRound : null}
                isMobile
              />
              <Divider />
            </Col>
          </Row>
        ))}
      </CardBody>
      {teamNamesWithByes.length > 0 && (
        <CardFooter>
          <Text>
            <Text bold>Bye:</Text>
            {` ${teamNamesWithByes.join(", ")}`}
          </Text>
        </CardFooter>
      )}
    </Card>
  );
}

export default Round;
