import React from "react";
import styled from "styled-components";
import FadeIn from "../animations/FadeIn";
import Portal from "./Portal";

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  animation: ${FadeIn} 500ms linear;
  z-index: 5;
`;

const Overlay = ({ children, ...rest }) => (
  <>
    <Portal>
      <OverlayContainer {...rest}>{children}</OverlayContainer>
    </Portal>
  </>
);

export default Overlay;
