import api, { assets } from ".";

export const get = async (clubId, seasonId, competitionId, id) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams/${id}`);

export const remove = async (clubId, seasonId, competitionId, id) =>
  api.delete(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams/${id}`);
export const fetch = async (clubId, seasonId, competitionId) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams`);
export const fetchForClub = async (clubId, query) =>
  api.get(`/clubs/${clubId}/teams`, {
    params: {
      query,
    },
  });
export const create = async (clubId, seasonId, competitionId, team) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams`, team);

export const update = async (clubId, seasonId, competitionId, team) =>
  api.put(
    `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams/${team.id}`,
    team,
  );

export const updateBulk = async (clubId, seasonId, competitionId, teams) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams/bulk`, {
    teams,
  });

export const downloadTemplate = async () =>
  assets.get(`teams/template`, {
    responseType: "blob",
  });

export const upload = async (clubId, seasonId, competitionId, file, onProgress = () => {}) => {
  const data = new FormData();
  data.append("file", file);

  const config = {
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    },
  };

  return assets.put(
    `clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams`,
    data,
    config,
  );
};

export default {
  get,
  remove,
  fetch,
  fetchForClub,
  create,
  update,
  updateBulk,
  downloadTemplate,
  upload,
};
