import { useCallback, useEffect, useRef } from "react";

/**
 * Helper hook to trigger a given function when clicking off an element
 */
function useOnClickOutside(callback) {
  const ref = useRef();

  const listener = useCallback(
    event => {
      if (ref.current != null && !ref.current.contains(event.target)) {
        callback(event);
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [listener]);

  return ref;
}

export default useOnClickOutside;
