import AsyncStatus from "../constants/AsyncStatus";

const getFetchingForKeys = (keys = []) => state => {
  const values = keys.map(key => state.status[key].fetching);

  const fetching = values.some(value => value === AsyncStatus.pending);
  if (fetching) {
    return AsyncStatus.pending;
  }

  const error = values.some(value => value === AsyncStatus.failed);
  if (error) {
    return AsyncStatus.failed;
  }

  const success = values.every(value => value === AsyncStatus.success);
  if (success) {
    return AsyncStatus.success;
  }

  return AsyncStatus.unfetched;
};

export const getClubsStatus = getFetchingForKeys(["clubs"]);
export const getSeasonsStatus = getFetchingForKeys(["clubs", "seasons"]);
export const getCompetitionsStatus = getFetchingForKeys([
  "clubs",
  "seasons",
  "competitions",
  "teams",
  "rounds",
]);
export const getRoundStatus = id => state => state.status.rounds[id];
export const getAppStatus = getFetchingForKeys([
  "clubs",
  "seasons",
  "competitions",
  "teams",
  "rounds",
]);
