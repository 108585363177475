import React from "react";
import PropTypes from "prop-types";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import Card from "../atoms/Card";
import CardHeader from "../atoms/CardHeader";
import HeadingFive from "../typography/HeadingFive";
import Toggle from "../utility/Toggle";
import Float from "../utility/Float";
import InteractiveWrapper from "../utility/InteractiveWrapper";

const ExpandableCard = ({ title, children, ...rest }) => (
  <Card border="neutral4" {...rest}>
    <Toggle defaultActive disableEscape disableClickOff>
      {({ active, toggle }) => (
        <>
          <InteractiveWrapper as={CardHeader} onClick={toggle}>
            <HeadingFive>
              <span>{title}</span>
              <Float float="right" as="span">
                {active ? <GoChevronUp /> : <GoChevronDown />}
              </Float>
            </HeadingFive>
          </InteractiveWrapper>
          {active && children}
        </>
      )}
    </Toggle>
  </Card>
);

ExpandableCard.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any.isRequired
};

ExpandableCard.defaultProps = {
  title: null
};

export default ExpandableCard;
