import api from ".";

const getAuthBearerHeader = token => ({ Authorization: `bearer ${token}` });
export const logout = async () => api.get("/auth/logout");
export const login = async user => api.post("/auth/login", user);
export const forgot = async user => api.post("/auth/forgot", user);
export const reset = async (user, token) =>
  api.post("/auth/reset", user, { headers: { ...getAuthBearerHeader(token) } });

export default {
  logout,
  login,
  forgot,
  reset,
};
