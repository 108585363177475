import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import TeamForm from "../components/Teams/TeamForm";
import {
  create as createTeam,
  fetch as fetchTeams,
  fetchForClub as fetchTeamsForClub,
} from "../actions/teams";
import { get as getCompetition } from "../actions/competitions";
import { getCompetitionById } from "../selectors/competitions";
import { getTeams, getTeamsForClub } from "../selectors/teams";
import { Row, Col, Card, HeadingFour, CardBody } from "../components/atomic";
import AsyncStatus from "../constants/AsyncStatus";
import { getAppStatus } from "../selectors/status";
import AppSpinner from "../components/AppSpinner";

export class AddTeam extends Component {
  static propTypes = {
    clubId: PropTypes.number.isRequired,
    seasonId: PropTypes.number.isRequired,
    competitionId: PropTypes.number.isRequired,
    competition: PropTypes.object,
    dispatchGetCompetition: PropTypes.func,
    dispatchCreateTeam: PropTypes.func,
    dispatchFetchTeams: PropTypes.func,
    dispatchFetchTeamsForClub: PropTypes.func,
    teams: PropTypes.array,
    clubTeams: PropTypes.array,
    appStatus: PropTypes.string,
  };

  static defaultProps = {
    competition: {},
    dispatchGetCompetition: () => {},
    dispatchFetchTeams: () => {},
    dispatchCreateTeam: () => {},
    dispatchFetchTeamsForClub: () => {},
    teams: [],
    clubTeams: [],
    appStatus: AsyncStatus.unfetched,
  };

  componentDidMount() {
    const {
      clubId,
      seasonId,
      competitionId,
      dispatchFetchTeams,
      dispatchFetchTeamsForClub,
      dispatchGetCompetition,
    } = this.props;

    dispatchFetchTeams(clubId, seasonId, competitionId);
    dispatchFetchTeamsForClub(clubId, null);
    dispatchGetCompetition(clubId, seasonId, competitionId);
  }

  addTeam = async team => {
    const { clubId, seasonId, competitionId, dispatchCreateTeam, history } = this.props;

    try {
      await dispatchCreateTeam(clubId, seasonId, competitionId, team);

      history.push(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/teams`);
    } catch (ex) {
      console.error(ex);
    }
  };

  render() {
    const {
      clubId,
      seasonId,
      competitionId,
      competition,
      teams,
      clubTeams,
      appStatus,
      dispatchFetchTeamsForClub,
    } = this.props;

    if (appStatus === AsyncStatus.pending) {
      return <AppSpinner />;
    }

    const { timeslots: competitionTimeslots = [] } = competition;
    const timeslots = competitionTimeslots.map(timeslot => timeslot.time);

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <HeadingFour color="primary">Add new team</HeadingFour>
                  </Col>
                  <Col xs={12}>
                    <TeamForm
                      clubId={clubId}
                      seasonId={seasonId}
                      competitionId={competitionId}
                      teams={teams}
                      clubTeams={clubTeams}
                      timeslots={timeslots}
                      onSubmit={this.addTeam}
                      debouncedDispatchFetchTeamsForClub={debounce(dispatchFetchTeamsForClub, 300)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(
  (state, { match }) => ({
    clubId: Number(match.params.clubId),
    seasonId: Number(match.params.seasonId),
    competitionId: Number(match.params.competitionId),
    competition: getCompetitionById(state, Number(match.params.competitionId)),
    teams: getTeams(state),
    clubTeams: getTeamsForClub(state),
    appStatus: getAppStatus(state),
  }),
  {
    dispatchFetchTeams: fetchTeams,
    dispatchFetchTeamsForClub: fetchTeamsForClub,
    dispatchGetCompetition: getCompetition,
    dispatchCreateTeam: createTeam,
  },
)(AddTeam);
