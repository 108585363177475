import { useCallback, useEffect, useRef, useState } from "react";

function useTouchScroll({ onStart, onChange, onDragEnd, onEnd }) {
  const ref = useRef();
  const origin = useRef({ x: null, y: null });
  const [isDragging, setDragging] = useState(false);

  const handleMove = useCallback(
    e => {
      if (isDragging) {
        const event = e.type === "touchmove" ? e.touches[0] : e;
        const { clientX: x, clientY: y } = event;
        onChange({
          x,
          y,
          distanceX: x - origin.current.x,
          distanceY: y - origin.current.y,
        });
        origin.current = { x, y };
      }
    },
    [onChange, origin, isDragging],
  );

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      onDragEnd();
    }

    setDragging(false);
    onEnd();
  }, [isDragging, onDragEnd, onEnd]);

  const handleMouseDown = useCallback(
    e => {
      onStart();

      const event = e.type === "touchstart" ? e.touches[0] : e;
      origin.current = { x: event.clientX, y: event.clientY };

      setDragging(true);
    },
    [origin, onStart],
  );

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("touchmove", handleMove);
    }

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleMove);
    };
  }, [isDragging, handleMove]);

  useEffect(() => {
    const node = ref.current;
    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("touchstart", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);
    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("touchstart", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [ref, handleMouseUp, handleMouseDown, handleMove]);

  return ref;
}

export default useTouchScroll;
