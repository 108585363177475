import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get as getClub } from "../actions/clubs";
import { get as getSeason } from "../actions/seasons";
import { create as createCompetition } from "../actions/competitions";
import CompetitionForm from "../components/Competitions/CompetitionForm";
import { Row, Col, Card, HeadingFour, CardBody } from "../components/atomic";
import AsyncStatus from "../constants/AsyncStatus";
import { getAppStatus } from "../selectors/status";
import AppSpinner from "../components/AppSpinner";

export class AddCompetition extends Component {
  componentDidMount() {
    const { dispatchGetClub, clubId, dispatchGetSeason, seasonId } = this.props;

    dispatchGetClub(clubId);
    dispatchGetSeason(clubId, seasonId);
  }

  addCompetition = async competition => {
    const { clubId, seasonId, dispatchCreateCompetition, history } = this.props;

    try {
      const action = await dispatchCreateCompetition(clubId, seasonId, competition);
      const { data } = action.value;

      history.push(`/clubs/${clubId}/seasons/${seasonId}/competitions/${data.id}`);
    } catch (ex) {
      console.error(ex);
    }
  };

  render() {
    const { clubId, seasonId, appStatus } = this.props;

    if (appStatus === AsyncStatus.pending) {
      return <AppSpinner />;
    }

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <HeadingFour color="primary">Add new competition</HeadingFour>
                  </Col>
                  <Col xs={12}>
                    <CompetitionForm
                      clubId={clubId}
                      seasonId={seasonId}
                      onSubmit={this.addCompetition}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

AddCompetition.propTypes = {
  clubId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  dispatchGetClub: PropTypes.func,
  dispatchGetSeason: PropTypes.func,
  dispatchCreateCompetition: PropTypes.func,
  appStatus: PropTypes.string,
};

AddCompetition.defaultProps = {
  dispatchGetClub: () => {},
  dispatchGetSeason: () => {},
  dispatchCreateCompetition: () => {},
  appStatus: AsyncStatus.unfetched,
};

export default connect(
  (state, { match }) => ({
    clubId: Number(match.params.clubId),
    seasonId: Number(match.params.seasonId),
    appStatus: getAppStatus(state),
  }),
  {
    dispatchGetClub: getClub,
    dispatchGetSeason: getSeason,
    dispatchCreateCompetition: createCompetition,
  },
)(AddCompetition);
