import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ImportTeamsStep1 from "./ImportTeamsStep1";
import ImportTeamsStep2 from "./ImportTeamsStep2";
import ImportTeamsStep3 from "./ImportTeamsStep3";
import { Row, Col, Modal, ModalHeader, ModalFooter, Button } from "../../atomic";
import {
  fetch as fetchTeams,
  downloadTemplate as downloadTeamTemplate,
  upload as uploadTeams,
  clearUpload,
  types as TeamTypes,
} from "../../../actions/teams";
import { getFormStatusByType, getFormResponseByType } from "../../../selectors/forms";
import useCounter from "../../../hooks/useCounter";

function ImportTeamsModal({ isOpen, toggle }) {
  const dispatch = useDispatch();

  const { value: step, increment, decrement } = useCounter(1);
  const { clubId, seasonId, competitionId } = useParams();

  const uploadStatus = useSelector(getFormStatusByType(TeamTypes.upload));
  const uploadResponse = useSelector(getFormResponseByType(TeamTypes.upload));
  const uploadTeamTemplate = useCallback(
    async files => {
      await dispatch(uploadTeams(clubId, seasonId, competitionId, files[0]));

      setTimeout(() => {
        dispatch(fetchTeams(clubId, seasonId, competitionId));
        toggle();
      }, 3000);
    },
    [clubId, seasonId, competitionId, dispatch, toggle],
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(clearUpload());
    }
  }, [dispatch, isOpen]);

  const steps = [
    <ImportTeamsStep1 onClick={() => downloadTeamTemplate("Team Import Form.xlsx")} />,
    <ImportTeamsStep2 />,
    <ImportTeamsStep3
      onDrop={uploadTeamTemplate}
      uploadStatus={uploadStatus}
      uploadResponse={uploadResponse}
    />,
  ];

  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {`Importing Teams (Step ${step} of ${steps.length})`}
      </ModalHeader>
      {steps[step - 1]}
      <ModalFooter>
        <Row>
          {step > 1 && (
            <Col xs={6}>
              <Button color="secondary" onClick={decrement}>
                Back
              </Button>
            </Col>
          )}
          <Col xs={step > 1 ? 6 : 12}>
            <Button disabled={step >= steps.length} color="primary" onClick={increment}>
              Next
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

export default ImportTeamsModal;
