import styled from "styled-components";

const Container = styled.div`
  padding: 1em;
  margin-right: auto;
  margin-left: auto;
  flex: 1;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 960px;
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 1140px;
  }
`;

Container.displayName = "Container";

export default Container;
