import styled from "styled-components";
import Paragraph from "./Paragraph";

const DisabledParagraph = styled(Paragraph)`
  margin: 1em 0;
`;

DisabledParagraph.defaultProps = {
  color: "neutral2",
  width: "100%",
  align: "center",
};

export default DisabledParagraph;
