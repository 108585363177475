import api from ".";

export const get = async id => api.get(`/clubs/${id}`);
export const update = async club => api.put(`/clubs/${club.id}`, club);
export const fetch = async () => api.get("/clubs");
export const create = async club => api.post("/clubs", club);

export default {
  get,
  update,
  fetch,
  create,
};
