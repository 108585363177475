import React from "react";
import PropTypes from "prop-types";
import { ListItemGroup } from "../atomic";
import RemovableItemListItem from "./RemovableItemListItem";

const RemovableItemList = ({ items = [], onRemove, ...rest }) => (
  <ListItemGroup {...rest}>
    {items.map((item, index) => (
      // eslint-disable-next-line
      <RemovableItemListItem key={index} onRemove={() => onRemove(index)}>
        {item}
      </RemovableItemListItem>
    ))}
  </ListItemGroup>
);

RemovableItemList.propTypes = {
  items: PropTypes.array,
  onRemove: PropTypes.func,
};

RemovableItemList.defaultProps = {
  items: [],
  onRemove: () => {},
};

export default RemovableItemList;
