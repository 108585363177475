import styled from "styled-components";

const Float = styled.div`
  float: ${props => props.float};
`;

Float.defaultProps = {
  float: "right",
};

export default Float;
