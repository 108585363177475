export const types = {
  toggleModal: "TOGGLE_MODAL",
};

export function toggleModal(modal) {
  return {
    type: types.toggleModal,
    payload: modal,
  };
}
