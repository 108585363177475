import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Text, Paragraph } from "../atomic";
import { getFinalsMatches } from "../../../shared/utils/matches";

const FinalsMatchupPreview = ({ error, teams, ...rest }) => {
  const matchups = getFinalsMatches(teams);

  let displayError = error;
  if (!displayError) {
    if (teams.length === 0) {
      displayError = "Select some teams to play in your finals";
    } else if (teams.length % 2 > 0) {
      displayError = "You need an even amount of teams to play in a final";
    }
  }

  return (
    <Card color={displayError ? "error" : "neutral4"} {...rest}>
      <CardBody>
        {displayError && (
          <Paragraph align="center" color="neutral5">
            {displayError}
          </Paragraph>
        )}
        {!error &&
          teams.length > 0 &&
          teams.length % 2 === 0 &&
          matchups.map(matchup => (
            <Row key={matchup.homeTeam.id}>
              <Col xs={5}>
                <Text bold>{matchup.homeTeam.ranking}</Text>
                <Text>
                  &nbsp;
                  {matchup.homeTeam.name}
                </Text>
              </Col>
              <Col xs={2}>vs</Col>
              <Col xs={5}>
                <Text bold>{matchup.awayTeam.ranking}</Text>
                <Text>
                  &nbsp;
                  {matchup.awayTeam.name}
                </Text>
              </Col>
            </Row>
          ))}
      </CardBody>
    </Card>
  );
};

FinalsMatchupPreview.propTypes = {
  teams: PropTypes.array,
};

FinalsMatchupPreview.defaultProps = {
  teams: [],
};

export default FinalsMatchupPreview;
