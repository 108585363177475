import moment from "moment";
import shuffle from "lodash.shuffle";
import uniq from "lodash.uniq";
import orderBy from "lodash.orderby";
import { pairTeamsByMatchups, groupTeamsByDivision } from "./teams";
import { getMatchupsForTeamSizeAndRound } from "./matchups";

import MatchTeamStatus from "../constants/MatchTeamStatus";

export const groupMatchesByTime = matches =>
  matches.reduce((aggregate, match) => {
    const { time } = match;
    const group = aggregate[time] || [];

    return {
      ...aggregate,
      [time]: group.concat(match),
    };
  }, {});

export const groupMatchesByName = matches =>
  matches.reduce((matchAggregate, match) => {
    if (!match.homeTeam || !match.awayTeam) {
      return matchAggregate;
    }

    const { name } = match;
    const matchesForName = matchAggregate[name] || [];

    return { ...matchAggregate, [name]: [...matchesForName, match] };
  }, {});

export const getTeamsWithByes = (matches, teams) => {
  const playingTeams = matches.reduce(
    (aggregate, match) => [...aggregate, match.homeTeamId, match.awayTeamId],
    [],
  );

  return teams.filter(team => !playingTeams.includes(team.id));
};

export const getClashesForTeam = (team, matches) => {
  if (!team || team.clashes == null) {
    return [];
  }

  const matchIds = matches.reduce(
    (aggregate, match) => [...aggregate, match.homeTeamId, match.awayTeamId],
    [],
  );

  return team.clashes.filter(clash => matchIds.includes(clash.id));
};

export const getWinner = match => {
  const { homeScore, awayScore } = match;

  if (homeScore !== null && awayScore !== null) {
    if (match.homeScore > match.awayScore) {
      return match.homeTeam;
    }
    if (match.awayScore > match.homeScore) {
      return match.awayTeam;
    }
  }

  return null;
};

export const getWinnerId = match => {
  const { homeScore, awayScore } = match;

  if (!homeScore !== null && !awayScore !== null) {
    if (match.homeScore > match.awayScore) {
      return match.homeTeamId;
    }
    if (match.awayScore > match.homeScore) {
      return match.awayTeamId;
    }
  }

  return null;
};

export const getPointsForTeam = (team, match) => {
  if (match.homeTeamId === team.id) {
    return match.homeScore;
  }
  if (match.awayTeamId === team.id) {
    return match.awayScore;
  }

  return 0;
};

export const getOpposingPoints = (team, match) => {
  if (match.homeTeamId === team.id) {
    return match.awayScore;
  }
  if (match.awayTeamId === team.id) {
    return match.homeScore;
  }
  return 0;
};

export const getIncludedMatches = (team, rounds = []) =>
  rounds.reduce(
    (aggregate, round) => [
      ...aggregate,
      ...round.matches.filter(
        match => match.homeTeamId === team.id || match.awayTeamId === team.id,
      ),
    ],
    [],
  );

export const getForfeitMatches = (team, matches = []) =>
  matches.filter(
    match =>
      (match.homeTeamStatus === MatchTeamStatus.forfeit && match.homeTeamId === team.id) ||
      (match.awayTeamStatus === MatchTeamStatus.forfeit && match.awayTeamId === team.id),
  );

export const generateMatchTemplates = (round, timeslots, fields) => {
  const { years: year, months: month, date } = moment(round.date).toObject();

  return timeslots.reduce((aggregate, timeslot) => {
    const matches = fields.map(field => ({
      roundId: round.id,
      homeTeamId: null,
      awayTeamId: null,
      field: field.name,
      time: moment(timeslot.time)
        .set({ year, month, date })
        .toDate(),
    }));

    return [...aggregate, ...matches];
  }, []);
};

export const generateMatchups = (teams, roundNumber) => {
  const teamsByDivision = groupTeamsByDivision(teams);
  const matchups = Object.keys(teamsByDivision).reduce((aggregate, divisionName) => {
    const division = orderBy(teamsByDivision[divisionName], "order", "asc");
    const matchupTemplate = getMatchupsForTeamSizeAndRound(division.length, roundNumber);

    return [...aggregate, ...pairTeamsByMatchups(division, matchupTemplate)];
  }, []);

  return matchups;
};

export const doesTeamClashWithMatch = (team, match) => {
  if (!team) {
    return false;
  }

  const { homeTeamId, awayTeamId } = match;
  const clashes = team.clashes.map(clash => clash.id);
  return clashes.includes(homeTeamId) || clashes.includes(awayTeamId);
};

export const doesTeamPreferMatch = (team, match) => {
  if (!team) {
    return true;
  }

  const { preferences } = team;

  return preferences.some(preference => moment(preference).isSame(match.time, "minutes"));
};

export const getOpponent = (teamId, match) => {
  if (match.homeTeamId === teamId) {
    return match.awayTeam;
  }

  if (match.awayTeamId === teamId) {
    return match.homeTeam;
  }

  return null;
};

export const getClashes = (matches, teams, excludeOpponents = false) => {
  const clashes = matches.reduce((aggregate, match) => {
    const { homeTeamId, awayTeamId, time } = match;

    const sameTimeMatches = matches.filter(otherMatch => {
      const sameTime = moment(otherMatch.time).isSame(time, "minutes");

      // If we're excluding clashing opponents, then filter out the current match
      return sameTime && (!excludeOpponents || otherMatch !== match);
    });

    const homeTeam = teams.find(team => team.id === homeTeamId);
    const homeClashes = sameTimeMatches.filter(otherMatch =>
      doesTeamClashWithMatch(homeTeam, otherMatch),
    );

    const awayTeam = teams.find(team => team.id === awayTeamId);
    const awayClashes = sameTimeMatches.filter(otherMatch =>
      doesTeamClashWithMatch(awayTeam, otherMatch),
    );

    return [...aggregate, ...homeClashes, ...awayClashes];
  }, []);

  return clashes;
};

export const populateMatchTemplates = (matchups, matchTemplates) => {
  const shuffledMatchups = shuffle(matchups);
  const shuffledMatchTemplates = shuffle(matchTemplates);

  const matches = shuffledMatchups.reduce((templates, matchup) => {
    // If it's a BYE match, then don't put it into a time
    if (matchup.some(teamId => !teamId)) {
      return templates;
    }

    const unusedTemplates = templates.filter(
      template => !template.homeTeamId && !template.awayTeamId,
    );

    const usedTemplates = templates.filter(
      template => !!template.homeTeamId || !!template.awayTeamId,
    );

    const availableTimeslots = uniq(unusedTemplates.map(template => template.time));

    const nonClashingTimeslots = availableTimeslots.filter(timeslot => {
      const usedTemplatesInTimeslot = usedTemplates.filter(template => template.time === timeslot);

      return (
        usedTemplatesInTimeslot.length === 0 ||
        usedTemplatesInTimeslot.some(
          template =>
            !(
              doesTeamClashWithMatch(matchup[0], template) ||
              doesTeamClashWithMatch(matchup[1], template)
            ),
        )
      );
    });

    // Filter out any clashing timeslots
    const nonClashingUnusedTemplates = unusedTemplates.filter(template =>
      nonClashingTimeslots.includes(template.time),
    );

    let templateToUse = {
      name: matchup[2], // TODO: HACK - REFACTOR
      homeTeamId: matchup[0] ? matchup[0].id : null,
      awayTeamId: matchup[1] ? matchup[1].id : null,
    };

    // By default, just get any unused templates. But, if we have some templates without clashes - use those
    let templateListToUse = unusedTemplates;
    if (nonClashingUnusedTemplates.length > 0) {
      templateListToUse = nonClashingUnusedTemplates;
    }

    const preferedTemplates = templateListToUse.filter(
      match => doesTeamPreferMatch(matchup[0], match) && doesTeamPreferMatch(matchup[1], match),
    );

    if (preferedTemplates.length > 0) {
      templateListToUse = preferedTemplates;
    }

    const index = Math.floor(Math.random() * templateListToUse.length);

    templateToUse = {
      ...templateListToUse[index],
      ...templateToUse,
    };

    const otherTemplates = templates.filter(
      template => !(template.time === templateToUse.time && template.field === templateToUse.field),
    );

    return [...otherTemplates, templateToUse];
  }, shuffledMatchTemplates);

  return orderBy(matches, ["time", "field"]);
};

export const getFinalsMatches = (teams, name) => {
  const copy = [...teams];
  const matchups = [];

  if (copy.length % 2 === 0) {
    while (copy.length > 0) {
      const homeTeam = copy.shift();
      const awayTeam = copy.pop();

      matchups.push({
        name,
        homeTeam,
        awayTeam,
      });
    }
  }

  return matchups;
};

export const hasMatchBeenPlayed = match =>
  (match.homeTeamId === null && match.awayTeamId === null) ||
  ((match.homeScore !== null || match.homeTeamStatus === MatchTeamStatus.forfeit) &&
    (match.awayScore !== null || match.awayTeamStatus === MatchTeamStatus.forfeit));

export const getFinalsTypeFromNumberOfMatches = length => {
  switch (length) {
    case 1: {
      return "Grand Finals";
    }
    case 2: {
      return "Semi Finals";
    }
    case 4: {
      return "Quarter Finals";
    }
    case 8: {
      return "Eigth Finals";
    }
    case 16: {
      return "16th Finals";
    }
    case 32: {
      return "32nd Finals";
    }
    case 64: {
      return "64th Finals";
    }
    default: {
      return null;
    }
  }
};
