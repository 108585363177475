import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import { Row, Col, Label, Select, Input } from "../atomic";
import BlockDatePicker from "../DatePickers/BlockDatePicker";
import { SubmitButton } from "../Buttons";

const CreateFinalsForm = ({
  values,
  isValid,
  handleSubmit,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
}) => {
  const startDate = new Date();
  const { limit, date } = values;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Label htmlFor="limit">Elimination Finals</Label>
          <Select
            id="limit"
            name="limit"
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
            value={limit}
          >
            <option value={4}>Semi Finals (Top 4)</option>
            <option value={2}>Grand Finals (Top 2)</option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Label htmlFor="date">Starting Date</Label>
          <BlockDatePicker
            id="date"
            name="date"
            customInput={<Input onBlur={handleBlur} />}
            autoComplete="off"
            disabledKeyboardNavigation
            onChange={_date => setFieldValue("date", _date)}
            selected={date}
            placeholderText={startDate.toLocaleDateString()}
            shouldCloseOnSelect
            todayButton="Today"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
            Preview Finals
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

CreateFinalsForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

CreateFinalsForm.defaultProps = {
  values: {},
  isValid: false,
  handleSubmit: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  isSubmitting: false,
  setFieldValue: () => {},
};

const mapPropsToValues = ({ limit, date }) => ({ limit, date });

const validationSchema = yup.object().shape({
  limit: yup.string().required(),
  date: yup.date().required(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit: (values, { props }) => {
    const { onSubmit } = props;
    const { limit, date } = values;

    onSubmit({ limit, date });
  },
})(CreateFinalsForm);
