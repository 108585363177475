import moment from "moment";
import last from "lodash.last";
import orderBy from "lodash.orderby";

import { groupMatchesByName, hasMatchBeenPlayed } from "./matches";

export const getPlayedRounds = (rounds = []) =>
  rounds.filter(round => round.matches.some(match => hasMatchBeenPlayed(match)));

export const getRoundsThatContainTeam = (team, rounds = []) =>
  rounds.filter(round =>
    round.matches.some(match => match.homeTeamId === team.id || match.awayTeamId === team.id),
  );

export const groupRoundsByMatchNames = rounds =>
  rounds.reduce((aggregate, round) => {
    const { matches } = round;

    const matchesByName = groupMatchesByName(matches);

    const names = Object.keys(matchesByName);
    names.sort();

    const namedRounds = names.reduce((nameAggregate, name) => {
      const existingRounds = aggregate[name] || [];

      return {
        ...nameAggregate,
        [name]: [...existingRounds, { ...round, matches: matchesByName[name] }],
      };
    }, {});

    return { ...aggregate, ...namedRounds };
  }, {});

export const populateDummyFinalsByDivision = finalsByDivision => {
  const divisions = Object.keys(finalsByDivision);

  return divisions.reduce((aggregate, division) => {
    const finals = orderBy([...finalsByDivision[division]], "createdAt", "asc");

    let lastFinals = last(finals);
    let nextNumberOfMatches = lastFinals.matches.length / 2;

    // We want to keep halving the amount of finals until we can't anymore
    while (nextNumberOfMatches >= 1) {
      const matches = [];
      for (let i = 0; i < nextNumberOfMatches; i += 1) {
        matches.push({});
      }

      finals.push({
        id: null,
        date: moment(lastFinals.date)
          .add("1", "week")
          .toISOString(),
        matches,
      });

      lastFinals = last(finals);
      nextNumberOfMatches = lastFinals.matches.length / 2;
    }

    return { ...aggregate, [division]: finals };
  }, {});
};
