import { types as TeamsActions } from "../actions/teams";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  response: {},
  status: {},
};

const FormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${TeamsActions.upload}_CLEAR`: {
      const status = {
        ...state.status,
        [TeamsActions.upload]: null,
      };

      const response = {
        ...state.response,
        [TeamsActions.upload]: null,
      };

      return { ...state, status, response };
    }
    case `${TeamsActions.upload}_PENDING`: {
      const status = {
        ...state.status,
        [TeamsActions.upload]: AsyncStatus.pending,
      };

      const response = {
        ...state.response,
        [TeamsActions.upload]: null,
      };

      return { ...state, status, response };
    }
    case `${TeamsActions.upload}_FULFILLED`: {
      const status = {
        ...state.status,
        [TeamsActions.upload]: AsyncStatus.success,
      };

      const response = {
        ...state.response,
        [TeamsActions.upload]: null,
      };

      return { ...state, status, response };
    }
    case `${TeamsActions.upload}_REJECTED`: {
      const status = {
        ...state.status,
        [TeamsActions.upload]: AsyncStatus.failed,
      };

      const { payload } = action;
      const response = {
        ...state.response,
        [TeamsActions.upload]: payload.response.data,
      };

      return { ...state, status, response };
    }
    default: {
      return { ...state };
    }
  }
};

export default FormsReducer;
