import { types as AuthActions } from "../actions/auth";
import { types as CompetitionsActions } from "../actions/competitions";
import { types as RoundsActions } from "../actions/rounds";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  status: {},
};

const CompetitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case `${CompetitionsActions.get}_PENDING`: {
      const { meta } = action;
      const status = {
        ...state.status,
        [meta.id]: AsyncStatus.pending,
      };

      return { ...state, status };
    }
    case `${CompetitionsActions.get}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(competition => competition.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }

    // FETCH
    case `${CompetitionsActions.fetch}_PENDING`: {
      return { ...state };
    }
    case `${CompetitionsActions.fetch}_FULFILLED`: {
      const { payload } = action;
      const status = payload.reduce(
        (result, competition) => ({
          ...result,
          [competition.id]: AsyncStatus.success,
        }),
        {},
      );

      return { list: payload, status };
    }
    case `${CompetitionsActions.fetch}_REJECTED`: {
      return { ...state };
    }

    // FETCH_PAGINATED
    case `${CompetitionsActions.paginate}_PENDING`: {
      return { ...state };
    }
    case `${CompetitionsActions.paginate}_FULFILLED`: {
      const { payload } = action;
      const status = payload.reduce(
        (result, competition) => ({
          ...result,
          [competition.id]: AsyncStatus.success,
        }),
        {},
      );

      const ids = action.payload.map(competition => competition.id);
      const list = [
        ...state.list.filter(competition => !ids.includes(competition.id)),
        ...action.payload,
      ];

      return { list, status };
    }
    case `${CompetitionsActions.paginate}_REJECTED`: {
      return { ...state };
    }

    // UPDATE
    case `${CompetitionsActions.update}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${CompetitionsActions.update}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(competition => competition.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }

    // ROUND_DELETE
    case `${RoundsActions.remove}_PENDING`: {
      const { meta } = action;
      const { competitionId } = meta;

      const status = {
        ...state.status,
        [competitionId]: AsyncStatus.pending,
      };

      return { ...state, status };
    }
    case `${RoundsActions.remove}_FULFILLED`: {
      const { meta } = action;

      const { id: roundId, competitionId } = meta;

      const updatedCompetition = state.list.find(
        competition => competition.id === Number(competitionId),
      );

      const list = state.list
        .filter(competition => competition.id !== competitionId)
        .concat({
          ...updatedCompetition,
          exclusions: updatedCompetition.exclusions.filter(exclusion => exclusion.id !== roundId),
        });

      const status = {
        ...state.status,
        [competitionId]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default CompetitionsReducer;
