import React from "react";
import PropTypes from "prop-types";
import { ModalBody, LinkButton } from "../../atomic";

const ImportTeamsStep1 = ({ onClick }) => (
  <ModalBody>
    {`To get started, you'll need to `}
    <LinkButton
      display="inline-block"
      color="primary"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      download a Team Import Form.
    </LinkButton>
  </ModalBody>
);

ImportTeamsStep1.propTypes = {
  onClick: PropTypes.func,
};

ImportTeamsStep1.defaultProps = {
  onClick: () => {},
};

export default ImportTeamsStep1;
