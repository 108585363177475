import React from "react";
import PropTypes from "prop-types";
import MobileTabs from "./Mobile";
import StandardTabs from "./Standard";

const Tabs = ({ isMobile, ...rest }) =>
  isMobile ? <MobileTabs {...rest} /> : <StandardTabs {...rest} />;

Tabs.propTypes = {
  isMobile: PropTypes.bool,
};

Tabs.defaultProps = {
  isMobile: false,
};

Tabs.displayName = "Tabs";

export default Tabs;
