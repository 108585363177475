import { types as AuthActions } from "../actions/auth";
import { types as SeasonsActions } from "../actions/seasons";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  status: {},
};

const SeasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case `${SeasonsActions.get}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${SeasonsActions.get}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(season => season.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }

    // FETCH
    case `${SeasonsActions.fetch}_PENDING`: {
      return { ...state };
    }
    case `${SeasonsActions.fetch}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, season) => ({ ...result, [season.id]: AsyncStatus.success }),
        {},
      );

      return { list: action.payload, status };
    }
    case `${SeasonsActions.fetch}_REJECTED`: {
      return { ...state };
    }
    // CREATE
    case `${SeasonsActions.create}_FULFILLED`: {
      return { ...state, list: state.list.concat([action.payload]) };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default: {
      return { ...state };
    }
  }
};

export default SeasonsReducer;
