import styled from "styled-components";

const Card = styled.div`
  display: ${props => props.display};
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: ${props => props.theme.radius.element};
  border: 1px solid ${props => props.theme.colors[props.border]};
  margin: 0.25em 0;
  overflow: hidden;
`;

Card.defaultProps = {
  color: "neutral5",
  display: "block",
  border: "transparent",
};

Card.displayName = "Card";

export default Card;
