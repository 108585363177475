import qs from "qs";
import api from ".";

export const get = async (clubId, seasonId, id) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${id}`);
export const fetch = async (clubId, seasonId, params) =>
  api.get(
    `/clubs/${clubId}/seasons/${seasonId}/competitions${qs.stringify(params, {
      addQueryPrefix: true,
    })}`,
  );
export const create = async (clubId, seasonId, competition) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions`, competition);
export const update = async (clubId, seasonId, competition) =>
  api.put(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competition.id}`, competition);

export default {
  get,
  fetch,
  create,
  update,
};
