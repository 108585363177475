import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import AddRoundModalForm from "./AddRoundModalForm";
import { getRoundIDs } from "../../../selectors/rounds";
import RoundTypes from "../../../../shared/constants/RoundTypes";
import { createBatch as createRoundBatch } from "../../../actions/rounds";
import { Modal, ModalHeader } from "../../atomic";

export function AddRoundModal({ toggle, date, ...rest }) {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();
  const roundIDs = useSelector(getRoundIDs);

  const dispatchCreateRound = useCallback(
    async values => {
      const startingRoundOrder = (roundIDs.length || 0) + 1;
      const { number, date: startDate } = values;

      const roundsToCreate = [];
      for (let iteration = 0; iteration < number; iteration += 1) {
        const newOrder = startingRoundOrder + iteration;

        roundsToCreate.push({
          name: `Round ${newOrder}`,
          order: newOrder,
          date: moment(startDate).add(iteration, "weeks"),
          type: RoundTypes.roundRobin,
        });
      }

      await dispatch(createRoundBatch(clubId, seasonId, competitionId, roundsToCreate)).then(
        toggle,
      );
    },
    [clubId, competitionId, dispatch, roundIDs.length, seasonId, toggle],
  );

  return (
    <Modal toggle={toggle} {...rest}>
      <ModalHeader toggle={toggle}>Add New Round</ModalHeader>
      <AddRoundModalForm date={date} onSubmit={dispatchCreateRound} />
    </Modal>
  );
}

AddRoundModal.propTypes = {
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};

AddRoundModal.defaultProps = {
  toggle: () => {},
  onSubmit: () => {},
  date: null,
};

export default AddRoundModal;
