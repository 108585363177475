import { useCallback, useState, useMemo } from "react";

/**
 * Helper hook to toggle between boolean values
 */
function useBoolean(initialValue) {
  const [value, set] = useState(initialValue);

  const toggle = useCallback(() => set(val => !val), [set]);
  const setTrue = useCallback(() => set(true), [set]);
  const setFalse = useCallback(() => set(false), [set]);

  return useMemo(
    () => ({
      value,
      toggle,
      set,
      setTrue,
      setFalse,
    }),
    [value, set, toggle, setTrue, setFalse],
  );
}

export default useBoolean;
