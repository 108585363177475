import React from "react";
import PropTypes from "prop-types";
import SelectRoundsModalForm from "./SelectRoundsModalForm";
import { Modal, ModalHeader } from "../../atomic";

export const SelectRoundsModal = ({ rounds, exclusions, toggle, onSubmit, ...rest }) => {
  const submitAndDismiss = async _rounds => {
    await onSubmit(_rounds);
    toggle(false);
  };

  return (
    <Modal toggle={toggle} {...rest} autoFocus={false}>
      <ModalHeader toggle={toggle}>Select rounds to include</ModalHeader>
      <SelectRoundsModalForm exclusions={exclusions} rounds={rounds} onSubmit={submitAndDismiss} />
    </Modal>
  );
};

SelectRoundsModal.propTypes = {
  division: PropTypes.string,
  teams: PropTypes.array,
  rounds: PropTypes.array,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

SelectRoundsModal.defaultProps = {
  division: null,
  teams: [],
  rounds: [],
  toggle: () => {},
  onSubmit: () => {},
};

export default SelectRoundsModal;
