import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import useResizeObserver from "use-resize-observer/polyfilled";

import ScrollableTabContext from "./ScrollableTabContext";
import SelectionContext from "../../context/SelectionContext";
import useBoolean from "../../hooks/useBoolean";
import useTouchScroll from "../../hooks/useTouchScroll";

const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.neutral3};
  border-bottom: 1px solid ${props => props.theme.colors.neutral3};
  display: flex;
  overflow: hidden;
  transform: scale(1);
`;

const TabsContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

const TabsScrollableContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  ${props => props.hasTransition && `transition: transform 300ms ease-out`}
`;

const ArrowContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.neutral5};
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: pointer;
  z-index: 1;
  pointer-events: ${props => (props.isDisabled ? "none" : "auto")};

  color: ${props => (props.isDisabled ? props.theme.colors.neutral4 : props.theme.colors.neutral2)};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.neutral1};
  }

  &:active {
    color: ${props => props.theme.colors.neutral0};
  }
`;

const StartArrow = styled(ArrowContainer)`
  left: 0;
  box-shadow: 8px 0 16px 8px ${props => props.theme.colors.neutral5};
`;
const EndArrow = styled(ArrowContainer)`
  right: 0;
  box-shadow: -8px 0 16px 8px ${props => props.theme.colors.neutral5};
`;

const NUMBER_OF_TABS = 2;
const OFFSET = 8;

function getTranslateForIndex(containerWidth, tabWidth, index) {
  return containerWidth / 2 - tabWidth / 2 - tabWidth * index;
}

function ScrollableTabs({ children, value, onChange }) {
  const [tabs, setTabs] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [translate, setRawTranslate] = useState(0);
  const [tabWidth, setTabWidth] = useState(0);
  const { value: isScrolling, setTrue: setIsScrolling, setFalse: setIsNotScrolling } = useBoolean(
    false,
  );

  const minTranslate = useMemo(() => getTranslateForIndex(width, tabWidth, 0), [width, tabWidth]);
  const maxTranslate = useMemo(() => getTranslateForIndex(width, tabWidth, tabs.length - 1), [
    width,
    tabWidth,
    tabs,
  ]);

  const setTranslate = useCallback(
    newTranslate => {
      if (newTranslate < maxTranslate) {
        setRawTranslate(maxTranslate);
      } else if (newTranslate > minTranslate) {
        setRawTranslate(minTranslate);
      } else {
        setRawTranslate(newTranslate);
      }
    },
    [setRawTranslate, minTranslate, maxTranslate],
  );

  const scrollToTab = useCallback(
    id => {
      const index = tabs.findIndex(tab => tab.id === id);

      if (index > -1) {
        const translateDestination = getTranslateForIndex(width, tabWidth, index);
        setTranslate(translateDestination);
      }
    },
    [tabs, width, setTranslate, tabWidth],
  );

  const updateWidthOnResize = useMemo(() => ({ width: updatedWidth }) => setWidth(updatedWidth), [
    setWidth,
  ]);

  const { ref: resizeRef } = useResizeObserver({
    onResize: updateWidthOnResize,
  });

  useLayoutEffect(() => {
    const tabCount = tabs.length < NUMBER_OF_TABS ? tabs.length : NUMBER_OF_TABS;
    setTabWidth(width / tabCount - OFFSET);
  }, [width, tabs]);

  const registerTab = useCallback((val, ref) => {
    setTabs(updatedTabs => updatedTabs.concat({ id: val, ref }));
  }, []);
  const deregisterTab = useCallback(val => {
    setTabs(updatedTabs => updatedTabs.filter(tab => tab.id !== val));
  }, []);

  useEffect(() => {
    scrollToTab(value);
  }, [value, scrollToTab]);

  useEffect(() => {
    if (isScrolling) {
      document.body.classList.add("stop-scroll");
    }

    return () => document.body.classList.remove("stop-scroll");
  }, [isScrolling]);

  const goForward = useCallback(() => {
    const index = tabs.findIndex(tab => tab.id === value);

    if (index < tabs.length - 1) {
      onChange(tabs[index + 1].id);
    }
  }, [tabs, value, onChange]);

  const goBack = useCallback(() => {
    const index = tabs.findIndex(tab => tab.id === value);

    if (index > 0) {
      onChange(tabs[index - 1].id);
    }
  }, [tabs, value, onChange]);

  const selectionContextValue = useMemo(
    () => ({
      value,
      select: onChange,
    }),
    [value, onChange],
  );

  const contextValue = useMemo(() => ({ registerTab, deregisterTab, tabWidth }), [
    registerTab,
    deregisterTab,
    tabWidth,
  ]);

  const onTouchScrollDragEnd = useCallback(() => {
    setIsNotScrolling();

    if (translate > 0) {
      onChange(tabs[0].id);
    } else {
      const val = Math.ceil(Math.abs(translate / tabWidth));
      onChange(tabs[val].id);
    }
  }, [setIsNotScrolling, tabWidth, tabs, translate, onChange]);

  const onTouchScrollChange = useCallback(
    val => {
      setTranslate(translate + val.distanceX);
    },
    [translate, setTranslate],
  );

  const handleTouchScroll = useMemo(
    () => ({
      onStart: setIsScrolling,
      onChange: onTouchScrollChange,
      onDragEnd: onTouchScrollDragEnd,
      onEnd: setIsNotScrolling,
    }),
    [onTouchScrollChange, onTouchScrollDragEnd, setIsScrolling, setIsNotScrolling],
  );

  const touchRef = useTouchScroll(handleTouchScroll);

  return (
    <SelectionContext.Provider value={selectionContextValue}>
      <Container ref={touchRef}>
        <ScrollableTabContext.Provider value={contextValue}>
          <StartArrow isDisabled={translate === minTranslate} onClick={goBack}>
            <GoChevronLeft />
          </StartArrow>
          &#8203;
          <TabsContainer style={{ overflow: "hidden" }} ref={resizeRef}>
            <TabsScrollableContainer
              hasTransition={!isScrolling}
              style={{ transform: `translate(${translate}px)` }}
            >
              {children}
            </TabsScrollableContainer>
          </TabsContainer>
          <EndArrow isDisabled={translate === maxTranslate} onClick={goForward}>
            <GoChevronRight />
          </EndArrow>
        </ScrollableTabContext.Provider>
      </Container>
    </SelectionContext.Provider>
  );
}

export default ScrollableTabs;
