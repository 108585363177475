import React, { useCallback, useContext, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Button, LinkButton } from "../atomic";
import ScrollableTabs from "../ScrollableTabs/ScrollableTabs.react";
import ScrollableTabItem from "../ScrollableTabs/ScrollableTabItem.react";
import DivisionFinal from "./DivisionFinal";
import { remove as removeFinal } from "../../actions/finals";
import { download as downloadRounds } from "../../actions/rounds";
import { getClubByID } from "../../selectors/clubs";
import { getCompetitionByID } from "../../selectors/competitions";
import { getFinals } from "../../selectors/finals";
import { getIsClubOwner } from "../../selectors/user";
import RoundTypes from "../../../shared/constants/RoundTypes";
import {
  groupRoundsByMatchNames,
  populateDummyFinalsByDivision,
} from "../../../shared/utils/rounds";
import MobileContext from "../../context/MobileContext";
import { getFinalsTypeFromNumberOfMatches } from "../../../shared/utils/matches";

function FinalsWrapper() {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();

  const club = useSelector(getClubByID(clubId));
  const competition = useSelector(getCompetitionByID(competitionId));
  const finals = useSelector(getFinals);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const finalsByMatchNames = useMemo(() => groupRoundsByMatchNames(finals), [finals]);
  const names = useMemo(() => Object.keys(finalsByMatchNames), [finalsByMatchNames]);

  const populatedFinalsByMatchNames = useMemo(
    () => populateDummyFinalsByDivision(finalsByMatchNames),
    [finalsByMatchNames],
  );

  const deleteFinals = useCallback(async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete your finals? This can't be undone.`,
    );

    if (confirmed) {
      const promises = finals.map(final =>
        dispatch(removeFinal(clubId, seasonId, competitionId, final.id)),
      );

      await Promise.all(promises);
    }
  }, [clubId, competitionId, dispatch, finals, seasonId]);

  const { isMobile } = useContext(MobileContext);

  return (
    <>
      {isAuthenticated && (
        <Row>
          <Col xs={12}>
            <LinkButton
              color="primary"
              padding={0}
              onClick={() =>
                downloadRounds(
                  clubId,
                  seasonId,
                  competitionId,
                  RoundTypes.elimination,
                  `${club.name} - ${competition.name} Finals.xlsx`,
                )
              }
            >
              Click here to download Finals.
            </LinkButton>
          </Col>
        </Row>
      )}
      {isAuthenticated && (
        <Row>
          <Col xs={12}>
            <Button color="error" onClick={deleteFinals}>
              Delete Finals
            </Button>
          </Col>
        </Row>
      )}
      {!isMobile &&
        names.map(division => (
          <Row key={division}>
            <Col xs={12}>
              <DivisionFinal title={division} rounds={populatedFinalsByMatchNames[division]} />
            </Col>
          </Row>
        ))}
      {isMobile && <MobileFinals finals={finals} />}
    </>
  );
}

function MobileFinals({ finals }) {
  const allFinalsByMatchNames = useMemo(() => groupRoundsByMatchNames(finals), [finals]);
  const matchLengths = useMemo(
    () =>
      finals
        .map(final => final.matches.length)
        .filter((length, index, self) => self.indexOf(length) === index)
        .sort((a, b) => b - a),
    [finals],
  );
  const [value, setValue] = useState(matchLengths[matchLengths.length - 1]);

  const applicableFinals = useMemo(() => finals.filter(final => final.matches.length === value), [
    finals,
    value,
  ]);

  const finalsByMatchNames = useMemo(() => groupRoundsByMatchNames(applicableFinals), [
    applicableFinals,
  ]);
  const names = useMemo(() => Object.keys(finalsByMatchNames), [finalsByMatchNames]);

  return (
    <>
      <ScrollableTabs value={value} onChange={setValue}>
        {matchLengths.map(length => (
          <ScrollableTabItem key={length} value={length}>
            {getFinalsTypeFromNumberOfMatches(Number(length))}
          </ScrollableTabItem>
        ))}
      </ScrollableTabs>
      {names.map(division => (
        <Row key={division}>
          <Col xs={12}>
            <DivisionFinal
              isMobile
              title={division}
              rounds={allFinalsByMatchNames[division]}
              visibleRounds={finalsByMatchNames[division]}
            />
          </Col>
        </Row>
      ))}
    </>
  );
}

export default connect()(FinalsWrapper);
