import { types as UserActions } from "../actions/users";
import { types as AuthActions } from "../actions/auth";
import { types as ClubsActions } from "../actions/clubs";

export const initialState = {
  isAuthenticating: true,
  clubs: {},
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${AuthActions.login}_PENDING`:
    case `${UserActions.create}_PENDING`:
    case `${UserActions.getCurrentUser}_PENDING`:
      return { ...state, isAuthenticating: true };
    case `${AuthActions.logout}_FULFILLED`:
      return { isAuthenticating: false, clubs: {} };
    case `${UserActions.create}_FULFILLED`:
    case `${AuthActions.login}_FULFILLED`:
    case `${UserActions.getCurrentUser}_FULFILLED`:
      return { ...action.payload, isAuthenticating: false };
    case `${AuthActions.login}_REJECTED`:
    case `${UserActions.getCurrentUser}_REJECTED`:
      return { ...state, isAuthenticating: false };
    // CREATE
    case `${ClubsActions.create}_FULFILLED`: {
      const clubId = action.payload.id;
      const clubs = { ...state.clubs, [clubId]: true };

      return { ...state, clubs };
    }
    default:
      return { ...state };
  }
};

export default UserReducer;
