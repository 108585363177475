import { types as ModalActions } from "../actions/modals";
import Modals from "../constants/Modals";

export const initialState = Object.values(Modals).reduce(
  (result, modal) => ({ ...result, [modal]: false }),
  {},
);

const ModalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActions.toggleModal: {
      const { payload } = action;
      return { ...state, [payload]: !state[payload] };
    }
    default: {
      return { ...state };
    }
  }
};

export default ModalsReducer;
