import styled from "styled-components";

const InputGroupWrapper = styled.div`
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
`;

export default InputGroupWrapper;
