import React, { useCallback } from "react";
import PropTypes from "prop-types";
import MatchTeam from "./MatchTeam";
import Score from "./Score";
import { getClashesForTeam } from "../../../shared/utils/matches";
import AsyncStatus from "../../constants/AsyncStatus";
import MatchTeamStatus from "../../../shared/constants/MatchTeamStatus";
import { EditableText, Row, Col, Text, Flex } from "../atomic";

function Match({ match, status, matches, teams, updateMatch, isMobile }) {
  const isEditable = updateMatch != null;
  const updateHomeTeam = useCallback(
    homeTeam =>
      updateMatch(
        homeTeam != null
          ? {
              ...match,
              homeTeam,
              homeTeamId: homeTeam.id,
            }
          : {
              ...match,
              homeTeam: null,
              homeTeamId: null,
              homeScore: null,
              homeTeamStatus: null,
            },
      ),
    [updateMatch, match],
  );
  const updateHomeTeamScore = useCallback(
    async homeScore => updateMatch({ ...match, homeScore, homeTeamStatus: null }),
    [updateMatch, match],
  );

  const updateAwayTeam = useCallback(
    awayTeam => {
      updateMatch(
        awayTeam != null
          ? {
              ...match,
              awayTeam,
              awayTeamId: awayTeam.id,
            }
          : {
              ...match,
              awayTeam: null,
              awayTeamId: null,
              awayScore: null,
              awayTeamStatus: null,
            },
      );
    },
    [updateMatch, match],
  );

  const updateAwayScore = useCallback(
    async awayScore => updateMatch({ ...match, awayScore, awayTeamStatus: null }),
    [updateMatch, match],
  );
  const updateField = useCallback(
    async field => {
      updateMatch({ ...match, field });
    },
    [updateMatch, match],
  );

  return (
    <Row>
      <Col xs={12} sm={2}>
        <EditableText
          isDisabled={!isEditable}
          bold
          value={match.field}
          onChange={updateField}
          color={isMobile ? "primary" : undefined}
        >
          {match.field}
        </EditableText>
      </Col>
      <Col xs={12} sm={4}>
        <Row>
          <Col xs={10}>
            <MatchTeam
              team={match.homeTeam}
              teams={teams}
              status={status}
              match={match}
              clashes={getClashesForTeam(match.homeTeam, matches)}
              onTeamUpdate={isEditable ? updateHomeTeam : undefined}
              position={isMobile ? "right" : "left"}
            />
          </Col>
          <Col xs={2}>
            {match.homeTeam && (
              <Flex justifyContent="flex-end">
                <Score
                  score={match.homeScore}
                  status={match.homeTeamStatus}
                  onSubmit={isEditable ? updateHomeTeamScore : undefined}
                  onForfeit={async () =>
                    updateMatch({
                      ...match,
                      homeScore: null,
                      homeTeamStatus: MatchTeamStatus.forfeit,
                    })
                  }
                />
              </Flex>
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={false} sm={1}>
        <Text align="center" display="block">
          vs
        </Text>
      </Col>
      <Col xs={12} sm={4}>
        <Row>
          <Col xs={isMobile ? false : 2}>
            {match.awayTeam && (
              <Flex justifyContent="flex-start">
                <Score
                  score={match.awayScore}
                  status={match.awayTeamStatus}
                  onSubmit={isEditable ? updateAwayScore : undefined}
                  onForfeit={async () =>
                    updateMatch({
                      ...match,
                      awayScore: null,
                      awayTeamStatus: MatchTeamStatus.forfeit,
                    })
                  }
                />
              </Flex>
            )}
          </Col>
          <Col xs={10}>
            <MatchTeam
              team={match.awayTeam}
              teams={teams}
              status={status}
              match={match}
              clashes={getClashesForTeam(match.awayTeam, matches)}
              onTeamUpdate={isEditable ? updateAwayTeam : undefined}
              position="right"
            />
          </Col>
          <Col xs={isMobile ? 2 : false}>
            {match.awayTeam && (
              <Flex justifyContent="flex-end">
                <Score
                  score={match.awayScore}
                  status={match.awayTeamStatus}
                  onSubmit={isEditable ? updateAwayScore : undefined}
                  onForfeit={async () =>
                    updateMatch({
                      ...match,
                      awayScore: null,
                      awayTeamStatus: MatchTeamStatus.forfeit,
                    })
                  }
                />
              </Flex>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Match.propTypes = {
  status: PropTypes.string,
  match: PropTypes.object.isRequired,
  matches: PropTypes.array,
  teams: PropTypes.array,
  updateMatch: PropTypes.func,
};

Match.defaultProps = {
  status: AsyncStatus.success,
  matches: [],
  teams: [],
  updateMatch: null,
};

export default Match;
