import React, { useCallback, useEffect, useMemo, useRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, LinkButton } from "../atomic";
import useBoolean from "../../hooks/useBoolean";

function FinalInlineDate({
  isDisabled,
  date: rawDate,
  displayFormat = "L, h:mm A",
  dateFormat = "dd/MM/yyyy, hh:mm aa",
  onChange,
  showTimeSelectOnly = false,
  timeIntervals
}) {
  const date = new Date(rawDate);
  const startDate = new Date();
  const datePickerRef = useRef();
  const { value: isActive, toggle } = useBoolean(false);
  const dateText = useMemo(() => moment(date).format(displayFormat), [
    date,
    displayFormat
  ]);

  useEffect(() => {
    if (isActive && datePickerRef.current != null) {
      datePickerRef.current.input.focus();
    }
  }, [isActive, datePickerRef]);

  const safeOnChange = useCallback(
    val => (val != null ? onChange(val) : onChange(date)),
    [onChange, date]
  );

  return !isActive ? (
    <LinkButton
      color="primary"
      isDisabled={isDisabled}
      underline="dotted"
      onClick={toggle}
    >
      {dateText}
    </LinkButton>
  ) : (
    <DatePicker
      id="date"
      name="date"
      customInput={<Input />}
      autoComplete="off"
      disabledKeyboardNavigation
      onChange={safeOnChange}
      onCalendarClose={toggle}
      selected={date}
      showTimeSelect
      showTimeSelectOnly={showTimeSelectOnly}
      placeholderText={startDate.toLocaleDateString()}
      shouldCloseOnSelect
      dateFormat={dateFormat}
      ref={datePickerRef}
      todayButton="Today"
      timeIntervals={timeIntervals}
    />
  );
}

export default FinalInlineDate;
