import React from "react";
import styled from "styled-components";
import Card from "./Card";
import CardBody from "./CardBody";

const TabContentContainer = styled(Card)`
  margin-top: 0;

  ${props => props.activeTab === 1 && `border-top-left-radius: 0;`}
`;

const TabContent = ({ children, ...rest }) => (
  <TabContentContainer {...rest}>
    <CardBody>{children}</CardBody>
  </TabContentContainer>
);

export default TabContent;
