const colors = {
  primary: "#F56900",
  secondary: "#196e64",
  muted: "#64b49b",
  error: "#dc3545",
  warning: "#fff3cd",
  success: "#28a745",
  neutral5: "#ffffff",
  neutral4: "#f2f2f2",
  neutral3: "#bfbfbf",
  neutral2: "#969696",
  neutral1: "#333333",
  neutral0: "#000000",
  transparent: "transparent",
  inherit: "inherit",
};

const radius = {
  element: "0.75rem",
  input: "0.5rem",
  small: "0.25rem",
};

const breakpoints = {
  lg: "75em",
  md: "64em",
  sm: "48em",
  xs: "0em",
};

export default { colors, radius, breakpoints };
