import api, { assets } from ".";

export const get = async (clubId, seasonId, competitionId, id) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds/${id}`);
export const fetch = async (clubId, seasonId, competitionId, params = {}) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds`, params);
export const create = async (clubId, seasonId, competitionId, round) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds`, round);
export const createBatch = async (clubId, seasonId, competitionId, rounds) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds/batch`, {
    rounds,
  });
export const update = async (clubId, seasonId, competitionId, round) =>
  api.put(
    `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds/${round.id}`,
    round,
  );

export const remove = async (clubId, seasonId, competitionId, id) =>
  api.delete(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds/${id}`);

export const download = async (clubId, seasonId, id, params) =>
  assets.get(`clubs/${clubId}/seasons/${seasonId}/competitions/${id}/rounds`, {
    params,
    responseType: "blob",
  });

export default {
  get,
  fetch,
  create,
  createBatch,
  remove,
  download,
  update,
};
