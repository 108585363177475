import { assets } from ".";

export const download = async (clubId, seasonId, id) =>
  assets.get(`clubs/${clubId}/seasons/${seasonId}/competitions/${id}/ladders`, {
    responseType: "blob",
  });

export default {
  download,
};
