import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../../Buttons";
import { Row, Col, ModalBody, Input, Label, Select } from "../../atomic";

const AddCustomDivisionModalForm = ({
  values,
  isValid,
  touched,
  errors,
  ladders,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
}) => {
  const divisions = Object.keys(ladders);

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(e);
      }}
    >
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Division 1B"
              autoFocus
              invalid={touched.name && !!errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              values={values.name}
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label for="division">Based on Division</Label>
            <Select
              id="division"
              name="division"
              onChange={e => {
                handleChange(e);
                setFieldValue("teams", ladders[e.target.value]);
              }}
              onBlur={handleBlur}
            >
              {divisions.map(division => (
                <option key={division} value={division}>
                  {division}
                </option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Add Final
            </SubmitButton>
          </Col>
        </Row>
      </ModalBody>
    </form>
  );
};

AddCustomDivisionModalForm.propTypes = {
  ladders: PropTypes.object,
  values: PropTypes.object,
  isValid: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AddCustomDivisionModalForm.defaultProps = {
  ladders: {},
  values: {},
  isValid: true,
  touched: {},
  errors: {},
  handleSubmit: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: ({ ladders: _ladders, limit = 4 }) => {
    const divisions = Object.keys(_ladders);
    const ladders = divisions.reduce((aggregate, division) => {
      const teams = _ladders[division].map((team, index) => ({
        ...team,
        selected: index < limit,
      }));

      return { ...aggregate, [division]: teams };
    }, {});

    return {
      name: "",
      division: divisions[0],
      teams: ladders[divisions[0]],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required(),
    division: yup.string().required(),
    teams: yup.array().required(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;

    try {
      if (onSubmit) await onSubmit(values);
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(AddCustomDivisionModalForm);
