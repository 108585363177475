import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";
import { renderRoutes } from "react-router-config";

import configureStore, { history } from "./store";
import Theme from "./components/atomic/theme";
import routes from "./routes";
import GlobalStyle from "./components/atomic/GlobalStyle";

const store = configureStore();

const App = () => (
  <ThemeProvider theme={Theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <>
          <GlobalStyle />
          {renderRoutes(routes)}
        </>
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>
);

export default App;
