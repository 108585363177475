import orderBy from "lodash.orderby";
import { types as AuthActions } from "../actions/auth";
import { types as FinalsActions } from "../actions/finals";
import { types as MatchesActions } from "../actions/matches";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  status: {},
};

const FinalsReducer = (state = initialState, action) => {
  switch (action.type) {
    // UPDATE ROUNDS
    case `${FinalsActions.update}_PENDING`: {
      const { meta } = action;
      const status = {
        ...state.status,
        [meta.id]: AsyncStatus.pending,
      };

      return { ...state, status };
    }
    case `${FinalsActions.update}_FULFILLED`: {
      const { payload } = action;
      const existingIndex = state.list.findIndex(round => round.id === payload.id);

      if (existingIndex < 0) {
        return { ...state };
      }

      const list = orderBy(
        [...state.list.slice(0, existingIndex), payload, ...state.list.slice(existingIndex + 1)],
        "date",
      );

      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      const ids = list.map(item => item.id);

      return { ...state, list, ids, status, fetching: false };
    }

    // UPDATE
    case `${MatchesActions.update}_PENDING`: {
      const { meta } = action;
      const status = {
        ...state.status,
        [meta.roundId]: AsyncStatus.pending,
      };

      return { ...state, status };
    }
    case `${MatchesActions.update}_FULFILLED`: {
      const { payload } = action;
      const existingFinal = state.list.find(final => final.id === payload.roundId);

      if (existingFinal == null) {
        return { ...state };
      }

      const matches = existingFinal.matches
        .filter(match => match.id !== payload.id)
        .concat(payload);

      const updatedFinal = {
        ...existingFinal,
        matches: orderBy(matches, "time", "asc"),
      };

      const list = orderBy(
        [...state.list.filter(filterFinal => filterFinal.id !== payload.roundId), updatedFinal],
        "date",
      );

      const status = {
        ...state.status,
        [payload.roundId]: AsyncStatus.success,
      };

      return { ...state, list, status, fetching: false };
    }

    // DELETE
    case `${FinalsActions.remove}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${FinalsActions.remove}_FULFILLED`: {
      const { meta } = action;
      const list = orderBy(
        state.list.filter(final => final.id !== meta.id),
        "date",
      );

      const status = {
        ...state.status,
        [meta.id]: AsyncStatus.success,
      };

      return { ...state, list, status, fetching: false };
    }

    // FETCH
    case `${FinalsActions.fetch}_PENDING`: {
      return { ...state, fetching: true };
    }
    case `${FinalsActions.fetch}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, final) => ({
          ...result,
          [final.id]: AsyncStatus.success,
        }),
        {},
      );

      const list = orderBy(action.payload, "date");

      return { list, status, fetching: false };
    }
    case `${FinalsActions.fetch}_REJECTED`: {
      return { ...state, fetching: false };
    }

    // CREATE
    case `${FinalsActions.create}_PENDING`: {
      const status = { ...state.status, 0: AsyncStatus.pending };
      return { ...state, status };
    }

    case `${FinalsActions.create}_FULFILLED`: {
      const { payload } = action;
      const list = orderBy(state.list.concat(payload), "date");
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
        0: AsyncStatus.success,
      };

      return { ...state, list, status, fetching: false };
    }

    // CREATE BATCH
    case `${FinalsActions.createBatch}_FULFILLED`: {
      const { payload } = action;
      const { rounds } = payload;
      const list = orderBy(rounds.concat(state.list), "date");
      const status = { [payload.id]: AsyncStatus.success, ...state.status };

      const ids = list.map(item => item.id);

      return { ...state, list, ids, status, fetching: false };
    }

    // SET
    case FinalsActions.set: {
      const status = action.payload.reduce(
        (result, round) => ({
          ...result,
          [round.id]: AsyncStatus.success,
        }),
        {},
      );

      return { list: orderBy(action.payload, "date"), status, fetching: false };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default FinalsReducer;
