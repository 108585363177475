import styled from "styled-components";

const Text = styled.span`
  font-size: ${props => props.size};
  color: ${props => props.theme.colors[props.color]};
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  text-align: ${props => props.align};
  justify-content: ${props => props.align};
  width: ${props => props.width};
  display: ${props => props.display};
`;

Text.defaultProps = {
  color: "neutral1",
  align: "inherit",
  display: "inline-block",
  size: "1em",
  underline: false,
  width: "initial",
};

export default Text;
