import { createAction } from "redux-actions";
import fileDownload from "js-file-download";
import RoundsAPI from "../api/rounds";

export const types = {
  get: "ROUND_GET",
  fetch: "ROUNDS_FETCH",
  createBatch: "ROUNDS_CREATE_BATCH",
  remove: "ROUND_REMOVE",
  set: "ROUND_SET",
  update: "ROUND_UPDATE",
};

export const get = createAction(types.get, RoundsAPI.get, id => ({ id }));
export const fetch = createAction(types.fetch, RoundsAPI.fetch);
export const set = createAction(types.set);
export const createBatch = createAction(types.createBatch, RoundsAPI.createBatch);
export const update = createAction(
  types.update,
  RoundsAPI.update,
  (clubId, seasonId, competitionId, round) => ({ id: round.id }),
);
export const remove = createAction(
  types.remove,
  RoundsAPI.remove,
  (clubId, seasonId, competitionId, id) => ({
    id,
    competitionId,
  }),
);

export const download = async (clubId, seasonId, id, type, fileName) => {
  const data = await RoundsAPI.download(clubId, seasonId, id, { type });
  fileDownload(data.data, fileName);
};
