import { combineReducers } from "redux";
import { types as UserActions } from "../actions/users";
import { types as ClubsActions } from "../actions/clubs";
import { types as SeasonsActions } from "../actions/seasons";
import { types as CompetitionsActions } from "../actions/competitions";
import { types as RoundsActions } from "../actions/rounds";
import { types as TeamsActions } from "../actions/teams";
import AsyncStatus from "../constants/AsyncStatus";

const generateStatusReducer = (types = []) => (state, action) => {
  const values = types.reduce(
    (aggregate, type) => ({
      ...aggregate,
      [type]: { fetching: AsyncStatus.unfetched },
      [`${type}_PENDING`]: { fetching: AsyncStatus.pending },
      [`${type}_FULFILLED`]: { fetching: AsyncStatus.success },
      [`${type}_REJECTED`]: { fetching: AsyncStatus.failed },
    }),
    {},
  );

  return values[action.type] || { fetching: AsyncStatus.unfetched, ...state };
};

const clubs = generateStatusReducer([ClubsActions.fetch, ClubsActions.get]);
const seasons = generateStatusReducer([SeasonsActions.fetch, SeasonsActions.get]);
const competitions = generateStatusReducer([CompetitionsActions.fetch, CompetitionsActions.get]);
const rounds = generateStatusReducer([RoundsActions.fetch, RoundsActions.get, RoundsActions.set]);
const teams = generateStatusReducer([TeamsActions.fetch, TeamsActions.get, TeamsActions.set]);
const clubTeams = generateStatusReducer([TeamsActions.fetchForClub]);
const users = generateStatusReducer([UserActions.getCurrentUser]);

export default combineReducers({
  clubs,
  seasons,
  competitions,
  rounds,
  teams,
  clubTeams,
  users,
});
