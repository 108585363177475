import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { SubmitButton } from "./Buttons";
import { Row, Col, Label, Input } from "./atomic";

export const ForgotFormPassword = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <Label for="password">New Password</Label>
        <Input
          type="password"
          name="password"
          id="password"
          invalid={touched.password && !!errors.password}
          required
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Label for="confirm-password">Confirm Password</Label>
        <Input
          type="password"
          name="confirmPassword"
          id="confirm-password"
          invalid={touched.confirmPassword && !!errors.confirmPassword}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
      Update my password
    </SubmitButton>
  </form>
);

ForgotFormPassword.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ForgotFormPassword.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  isValid: true,
  handleSubmit: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: () => ({
    password: "",
    confirmPassword: "",
  }),
  validationSchema: yup.object().shape({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Passwords do not match"),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;
    const { password } = values;

    try {
      if (onSubmit) await onSubmit({ password });
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(ForgotFormPassword);
