import "normalize.css";
import "react-datepicker/dist/react-datepicker.css";
import "core-js/fn/string/ends-with";
import "core-js/fn/string/starts-with";
import "core-js/fn/object/values";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { unregister } from "./client/registerServiceWorker";
import App from "./client/App";
import "./client/styles/overrides";

import "./client/localization";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://85d69e8ab4504f6f8b44baa5cbe63ca8@sentry.io/1784882",
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
