import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ListItem } from "../atomic";

const InteractiveListGroupItem = styled(ListItem)`
  cursor: pointer;
`;
// eslint-disable-next-line
class AutocompleteItem extends Component {
  static propTypes = {
    isHighlighted: PropTypes.bool,
  };

  static defaultProps = {
    isHighlighted: false,
  };

  render() {
    const { isHighlighted, ...rest } = this.props;
    return (
      <InteractiveListGroupItem
        active={isHighlighted}
        color={isHighlighted ? "neutral4" : "neutral5"}
        border="neutral4"
        hover="neutral4"
        {...rest}
      />
    );
  }
}

export default AutocompleteItem;
