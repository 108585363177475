import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../../Buttons";
import { Row, Col, ModalBody, Input, Label } from "../../atomic";

const AddClubModalForm = ({
  values,
  isValid,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <ModalBody>
      <Row>
        <Col xs={12}>
          <Label htmlFor="club-name">Club Name</Label>
          <Input
            type="text"
            name="name"
            id="club-name"
            placeholder="Fairfield City Oztag"
            autoFocus
            invalid={touched.name && !!errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values.name}
            disabled={isSubmitting}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
            Add Club
          </SubmitButton>
        </Col>
      </Row>
    </ModalBody>
  </form>
);

AddClubModalForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AddClubModalForm.defaultProps = {
  values: {},
  isValid: true,
  touched: {},
  errors: {},
  handleSubmit: () => {},
  handleChange: () => {},
  handleBlur: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: () => ({ name: "" }),
  validationSchema: yup.object().shape({
    name: yup.string().required(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;

    try {
      if (onSubmit) await onSubmit(values);
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(AddClubModalForm);
