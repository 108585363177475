import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withFormik } from "formik";
import * as yup from "yup";
import { GoCheck } from "react-icons/go";
import { Input, Button, Portal, Row, Col, getAbsolutePosition } from "../atomic";

const FormContainer = styled.div`
  position: absolute;
  left: calc(${props => props.location.left}px - 0.75em);
  top: calc(${props => props.location.top}px - 0.75em);
  z-index: 5;
`;

class PointAdjustmentForm extends Component {
  static propTypes = {
    id: PropTypes.string,
    defaultPoints: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
    values: PropTypes.object,
    setSubmitting: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    onReset: PropTypes.func,
    handleBlur: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    triggerRef: PropTypes.object,
  };

  static defaultProps = {
    id: undefined,
    values: {},
    setSubmitting: () => {},
    handleSubmit: () => {},
    handleChange: () => {},
    handleBlur: () => {},
    onReset: () => {},
    touched: {},
    errors: {},
    isSubmitting: false,
    isValid: false,
    triggerRef: null,
  };

  inputRef = React.createRef();

  state = {
    location: getAbsolutePosition(this.props.triggerRef.current),
  };

  componentDidMount() {
    const { values } = this.props;
    const { current: input } = this.inputRef;

    const { points } = values;

    input.focus();

    if (points !== null) {
      input.setSelectionRange(0, points.toString().length);
    }
  }

  render() {
    const {
      id,
      values,
      handleSubmit,
      handleChange,
      handleBlur,
      touched,
      errors,
      setSubmitting,
      isSubmitting,
      isValid,
      points: initialPoints,
      defaultPoints,
      onReset,
    } = this.props;

    const { location } = this.state;

    return (
      <Portal>
        <FormContainer id={id} location={location}>
          <form onSubmit={handleSubmit}>
            <Row alignItems="center">
              <Col xs={12} sm={3}>
                <Input
                  id="points"
                  name="points"
                  align="center"
                  placeholder={defaultPoints}
                  value={values.points}
                  invalid={touched.points && !!errors.points}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  ref={this.inputRef}
                />
              </Col>
              <Col xs={12} sm={3}>
                <Button type="submit" color="success" disabled={!isValid || isSubmitting}>
                  <GoCheck />
                </Button>
              </Col>
              {defaultPoints !== initialPoints && (
                <Col xs={12} sm={6}>
                  <Button
                    color="error"
                    width="initial"
                    onClick={e => {
                      e.preventDefault();
                      setSubmitting(true);
                      onReset(() => setSubmitting(false));
                    }}
                    disabled={isSubmitting}
                    display="block"
                  >
                    Reset
                  </Button>
                </Col>
              )}
            </Row>
          </form>
        </FormContainer>
      </Portal>
    );
  }
}

const mapPropsToValues = ({ points }) => {
  const defaults = {
    points: null,
  };

  return { ...defaults, points };
};

const validationSchema = yup.object().shape({
  points: yup.number().required(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const { defaultPoints, onSubmit } = props;
    const { points: newPoints } = values;

    const adjustment = Number(newPoints) - Number(defaultPoints);

    if (onSubmit) await onSubmit(adjustment);
  },
})(PointAdjustmentForm);
