import styled from "styled-components";

const CardHeader = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  padding: 1em 1em;
  border-bottom: 1px solid ${props => props.theme.colors[props.border]};
`;

CardHeader.displayName = "CardHeader";
CardHeader.defaultProps = {
  color: "neutral4",
  border: "transparent",
};
export default CardHeader;
