import { createAction } from "redux-actions";
import SeasonsAPI from "../api/seasons";

export const types = {
  get: "SEASON_GET",
  fetch: "SEASONS_FETCH",
  create: "SEASONS_CREATE",
};

export const get = createAction(types.get, SeasonsAPI.get, (_clubId, id) => ({
  id,
}));
export const fetch = createAction(types.fetch, SeasonsAPI.fetch);
export const create = createAction(types.create, SeasonsAPI.create);
