import styled from "styled-components";
import { Row as _Row } from "react-styled-flexboxgrid";

const Row = styled(_Row)`
  align-items: ${props => props.alignItems};

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

Row.defaultProps = {
  alignItems: "initial",
};

export default Row;
