import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import user from "./user";
import clubs from "./clubs";
import seasons from "./seasons";
import competitions from "./competitions";
import teams from "./teams";
import clubTeams from "./club-teams";
import rounds from "./rounds";
import finals from "./finals";
import modals from "./modals";
import forms from "./forms";
import status from "./status";

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    clubs,
    clubTeams,
    seasons,
    competitions,
    teams,
    rounds,
    finals,
    forms,
    modals,
    status,
  });
