import { useContext, useMemo, useCallback } from "react";
import SelectionContext from "../context/SelectionContext";

/**
 * Helper hook to toggle between boolean values
 */
function useSelection(value) {
  const { value: selectedValue, select: selectValue } = useContext(SelectionContext);
  const select = useCallback(() => selectValue(value), [value, selectValue]);

  return useMemo(
    () => ({
      isSelected: value === selectedValue,
      select,
    }),
    [value, select, selectedValue],
  );
}

export default useSelection;
