import { createAction } from "redux-actions";
import CompetitionsAPI from "../api/competitions";

export const types = {
  get: "COMPETITION_GET",
  fetch: "COMPETITIONS_FETCH",
  paginate: "COMPETITIONS_PAGINATE",
  create: "COMPETITIONS_CREATE",
  update: "COMPETITIONS_UPDATE",
};

export const get = createAction(types.get, CompetitionsAPI.get, (_clubId, _seasonId, id) => ({
  id,
}));
export const fetch = createAction(types.fetch, CompetitionsAPI.fetch);
export const paginate = createAction(types.paginate, CompetitionsAPI.fetch);
export const create = createAction(types.create, CompetitionsAPI.create);
export const update = createAction(
  types.update,
  CompetitionsAPI.update,
  (clubId, seasonId, competition) => ({ id: competition.id }),
);
