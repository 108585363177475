import styled from "styled-components";
import TableBody from "./TableBody";

const TableHeader = styled(TableBody)`
  width: 100%;
`;

TableHeader.defaultProps = {
  as: "thead",
};

export default TableHeader;
