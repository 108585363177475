import React, { useCallback, useContext, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  DisabledParagraph,
  Paragraph,
  LinkButton,
  Text
} from "../components/atomic";
import ScrollableTabs from "../components/ScrollableTabs/ScrollableTabs.react";
import ScrollableTabItem from "../components/ScrollableTabs/ScrollableTabItem.react";
import { update as updateTeam } from "../actions/teams";
import { update as updateCompetition } from "../actions/competitions";
import { download as downloadLadder } from "../actions/ladders";
import { getClubByID } from "../selectors/clubs";
import { getCompetitionByID } from "../selectors/competitions";
import { getRounds } from "../selectors/rounds";
import { getDivisions } from "../selectors/teams";
import { getIsClubOwner } from "../selectors/user";
import Ladder from "../components/Ladders/Ladder";
import MobileContext from "../context/MobileContext";
import SelectRoundsModal from "../components/modals/select-rounds/SelectRoundsModal";
import { calculateLadders } from "../../shared/utils/ladder";
import useBoolean from "../hooks/useBoolean";

function Ladders() {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();

  const club = useSelector(getClubByID(clubId));
  const competition = useSelector(getCompetitionByID(competitionId));
  const rounds = useSelector(getRounds);
  const teamsByDivision = useSelector(getDivisions);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const submitPointAdjustment = useCallback(
    async team => dispatch(updateTeam(clubId, seasonId, competitionId, team)),
    [dispatch, clubId, seasonId, competitionId]
  );

  const { exclusions = [] } = competition;

  const [ladders, divisions] = useMemo(() => {
    const ladders = calculateLadders(teamsByDivision, rounds, exclusions);
    const divisions = Object.keys(ladders).sort();

    return [ladders, divisions];
  }, [teamsByDivision, rounds, exclusions]);

  const hasRoundsAndLadders = rounds.length > 0 && divisions.length > 0;
  const showFullLadder = !useContext(MobileContext).isMobile;

  return (
    <>
      <Row>
        {rounds.length === 0 && (
          <Col xs={12}>
            <DisabledParagraph>
              {`There are no ladders because no rounds have been played yet in ${competition.name}`}
            </DisabledParagraph>
          </Col>
        )}
        {hasRoundsAndLadders && isAuthenticated && (
          <Col xs={12}>
            <LadderInteractions
              club={club}
              competition={competition}
              rounds={rounds}
            />
          </Col>
        )}
      </Row>
      {!hasRoundsAndLadders ? null : showFullLadder ? (
        divisions.map(division => (
          <Row key={division}>
            <Col xs={12}>
              <Ladder
                title={division}
                ladder={ladders[division]}
                onAdjustmentSubmit={submitPointAdjustment}
              />
            </Col>
          </Row>
        ))
      ) : (
        <MobileLadders
          divisions={divisions}
          isAuthenticated={isAuthenticated}
          ladders={ladders}
          onAdjustmentSubmit={submitPointAdjustment}
        />
      )}
    </>
  );
}

function MobileLadders({
  divisions,
  isAuthenticated,
  onAdjustmentSubmit,
  ladders
}) {
  const [value, setValue] = useState(divisions[0]);

  return (
    <>
      <ScrollableTabs value={value} onChange={setValue}>
        {divisions.map(division => (
          <ScrollableTabItem key={division} value={division}>
            {division}
          </ScrollableTabItem>
        ))}
      </ScrollableTabs>
      <Row>
        <Col xs={12}>
          <Ladder
            title={value}
            ladder={ladders[value]}
            onAdjustmentSubmit={onAdjustmentSubmit}
          />
        </Col>
      </Row>
    </>
  );
}

function getLadderText(roundsLength, exclusionsLength) {
  const includedRoundsLength = roundsLength - exclusionsLength;
  if (exclusionsLength === 0) {
    return `${includedRoundsLength} rounds.`;
  }

  if (exclusionsLength === 1) {
    return `${includedRoundsLength} rounds and excludes 1 round.`;
  }

  if (exclusionsLength > 1) {
    return `${includedRoundsLength} rounds and excludes ${exclusionsLength} rounds.`;
  }

  return null;
}

function LadderInteractions({ club, competition, rounds }) {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();
  const { value: isModalShown, toggle: toggleModal } = useBoolean(false);

  const submitExclusions = useCallback(
    async exclusions => {
      const mappedExclusions = exclusions.map(round => ({ id: round.id }));

      return dispatch(
        updateCompetition(clubId, seasonId, {
          ...competition,
          exclusions: mappedExclusions
        })
      );
    },
    [dispatch, competition, clubId, seasonId]
  );

  const { exclusions } = competition;

  return (
    <Paragraph>
      <Text>This ladder is based on</Text>{" "}
      <LinkButton onClick={toggleModal} color="primary" bold padding={0}>
        {getLadderText(rounds.length, exclusions.length)}
      </LinkButton>{" "}
      <LinkButton
        color="primary"
        padding={0}
        onClick={() =>
          downloadLadder(
            clubId,
            seasonId,
            competitionId,
            `${club.name} - ${competition.name} Ladders.xlsx`
          )
        }
      >
        Click here to download it.
      </LinkButton>
      <SelectRoundsModal
        id="select-rounds-modal"
        rounds={rounds}
        exclusions={exclusions}
        isOpen={isModalShown}
        toggle={toggleModal}
        onSubmit={submitExclusions}
      />
    </Paragraph>
  );
}

export default connect()(Ladders);
