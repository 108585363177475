import { createAction } from "redux-actions";
import usersAPI from "../api/users";

export const types = {
  getCurrentUser: "USER_GET_CURRENT",
  create: "USER_CREATE",
};

export const getCurrentUser = createAction(types.getCurrentUser, usersAPI.getCurrentUser);
export const create = createAction(types.create, usersAPI.create);
