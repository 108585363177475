import React, { useCallback, useEffect, useState } from "react";
import mergeRefs from "react-merge-refs";
import useBoolean from "../../../hooks/useBoolean";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useOnEscape from "../../../hooks/useOnEscape";
import useGetElementRect from "../../../hooks/useGetElementRect";
import { Input, LinkButton } from "../index";

function EditableText({
  align,
  isDisabled,
  shouldMaintainWidth = false,
  children,
  value,
  onChange,
  ...rest
}) {
  const [textValue, setTextValue] = useState(value);
  const { value: isEditing, toggle } = useBoolean(false);
  const [rectRef, rect] = useGetElementRect();

  const submitAndToggle = useCallback(() => {
    if (textValue != null && textValue.trim() !== "") {
      onChange(textValue);
    }
    toggle();
  }, [textValue, onChange, toggle]);

  const reset = useCallback(() => {
    setTextValue(value);
    toggle();
  }, [value, toggle, setTextValue]);

  const clickOutsideRef = useOnClickOutside(submitAndToggle);
  const onEscapeRef = useOnEscape(reset);
  const submit = useCallback(e => e.keyCode === 13 && submitAndToggle(), [
    submitAndToggle
  ]);

  const refs = mergeRefs([clickOutsideRef, onEscapeRef]);

  useEffect(() => {
    if (isEditing) {
      setTextValue(value);
    }
  }, [isEditing, setTextValue, value]);

  return isEditing ? (
    <Input
      align={align}
      autoFocus
      display="inline-block"
      type="text"
      value={textValue}
      onChange={e => setTextValue(e.target.value)}
      ref={refs}
      onKeyDown={submit}
      style={
        shouldMaintainWidth
          ? { width: `${rect.width}px`, boxSizing: "content-box" }
          : null
      }
    />
  ) : (
    <LinkButton
      {...rest}
      isDisabled={isDisabled}
      underline="dotted"
      onClick={e => {
        e.stopPropagation();
        toggle();
      }}
      ref={shouldMaintainWidth ? rectRef : null}
    >
      {children}
    </LinkButton>
  );
}

export default EditableText;
