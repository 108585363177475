import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import orderBy from "lodash.orderby";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { download as downloadRounds } from "../actions/rounds";
import { getClubByID } from "../selectors/clubs";
import { getCompetitionByID } from "../selectors/competitions";
import { getRounds, getRoundIDs, getRoundByID } from "../selectors/rounds";
import AddRoundModal from "../components/modals/add-round/AddRoundModal";
import Round from "../components/Rounds/Round";
import MobileRound from "../components/Rounds/MobileRound.react";
import MobileContext from "../context/MobileContext";
import { getEditCompetitionLink } from "../routes";
import { hasEnoughFieldsAndTimeslots } from "../../shared/utils/competitions";
import {
  Button,
  DisabledParagraph,
  Card,
  CardBody,
  Link as LinkText,
  Row,
  Col,
  LinkButton
} from "../components/atomic";
import ScrollableTabs from "../components/ScrollableTabs/ScrollableTabs.react";
import ScrollableTabItem from "../components/ScrollableTabs/ScrollableTabItem.react";
import RoundTypes from "../../shared/constants/RoundTypes";
import { getIsClubOwner } from "../selectors/user";
import { getTeams } from "../selectors/teams";
import useBoolean from "../hooks/useBoolean";

function getNextRoundDate(latestDate) {
  return latestDate != null
    ? moment(latestDate)
        .add(1, "week")
        .toDate()
    : new Date();
}

const TODAY = moment(new Date()).startOf("day");

function Rounds() {
  const { clubId, seasonId, competitionId } = useParams();

  const club = useSelector(getClubByID(clubId));
  const competition = useSelector(getCompetitionByID(competitionId));
  const rounds = useSelector(getRounds);
  const roundIDs = useSelector(getRoundIDs);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));
  const teams = useSelector(getTeams);

  const upcomingRoundID = orderBy(rounds, "date", "desc").reduce(
    (latestRoundId, round) =>
      moment(round.date).isSameOrAfter(TODAY, "day") ? round.id : latestRoundId,
    null
  );

  const canMakeRound = useMemo(
    () => hasEnoughFieldsAndTimeslots(competition, teams),
    [competition, teams]
  );

  const showFullRounds = !useContext(MobileContext).isMobile;

  return (
    <>
      {!canMakeRound && (
        <Row>
          <Col xs={12}>
            <Card color="warning" style={{ width: "100%" }}>
              <CardBody>
                {`There aren't enough fields or times to add new Rounds. `}
                <LinkText
                  bold
                  as={Link}
                  to={getEditCompetitionLink(clubId, seasonId, competitionId)}
                >
                  Edit this Competition
                </LinkText>{" "}
                to add more.
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          {roundIDs.length === 0 && (
            <DisabledParagraph>
              {`There doesn't seem to be any rounds for ${competition.name}...`}
            </DisabledParagraph>
          )}
          {roundIDs.length > 0 && isAuthenticated && (
            <LinkButton
              color="primary"
              padding={0}
              onClick={() =>
                downloadRounds(
                  clubId,
                  seasonId,
                  competitionId,
                  RoundTypes.roundRobin,
                  `${club.name} - ${competition.name} Rounds.xlsx`
                )
              }
            >
              Click here to download Rounds.
            </LinkButton>
          )}
        </Col>
      </Row>
      {isAuthenticated && (
        <Row>
          <Col xs={12}>
            {canMakeRound && (
              <AddRoundModalAndButton latestRoundID={roundIDs[0]} />
            )}
          </Col>
        </Row>
      )}
      {!showFullRounds && roundIDs.length > 0 && (
        <MobileRounds upcomingRoundID={upcomingRoundID} roundIDs={roundIDs} />
      )}
      {showFullRounds && roundIDs.length > 0 && (
        <FullRounds upcomingRoundID={upcomingRoundID} roundIDs={roundIDs} />
      )}
    </>
  );
}

function FullRounds({ upcomingRoundID, roundIDs }) {
  if (roundIDs.length === 0) {
    return null;
  }

  return roundIDs.map(roundID => (
    <Row key={roundID}>
      <Col xs={12}>
        <Round
          roundID={roundID}
          isHighlighted={roundID === upcomingRoundID}
          isExpanded={roundID === upcomingRoundID}
        />
      </Col>
    </Row>
  ));
}

function MobileRounds({ upcomingRoundID, roundIDs }) {
  const unsortedRounds = useSelector(getRounds);
  const rounds = roundIDs
    .map(roundID => unsortedRounds.find(round => round.id === roundID))
    .reverse();
  const [value, setValue] = useState(
    upcomingRoundID != null ? upcomingRoundID : rounds[rounds.length - 1].id
  );
  const index = useRef(roundIDs.indexOf(value));

  useEffect(() => {
    index.current = roundIDs.indexOf(value);
  }, [value, roundIDs]);

  if (!roundIDs.includes(value)) {
    index.current = index.current > 0 ? index.current - 1 : index.current;
    setValue(roundIDs[index.current]);
  }

  return (
    <>
      <ScrollableTabs value={value} onChange={setValue}>
        {rounds.map(round => (
          <ScrollableTabItem key={round.id} value={round.id}>
            {round.name}
          </ScrollableTabItem>
        ))}
      </ScrollableTabs>
      <Row>
        <Col xs={12}>
          <MobileRound roundID={value} />
        </Col>
      </Row>
    </>
  );
}

function AddRoundModalAndButton({ latestRoundID, isDisabled }) {
  const { value: hasModal, toggle: toggleModal } = useBoolean(false);
  const latestRound = useSelector(getRoundByID(latestRoundID));

  return (
    <>
      <Button
        color="primary"
        disabled={isDisabled}
        onClick={toggleModal}
        display="block"
      >
        Add New Round
      </Button>
      <AddRoundModal
        isOpen={hasModal}
        date={getNextRoundDate(latestRound?.date)}
        toggle={toggleModal}
      />
    </>
  );
}

export default Rounds;
