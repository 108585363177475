import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import sortBy from "lodash.sortby";
import { groupMatchesByTime } from "../../../shared/utils/matches";
import Match from "./Match";
import moment from "moment";
import AsyncStatus from "../../constants/AsyncStatus";
import { Row, Col } from "../atomic";
import FinalInlineDate from "../Finals/FinalInlineDate";

const MatchGroups = ({
  matches,
  status,
  teams,
  updateMatch,
  isMobile = false
}) => {
  const matchesByTime = useMemo(() => {
    const sortedMatches = sortBy(matches, ["time", "field"]);
    return groupMatchesByTime(sortedMatches);
  }, [matches]);

  const times = Object.keys(matchesByTime);

  const getUpdateTimeCallback = useCallback(
    time => {
      return newTime => {
        const newMatches = matchesByTime[time].map(match => {
          return { ...match, time: moment(newTime).toISOString() };
        });

        updateMatch(newMatches);
      };
    },
    [matchesByTime, updateMatch]
  );

  return times.map(time => (
    <Row key={time}>
      <Col xs={12}>
        <FinalInlineDate
          date={time}
          dateFormat="h:mm aa"
          displayFormat="h:mm A"
          isDisabled={updateMatch == null}
          onChange={getUpdateTimeCallback(time)}
          showTimeSelectOnly={true}
          timeIntervals={15}
        />
        {matchesByTime[time].map(match => (
          <Match
            key={match.id}
            isMobile={isMobile}
            match={match}
            status={status}
            matches={matchesByTime[time]}
            teams={teams}
            updateMatch={updateMatch}
          />
        ))}
      </Col>
    </Row>
  ));
};

MatchGroups.propTypes = {
  status: PropTypes.string,
  matches: PropTypes.array,
  teams: PropTypes.array,
  updateMatch: PropTypes.func
};

MatchGroups.defaultProps = {
  status: AsyncStatus.success,
  matches: [],
  teams: [],
  updateMatch: null
};

export default MatchGroups;
