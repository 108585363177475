import { types as AuthActions } from "../actions/auth";
import { types as ClubsActions } from "../actions/clubs";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  status: {},
};

const ClubsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case `${ClubsActions.get}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${ClubsActions.get}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(club => club.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }
    case `${ClubsActions.get}_REJECTED`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.failed };
      return { ...state, status };
    }
    // UPDATE
    case `${ClubsActions.update}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${ClubsActions.update}_FULFILLED`: {
      const { payload } = action;
      const index = state.list.findIndex(club => club.id === payload.id);

      const list = [...state.list];
      list[index] = payload;

      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      return { ...state, list, status };
    }
    case `${ClubsActions.uodate}_REJECTED`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.failed };
      return { ...state, status };
    }

    // FETCH
    case `${ClubsActions.fetch}_PENDING`: {
      return { ...state, list: [] };
    }
    case `${ClubsActions.fetch}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, club) => ({ ...result, [club.id]: AsyncStatus.success }),
        {},
      );

      return { list: action.payload, status };
    }
    case `${ClubsActions.fetch}_REJECTED`: {
      return { ...state };
    }
    // CREATE
    case `${ClubsActions.create}_FULFILLED`: {
      return { ...state, list: state.list.concat([action.payload]) };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default: {
      return { ...state };
    }
  }
};

export default ClubsReducer;
