import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import ScrollableTabContext from "./ScrollableTabContext";
import useSelection from "../../hooks/useSelection";
import { LinkButton } from "../atomic";

const Item = styled(LinkButton)`
  text-align: center;
  padding: 0.75em 1em;
  user-select: none;
`;

function ScrollableTabItem({ children, value }) {
  const ref = useRef(null);
  const { isSelected, select } = useSelection(value);
  const { deregisterTab, registerTab, tabWidth } = useContext(ScrollableTabContext);

  useEffect(() => {
    registerTab(value, ref);

    return () => {
      deregisterTab(value);
    };
  }, [registerTab, deregisterTab, value]);

  return (
    <Item
      ref={ref}
      color={isSelected ? "primary" : "neutral2"}
      onClick={select}
      style={{ width: tabWidth != null ? `${tabWidth}px` : undefined }}
      tabIndex="-1"
    >
      {children}
    </Item>
  );
}

export default ScrollableTabItem;
