import { types as TeamsActions } from "../actions/teams";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  status: {},
};

const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case `${TeamsActions.fetchForClub}_PENDING`: {
      return { ...state };
    }
    case `${TeamsActions.fetchForClub}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, team) => ({
          ...result,
          [team.id]: AsyncStatus.success,
        }),
        {},
      );

      return { list: action.payload, status };
    }
    case `${TeamsActions.fetchForClub}_REJECTED`: {
      return { ...state };
    }

    // SET
    case TeamsActions.set: {
      const status = action.payload.reduce(
        (result, team) => ({
          ...result,
          [team.id]: AsyncStatus.success,
        }),
        {},
      );

      return { list: action.payload, status };
    }

    default:
      return { ...state };
  }
};

export default TeamsReducer;
