import React, { useCallback, useEffect, useMemo, useRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, LinkButton } from "../atomic";
import useBoolean from "../../hooks/useBoolean";

function RoundInlineDate({ isDisabled, date: rawDate, onChange }) {
  const date = new Date(rawDate);
  const startDate = new Date();
  const datePickerRef = useRef();
  const { value: isActive, toggle } = useBoolean(false);
  const dateText = useMemo(() => moment(date).format("Do MMMM YYYY"), [date]);

  useEffect(() => {
    if (isActive && datePickerRef.current != null) {
      datePickerRef.current.input.focus();
    }
  }, [isActive, datePickerRef]);

  const safeOnChange = useCallback(val => (val != null ? onChange(val) : onChange(date)), [
    onChange,
    date,
  ]);

  return !isActive ? (
    <LinkButton isDisabled={isDisabled} underline="dotted" onClick={toggle}>
      {dateText}
    </LinkButton>
  ) : (
    <DatePicker
      id="date"
      name="date"
      customInput={<Input />}
      autoComplete="off"
      disabledKeyboardNavigation
      onChange={safeOnChange}
      onCalendarClose={toggle}
      selected={date}
      placeholderText={startDate.toLocaleDateString()}
      shouldCloseOnSelect
      ref={datePickerRef}
      todayButton="Today"
      dateFormat="dd/MM/yyyy"
    />
  );
}

export default RoundInlineDate;
