import orderBy from "lodash.orderby";

export const groupTeamsByDivision = teams => {
  const divisions = teams.reduce((aggregate, team) => {
    const updatedAggregate = { ...aggregate };
    const { division } = team;

    if (!updatedAggregate[division]) {
      updatedAggregate[division] = [];
    }

    updatedAggregate[division] = updatedAggregate[division].concat(team);

    return updatedAggregate;
  }, {});

  return Object.keys(divisions).reduce(
    (accumulator, divisionName) => ({
      ...accumulator,
      [divisionName]: orderBy(divisions[divisionName], "order", "asc"),
    }),
    {},
  );
};

export const pairTeamsByMatchups = (teams, matchups) =>
  matchups.map(matchup => [teams[matchup[0]], teams[matchup[1]]]);
