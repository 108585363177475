import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GoX } from "react-icons/go";
import CardHeader from "./CardHeader";
import LinkButton from "../typography/LinkButton";
import HeadingFive from "../typography/HeadingFive";

const ModalHeaderContainer = styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalHeader = ({ toggle, children, ...rest }) => (
  <ModalHeaderContainer {...rest}>
    <HeadingFive>{children}</HeadingFive>
    {toggle && (
      <LinkButton onClick={toggle}>
        <GoX />
      </LinkButton>
    )}
  </ModalHeaderContainer>
);

ModalHeader.propTypes = {
  toggle: PropTypes.func,
  children: PropTypes.any,
};
ModalHeader.defaultProps = {
  toggle: null,
  children: null,
};

export default ModalHeader;
