import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import Autocomplete from "react-autocomplete";
import sortBy from "lodash.sortby";
import { AutocompleteMenu, AutocompleteItem } from "../Autocomplete";
import { Input, Text } from "../atomic";

const TeamNameForm = ({ values, teams, setFieldValue, handleSubmit, submitForm, isSubmitting }) => {
  const sortedTeams = sortBy(teams, "name");
  const teamsToShow = [{ id: null, name: "No Team" }, ...sortedTeams];

  return (
    <form onSubmit={handleSubmit}>
      <Autocomplete
        id="name"
        name="name"
        getItemValue={team => team.name}
        items={teamsToShow}
        disabled={isSubmitting}
        wrapperStyle={{}}
        // eslint-disable-next-line
        renderMenu={(items, value, style) => (
          // eslint-disable-next-line
          <AutocompleteMenu style={{ ...style }}>{items}</AutocompleteMenu>
        )}
        renderInput={({ ref, ...rest }) => (
          <Input
            ref={ref}
            autoFocus
            disabled={isSubmitting}
            placeholder="Select a team to change to"
            {...rest}
          />
        )}
        renderItem={(team, isHighlighted) => (
          <AutocompleteItem key={team.id} isHighlighted={isHighlighted}>
            <Text color={team.id ? "inherit" : "neutral3"}>{team.name}</Text>
          </AutocompleteItem>
        )}
        value={values.team.name}
        onChange={e => {
          setFieldValue("team", { name: e.target.value });
        }}
        shouldItemRender={(item, value) => {
          const { name: itemName } = item;
          return itemName.toLowerCase().startsWith(value.toLowerCase());
        }}
        onSelect={(value, team) => {
          setFieldValue("team", team);
          submitForm();
        }}
      />
    </form>
  );
};

TeamNameForm.propTypes = {
  teams: PropTypes.array,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  submitForm: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TeamNameForm.defaultProps = {
  teams: [],
  values: {},
  handleSubmit: () => {},
  handleChange: () => {},
  setFieldValue: () => {},
  submitForm: () => {},
  handleBlur: () => {},
  isSubmitting: false,
};

const mapPropsToValues = () => ({
  team: {},
});

const validationSchema = yup.object().shape({
  team: yup.object().required(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const { onSubmit } = props;

    if (onSubmit) await onSubmit(values.team.id == null ? null : values.team);
  },
})(TeamNameForm);
