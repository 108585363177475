import api from ".";

export const update = async (clubId, seasonId, competitionId, roundId, match) =>
  api.put(
    `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/rounds/${roundId}/matches/${match.id}`,
    match,
  );

export default {
  update,
};
