import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import keycode from "keycode";
import moment from "moment";
import uniq from "lodash.uniq";
import Autocomplete from "react-autocomplete";
import sortBy from "lodash.sortby";
import SubmitButton from "../Buttons/SubmitButton";
import RemovableItemList from "../RemovableItemList/RemovableItemList";
import { AutocompleteInput, AutocompleteMenu, AutocompleteItem } from "../Autocomplete";
import AsyncStatus from "../../constants/AsyncStatus";
import { Row, Col, Label, Input, Checkbox, LinkButton } from "../atomic";

class TeamForm extends Component {
  static propTypes = {
    values: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    debouncedDispatchFetchTeamsForClub: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    saveButtonText: PropTypes.string,
    teams: PropTypes.array,
    clubTeams: PropTypes.array,
    timeslots: PropTypes.array,
    status: PropTypes.string,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    values: {},
    handleSubmit: () => {},
    handleChange: () => {},
    handleBlur: () => {},
    setFieldValue: () => {},
    debouncedDispatchFetchTeamsForClub: () => {},
    isSubmitting: false,
    isValid: false,
    saveButtonText: "Add Team",
    teams: [],
    clubTeams: [],
    timeslots: [],
    status: null,
    onDelete: null,
  };

  isTimeslotSelected = timeslot => {
    const { values } = this.props;
    const { preferences } = values;

    return preferences.some(preference => moment(preference).isSame(timeslot, "minutes"));
  };

  togglePreference = (event, preference) => {
    const { values, setFieldValue } = this.props;
    const { checked } = event.target;

    const { preferences } = values;

    if (checked) {
      setFieldValue("preferences", [...preferences, preference]);
    } else {
      const filteredPreferences = preferences.filter(
        existingPreference => existingPreference !== preference,
      );

      setFieldValue("preferences", filteredPreferences);
    }
  };

  addClash = clash => {
    const { values, setFieldValue } = this.props;
    const { clash: valueClash, clashes } = values;

    const clashToAdd = clash || valueClash;

    if (!clash || !clashToAdd.id) {
      return;
    }

    setFieldValue("clashes", [...clashes, clashToAdd]);
    setFieldValue("clash", {});
  };

  addClashFromKeyPress = e => {
    if (keycode.isEventKey(e, "enter")) {
      e.preventDefault();
      this.addClash();
    }
  };

  populateTeam = team => {
    const { values, setValues } = this.props;
    const { id, preferences, clashes, ...teamInfo } = team;

    setValues({
      ...values,
      ...teamInfo,
    });
  };

  populateTeamFromKeyPress = e => {
    if (keycode.isEventKey(e, "enter")) {
      e.preventDefault();
      this.populateTeam();
    }
  };

  removeClash = index => {
    const { values, setFieldValue } = this.props;

    setFieldValue(
      "clashes",
      values.clashes.filter((clash, clashIndex) => clashIndex !== index),
    );
  };

  render() {
    const {
      clubId,
      values,
      handleSubmit,
      handleChange,
      handleBlur,
      isSubmitting,
      isValid,
      timeslots,
      teams,
      clubTeams,
      status,
      saveButtonText,
      setFieldValue,
      onDelete,
      debouncedDispatchFetchTeamsForClub,
    } = this.props;
    const saving = isSubmitting || status === AsyncStatus.pending;
    const clashes = values.clashes.map(clash => clash.name);
    const possibleClashes = teams.filter(
      team => !(team.id === values.id || values.clashes.some(clash => clash.id === team.id)),
    );
    const sortedPossibleClashes = sortBy(possibleClashes, "name");
    const sortedClubTeams = sortBy(clubTeams, "name");

    const divisions = uniq(teams.map(team => team.division));
    divisions.sort();

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={8} sm={10}>
            <Label htmlFor="team-name">Team Name</Label>
            <Autocomplete
              id="team-name"
              name="name"
              getItemValue={team => team.name}
              items={sortedClubTeams}
              wrapperStyle={{}}
              // eslint-disable-next-line
              renderMenu={(items, value, style) => (
                <AutocompleteMenu style={{ ...style }}>{items}</AutocompleteMenu>
              )}
              renderInput={({ ref, ...rest }) => <Input ref={ref} autoFocus {...rest} />}
              renderItem={(clash, isHighlighted) => (
                <AutocompleteItem key={clash.id} isHighlighted={isHighlighted}>
                  {clash.name}
                </AutocompleteItem>
              )}
              value={values.name}
              onChange={e => {
                setFieldValue("name", e.target.value);
                debouncedDispatchFetchTeamsForClub(clubId, e.target.value);
              }}
              onSelect={(value, team) => {
                this.populateTeam(team);
              }}
            />
          </Col>
          <Col xs={4} sm={2}>
            <Label htmlFor="division">Division</Label>
            <Autocomplete
              id="division"
              name="division"
              getItemValue={division => division}
              items={divisions}
              renderMenu={(items, value, style) => (
                <AutocompleteMenu style={{ ...style }}>{items}</AutocompleteMenu>
              )}
              renderInput={({ ref, ...rest }) => <Input ref={ref} {...rest} />}
              renderItem={(division, isHighlighted) => (
                <AutocompleteItem key={division} isHighlighted={isHighlighted}>
                  {division}
                </AutocompleteItem>
              )}
              value={values.division}
              onChange={e => {
                setFieldValue("division", e.target.value);
              }}
              shouldItemRender={(item, value) => {
                const itemLower = item.toLowerCase();
                const valueLower = value.toLowerCase();

                return itemLower.startsWith(valueLower) && itemLower !== valueLower;
              }}
              onSelect={(value, division) => {
                setFieldValue("division", division);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="delegate-name">Team Delegate Name</Label>
            <Input
              type="text"
              name="delegateName"
              id="delegate-name"
              placeholder="John Smith"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.delegateName}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="delegate-email">Team Delegate Email Address</Label>
            <Input
              type="text"
              name="delegateEmail"
              id="delegate-email"
              placeholder="john.smith@email.com"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.delegateEmail}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="delegate-phone">Team Delegate Phone Number</Label>
            <Input
              type="text"
              name="delegatePhoneNumber"
              id="delegate-phone"
              placeholder="0400 000 000"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.delegatePhoneNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Label htmlFor="preferences">Time Preference</Label>
            {timeslots.length > 0 &&
              timeslots.map(timeslot => (
                <Col key={timeslot} xs={12}>
                  <Label display="block">
                    <Checkbox
                      checked={this.isTimeslotSelected(timeslot)}
                      onChange={event => this.togglePreference(event, timeslot)}
                    />{" "}
                    {moment(timeslot).format("h:mm A")}
                  </Label>
                </Col>
              ))}
          </Col>
          <Col xs={12} md={6}>
            <Label htmlFor="clash">Clashes</Label>
            <Autocomplete
              id="clash"
              name="clash"
              getItemValue={team => team.name}
              items={sortedPossibleClashes}
              wrapperStyle={{}}
              // eslint-disable-next-line
              renderMenu={(items, value, style) => (
                <AutocompleteMenu style={{ ...style }}>{items}</AutocompleteMenu>
              )}
              renderInput={({ ref, ...rest }) => (
                <AutocompleteInput
                  innerRef={ref}
                  onKeyPress={this.addClashFromKeyPress}
                  onAddClick={this.addClash}
                  disabled={!values.clash.id}
                  {...rest}
                />
              )}
              renderItem={(clash, isHighlighted) => (
                <AutocompleteItem key={clash.id} isHighlighted={isHighlighted}>
                  {clash.name}
                </AutocompleteItem>
              )}
              value={values.clash.name}
              onChange={e => {
                setFieldValue("clash", { name: e.target.value });
              }}
              shouldItemRender={(item, value) => {
                const { name: itemName } = item;
                return itemName.toLowerCase().startsWith(value.toLowerCase());
              }}
              onSelect={(value, clash) => {
                this.addClash(clash);
              }}
            />
            <RemovableItemList items={clashes} onRemove={this.removeClash} />
          </Col>
        </Row>
        <SubmitButton color="primary" disabled={!isValid} isSubmitting={saving}>
          {saveButtonText}
        </SubmitButton>
        {onDelete && (
          <div style={{ textAlign: "right" }}>
            <LinkButton align="right" color="error" disabled={saving} onClick={onDelete}>
              Delete
            </LinkButton>
          </div>
        )}
      </form>
    );
  }
}

const mapPropsToValues = ({ team = {}, timeslots = [] }) => {
  const defaults = {
    name: "",
    division: "",
    delegateName: "",
    delegateEmail: "",
    delegatePhoneNumber: "",
    clash: {},
    clashes: [],
    preferences: [...timeslots],
  };

  const { preferences: teamPreferences, ...existingTeam } = team;

  return {
    ...defaults,
    ...existingTeam,
    preferences: teamPreferences || [...timeslots],
  };
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  division: yup.string(),
  delegateName: yup.string().required(),
  delegateEmail: yup.string().required(),
  delegatePhoneNumber: yup.string().required(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const { team: existingTeam = {}, onSubmit } = props;
    const { clash, clashes: rawClashes, ...cleanedForm } = values;

    const { division: existingDivision } = existingTeam;
    const { order, division } = cleanedForm;

    const clashes = rawClashes.map(rawClash => ({ id: rawClash.id }));

    if (onSubmit)
      await onSubmit({
        ...cleanedForm,
        order: existingDivision === division ? order : null,
        clashes,
      });
  },
})(TeamForm);
