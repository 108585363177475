import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import { fetch as fetchTeams, set as setTeams } from "../actions/teams";
import { fetch as fetchRounds, set as setRounds } from "../actions/rounds";
import { fetch as fetchFinals, set as setFinals } from "../actions/finals";

import { Tabs, TabContent } from "../components/atomic";
import AppSpinner from "../components/AppSpinner";
import { getAppStatus } from "../selectors/status";
import { getCompetitionByID } from "../selectors/competitions";
import { getClubByID } from "../selectors/clubs";
import { getSeasonByID } from "../selectors/seasons";
import { getIsClubOwner } from "../selectors/user";
import { getRounds } from "../selectors/rounds";

import AsyncStatus from "../constants/AsyncStatus";
import MobileContext from "../context/MobileContext";

const TABS = [
  { id: "teams", name: "Teams" },
  { id: "rounds", name: "Rounds" },
  { id: "ladder", name: "Ladder" },
  { id: "finals", name: "Finals" },
];

export function Competition({ route }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clubId, seasonId, competitionId, tabId } = useParams();
  const [hasInitialized, setInitialized] = useState(false);
  const showFullTabs = !useContext(MobileContext).isMobile;

  const club = useSelector(getClubByID(clubId));
  const season = useSelector(getSeasonByID(seasonId));
  const competition = useSelector(getCompetitionByID(competitionId));
  const rounds = useSelector(getRounds);
  const status = useSelector(getAppStatus);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const changeTab = useCallback(
    tabID => {
      history.push(tabID);
    },
    [history],
  );

  useEffect(() => {
    if (tabId == null) {
      history.replace(`${history.location.pathname}/teams`);
    }
  }, [history, tabId]);

  useEffect(
    () => () => {
      dispatch(setTeams([]));
      dispatch(setRounds([]));
      dispatch(setFinals([]));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!hasInitialized) {
      dispatch(fetchTeams(clubId, seasonId, competitionId));
      dispatch(fetchRounds(clubId, seasonId, competitionId));
      dispatch(fetchFinals(clubId, seasonId, competitionId));
      setInitialized(true);
    }
  }, [hasInitialized, dispatch, club, season, competition, clubId, seasonId, competitionId]);

  const tabs = useMemo(
    () =>
      isAuthenticated && rounds.length > 0
        ? TABS.concat({ id: "reporting", name: "Reporting" })
        : TABS,
    [isAuthenticated, rounds],
  );

  const isReady = ![AsyncStatus.pending, AsyncStatus.unfetched].includes(status);

  if (!isReady) {
    return <AppSpinner />;
  }

  return (
    <>
      <Tabs tabs={tabs} isMobile={!showFullTabs} onChange={changeTab} activeTabId={tabId} />
      <TabContent activeTab={tabId}>{renderRoutes(route.routes)}</TabContent>
    </>
  );
}

export default connect()(Competition);
