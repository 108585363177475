import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import cloneDeep from "lodash.clonedeep";
import {
  Row,
  Col,
  Label,
  ExpandableCard,
  CardBody,
  ListItemGroup,
  ListItem,
  Checkbox,
  Toggle,
  LinkButton,
  Text,
} from "../atomic";
import RoundTypes from "../../../shared/constants/RoundTypes";
import { SubmitButton } from "../Buttons";
import FinalsMatchupPreview from "./FinalsMatchupPreview";
import { getFinalsMatches } from "../../../shared/utils/matches";
import AddCustomDivisionModal from "../modals/add-custom-division/AddCustomDivisionModal";

const CreateFinalsPreview = ({
  errors,
  values,
  isValid,
  handleSubmit,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
}) => {
  const { ladders } = values;
  const divisions = Object.keys(ladders);

  const addCustomDivision = _values => {
    const { ladders: _ladders } = values;
    const { name, teams } = _values;

    setFieldValue("ladders", {
      ..._ladders,
      [name]: cloneDeep(teams),
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {divisions.map(division => (
          <Row key={division}>
            <Col xs={12}>
              <ExpandableCard title={division}>
                <CardBody>
                  <Row>
                    <Col xs={12} md={6}>
                      <ListItemGroup>
                        {ladders[division].map((team, index) => (
                          <ListItem border="neutral4" key={team.id}>
                            <Label>
                              <Checkbox
                                name={`ladders.${division}[${index}].selected`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={team.selected}
                              />
                              <Text bold>{team.ranking}</Text> <Text>{team.name}</Text>
                            </Label>
                          </ListItem>
                        ))}
                      </ListItemGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FinalsMatchupPreview
                        error={errors[division]}
                        teams={ladders[division].filter(team => team.selected)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </ExpandableCard>
            </Col>
          </Row>
        ))}
        <Row>
          <Col xs={12}>
            <Toggle identifier="add-custom-division-modal" align="right">
              {({ active, toggle }) => (
                <>
                  <LinkButton type="button" color="primary" onClick={toggle}>
                    + Add another final
                  </LinkButton>
                  <AddCustomDivisionModal
                    id="add-custom-division-modal"
                    isOpen={active}
                    toggle={toggle}
                    ladders={ladders}
                    onSubmit={_values => {
                      addCustomDivision(_values);
                      toggle();
                    }}
                  />
                </>
              )}
            </Toggle>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
              Create Finals
            </SubmitButton>
          </Col>
        </Row>
      </form>
    </>
  );
};

CreateFinalsPreview.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CreateFinalsPreview.defaultProps = {
  values: {
    ladders: {},
  },
  errors: {},
  isValid: true,
  handleSubmit: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  isSubmitting: false,
};

const mapPropsToValues = ({ ladders: _ladders, limit = 4 }) => {
  const divisions = Object.keys(_ladders);
  const ladders = divisions.reduce((aggregate, division) => {
    const teams = _ladders[division].map((team, index) => ({
      id: team.team.id,
      name: team.team.name,
      ranking: team.ranking,
      selected: index < limit,
    }));

    return { ...aggregate, [division]: teams };
  }, {});

  return {
    ladders,
  };
};

const validate = values => {
  const { ladders } = values;

  const divisions = Object.keys(ladders);
  const errors = divisions.reduce((aggregate, division) => {
    const teams = ladders[division];
    const selectedTeams = teams.filter(team => team.selected);

    if (selectedTeams.length % 2 > 0) {
      return {
        ...aggregate,
        [division]: "You need an even amount of teams to play in a final",
      };
    }

    return { ...aggregate };
  }, {});

  return errors;
};

export default withFormik({
  isInitialValid: props => {
    const values = mapPropsToValues(props);
    const errors = validate(values);

    return Object.keys(errors).length === 0;
  },
  enableReinitialize: true,
  mapPropsToValues,
  validate,
  handleSubmit: (values, { props }) => {
    const { ladders } = values;
    const { date, onSubmit } = props;

    const names = Object.keys(ladders);

    const rounds = names.reduce((aggregate, name) => {
      const ladder = ladders[name];
      const teams = ladder.filter(team => team.selected);
      const matchups = getFinalsMatches(teams, name);

      return [
        ...aggregate,
        {
          name: "Finals Week 1",
          date,
          type: RoundTypes.elimination,
          matches: matchups.map(({ homeTeam, awayTeam }) => ({
            name,
            homeTeamId: homeTeam ? homeTeam.id : null,
            awayTeamId: awayTeam ? awayTeam.id : null,
          })),
        },
      ];
    }, []);

    onSubmit(rounds);
  },
})(CreateFinalsPreview);
