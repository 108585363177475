import CompetitionStatus from "../constants/CompetitionStatus";

export const getCompetitions = state => state.competitions.list;
export const getActiveCompetitions = state =>
  state.competitions.list.filter(competition => competition.status === CompetitionStatus.active);
export const getArchivedCompetitions = state =>
  state.competitions.list.filter(competition => competition.status === CompetitionStatus.archived);

export const getCompetitionById = (state, id) =>
  state.competitions.list.find(competition => competition.id === id);

export const getCompetitionByID = id => state =>
  state.competitions.list.find(competition => competition.id === Number(id));
export const getCompetitionStatusByID = id => state => state.competitions.status[Number(id)];
