import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import { BeatLoader } from "react-spinners";
import { GoCloudUpload } from "react-icons/go";
import AsyncStatus from "../../../constants/AsyncStatus";
import { ModalBody, Paragraph, Row, Col, HeadingSix } from "../../atomic";

const DropContainer = styled(Dropzone)`
  position: relative;
  display: flex;
  border: 2px #cccccc dashed;
  border-radius: 4px;
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;
`;
const InnerDropContainer = styled.div`
  text-align: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

const ImportTeamsStep3 = ({ onDrop, uploadStatus, uploadResponse }) => {
  const disabled = uploadStatus === AsyncStatus.pending;

  return (
    <ModalBody>
      {uploadStatus !== AsyncStatus.success && (
        <Fragment>
          <Row>
            <Col xs={12}>
              <Paragraph>
                {`You're ready to import your teams! Simply drop your completed Team
            Import Form below:`}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <DropContainer
                disabled={disabled}
                onDrop={(accepted, rejected) => {
                  if (rejected.length > 0) {
                    return;
                  }

                  onDrop(accepted);
                }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              >
                {({ isDragAccept, isDragReject }) => (
                  <InnerDropContainer disabled={disabled}>
                    <p>
                      {disabled && `Uploading your Teams now...`}
                      {!disabled && isDragAccept && `Drop this file to start importing`}
                      {!disabled && isDragReject && `That's not the right file...`}
                      {!disabled &&
                        !isDragAccept &&
                        !isDragReject &&
                        `Upload your Team Import Form`}
                    </p>
                    <div>
                      {!disabled && <GoCloudUpload size="2em" />}
                      {disabled && <BeatLoader />}
                    </div>
                  </InnerDropContainer>
                )}
              </DropContainer>
            </Col>
          </Row>
        </Fragment>
      )}
      {uploadStatus === AsyncStatus.success && (
        <>
          <HeadingSix color="success">All done!</HeadingSix>
          <Paragraph>
            Your teams have successfully been added! This page will refresh shortly...
          </Paragraph>
        </>
      )}
      {uploadStatus === AsyncStatus.failed && uploadResponse && (
        <>
          <HeadingSix color="error">Uh oh!</HeadingSix>
          <Paragraph>
            Something went wrong:
            {uploadResponse.message}
          </Paragraph>
        </>
      )}
    </ModalBody>
  );
};

ImportTeamsStep3.propTypes = {
  onDrop: PropTypes.func,
  uploadStatus: PropTypes.string,
  uploadResponse: PropTypes.object,
};

ImportTeamsStep3.defaultProps = {
  onDrop: () => {},
  uploadStatus: null,
  uploadResponse: null,
};

export default ImportTeamsStep3;
