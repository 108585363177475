import api, { assets } from ".";

export const fetch = async (clubId, seasonId, competitionId, params = {}) =>
  api.get(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/finals`, params);
export const create = async (clubId, seasonId, competitionId, round) =>
  api.post(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/finals`, round);
export const createBatch = async (clubId, seasonId, competitionId, rounds) =>
  api.post(
    `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/finals/batch`,
    rounds,
  );
export const remove = async (clubId, seasonId, competitionId, id) =>
  api.delete(`/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/finals/${id}`);

export const download = async (clubId, seasonId, id, params) =>
  assets.get(`clubs/${clubId}/seasons/${seasonId}/competitions/${id}/finals`, {
    params,
    responseType: "blob",
  });

export const update = async (clubId, seasonId, competitionId, round) =>
  api.put(
    `/clubs/${clubId}/seasons/${seasonId}/competitions/${competitionId}/finals/${round.id}`,
    round,
  );

export default {
  fetch,
  create,
  createBatch,
  remove,
  download,
  update,
};
