import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Card from "../atoms/Card";
import getWidthOfElement from "../utils/getWidthOfElement";
import Dropdown from "./Dropdown";

const MenuContainer = styled(Card)`
  margin: 0;
  position: absolute;
  z-index: 2;
  padding: 0;
  max-height: 10em;
  overflow-y: auto;
  top: ${props => props.location.top};
  right: ${props => props.location.right};
  bottom: ${props => props.location.bottom};
  left: ${props => props.location.left};
  border: 1px solid ${props => props.theme.colors[props.border]};
  box-shadow: 0 0 0.5rem ${props => props.theme.colors.neutral3};
  min-width: 10rem;
  background-color: white;
`;
MenuContainer.displayName = "MenuContainer";
MenuContainer.defaultProps = {
  border: "neutral4",
  location: {
    top: "initial",
    right: "initial",
    bottom: "intiial",
    left: "initial",
  },
};

class DropdownMenuContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    position: PropTypes.string,
    children: PropTypes.any,
  };

  static defaultProps = {
    children: null,
    position: "right",
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  state = {
    location: this.props.location,
  };

  componentDidMount() {
    const { location } = this.state;
    const { position } = this.props;
    const width = getWidthOfElement(this.containerRef.current);

    const left =
      position === "left" ? `calc(${location.left}px)` : `calc(${location.left - width}px  + 1em)`;

    this.setState({
      location: {
        top: `${location.top}px`,
        left,
      },
    });
  }

  render() {
    const { position, children } = this.props;
    const { location } = this.state;

    return (
      <MenuContainer ref={this.containerRef} position={position} location={location}>
        {children}
      </MenuContainer>
    );
  }
}

const DropdownMenu = ({ children, ...rest }) => (
  <Dropdown MenuContainer={DropdownMenuContainer} {...rest}>
    {children}
  </Dropdown>
);

DropdownMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.object]).isRequired,
};

export default DropdownMenu;
