import { createGlobalStyle } from "styled-components";
import Theme from "./theme";

const GlobalStyle = createGlobalStyle`
html,
body,
#root,
#app {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  background-color: ${Theme.colors.neutral4};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body.stop-scroll {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

* {
  box-sizing: border-box;
}

*::placeholder {
  color: #bbbbbb !important;
  opacity: 1 !important;
}

*:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbbbbb;
}

/*noinspection CssInvalidPseudoSelector*/
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbbbbb !important;
}
`;

export default GlobalStyle;
