import React from "react";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import Button from "../atomic/atoms/Button";

const SubmitButton = props => {
  const { isSubmitting, color, disabled, children, ...rest } = props;

  let child = children;
  if (isSubmitting) child = <BeatLoader size={10} color="#FFFFFF" />;

  return (
    <Button
      display="block"
      type="submit"
      color={color}
      disabled={disabled || isSubmitting}
      {...rest}
    >
      {child}
    </Button>
  );
};

SubmitButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

SubmitButton.defaultProps = {
  color: "primary",
  children: null,
  disabled: false,
  isSubmitting: false,
};
export default SubmitButton;
