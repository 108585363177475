import orderBy from "lodash.orderby";
import memoize from "lodash.memoize";
import {
  getWinnerId,
  getPointsForTeam,
  getOpposingPoints,
  getIncludedMatches,
  getForfeitMatches,
  hasMatchBeenPlayed,
} from "./matches";
import { getRoundsThatContainTeam } from "./rounds";

export const calculateLadderForTeam = (team, rounds = []) => {
  const teamRounds = getRoundsThatContainTeam(team, rounds);
  const teamMatches = getIncludedMatches(team, teamRounds);

  const completedMatches = teamMatches.filter(match => hasMatchBeenPlayed(match));
  const gamesForfeit = getForfeitMatches(team, completedMatches);

  const matchesPlayed = completedMatches.filter(match => !gamesForfeit.includes(match));

  const winningMatches = matchesPlayed.filter(match => getWinnerId(match) === team.id);

  const losingMatches = matchesPlayed.filter(match => {
    const winner = getWinnerId(match);

    return winner !== null && winner !== team.id;
  });

  const drawnMatches = matchesPlayed.filter(
    match => !winningMatches.includes(match) && !losingMatches.includes(match),
  );

  const pointsFor = matchesPlayed.reduce(
    (points, match) => points + getPointsForTeam(team, match),
    0,
  );

  const pointsAgainst = matchesPlayed.reduce(
    (points, match) => points + getOpposingPoints(team, match),
    0,
  );

  const totalPoints = winningMatches.length * 2 + drawnMatches.length;

  const { pointAdjustment = 0 } = team;
  const adjustedPoints = totalPoints + pointAdjustment;

  let strikeRate = adjustedPoints / matchesPlayed.length;

  if (!strikeRate || Math.abs(strikeRate) === Infinity) {
    strikeRate = 0;
  } else {
    strikeRate = Math.round(strikeRate * 100) / 100;
  }

  return {
    gamesPlayed: matchesPlayed.length,
    gamesWon: winningMatches.length,
    gamesDrawn: drawnMatches.length,
    gamesLost: losingMatches.length,
    gamesForfeit: gamesForfeit.length,
    gamesByed: rounds.length - teamRounds.length,
    pointsFor,
    pointsAgainst,
    pointsDifference: pointsFor - pointsAgainst,
    totalPoints,
    adjustedPoints,
    strikeRate,
  };
};

export const orderLadder = ladder =>
  orderBy(ladder, ["strikeRate", "adjustedPoints", "pointsDifference"], ["desc", "desc", "desc"]);

export const hasSameRanking = (teamA, teamB) => {
  if (teamA === null || teamB === null) {
    return false;
  }

  const {
    strikeRate: teamAStrikeRate,
    adjustedPoints: teamAPoints,
    pointsDifference: teamAPointsDifference,
  } = teamA;

  const {
    strikeRate: teamBStrikeRate,
    adjustedPoints: teamBPoints,
    pointsDifference: teamBPointsDifference,
  } = teamB;

  return (
    teamAStrikeRate === teamBStrikeRate &&
    teamAPoints === teamBPoints &&
    teamAPointsDifference === teamBPointsDifference
  );
};

export const addRankingToTeams = teams =>
  teams.map((team, index) => ({
    ...team,
    ranking: index + 1,
  }));

const calculateLaddersFn = (teamsByDivision, rounds = [], exclusions = []) => {
  const divisions = Object.keys(teamsByDivision);
  const roundsForLadder = rounds.filter(round =>
    exclusions.every(exclusion => exclusion.id !== round.id),
  );

  return divisions.reduce((aggregate, division) => {
    const divisionLadder = teamsByDivision[division].map(team => ({
      team,
      ...calculateLadderForTeam(team, roundsForLadder),
    }));

    const orderedDivisionLadder = orderLadder(divisionLadder);

    return {
      ...aggregate,
      [division]: addRankingToTeams(orderedDivisionLadder),
    };
  }, {});
};

export const calculateLadders = memoize(calculateLaddersFn, (...args) => JSON.stringify(args));
