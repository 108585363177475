import styled from "styled-components";

const CardBody = styled.div`
  padding: 1em;
  display: ${props => props.display};
`;

CardBody.displayName = "CardBody";
CardBody.defaultProps = {
  display: "block",
};

export default CardBody;
