import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { SubmitButton } from "./Buttons";
import { Row, Col, Input, Label } from "./atomic";

export const LoginForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  isSubmitting,
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <Label for="email">Email</Label>
      </Col>
      <Col xs={12}>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="manager@club.com.au"
          invalid={touched.email && !!errors.email}
          required
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Label for="password">Password</Label>
      </Col>
      <Col xs={12}>
        <Input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          invalid={touched.password && !!errors.password}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          disabled={isSubmitting}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
          Login
        </SubmitButton>
      </Col>
    </Row>
  </form>
);

LoginForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

LoginForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  isValid: true,
  handleSubmit: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  isSubmitting: false,
};

export default withFormik({
  mapPropsToValues: () => ({ email: "", password: "" }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { onSubmit } = props;

    try {
      if (onSubmit) await onSubmit(values);
    } catch (ex) {
      setSubmitting(false);
    }
  },
})(LoginForm);
