import styled from "styled-components";
import Button from "./Button";

const SmallButton = styled(Button)`
  padding: 0;
  min-width: 1.25em;
  border-radius: ${props => props.theme.radius.small};
`;

SmallButton.displayName = "SmallButton";
SmallButton.defaultProps = {
  width: "initial",
};
export default SmallButton;
