import { Component } from "react";
import PropTypes from "prop-types";

class EscapeListener extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onEscape: PropTypes.func,
    children: PropTypes.any,
  };

  static defaultProps = {
    disabled: false,
    onEscape: () => {},
    children: null,
  };

  componentDidMount() {
    this.bindListeners();
  }

  componentWillUnmount() {
    this.unbindListeners();
  }

  bindListeners = () => {
    const { disabled } = this.props;

    if (!disabled) {
      document.addEventListener("keydown", this.addEventListener);
    }
  };

  unbindListeners = () => {
    document.removeEventListener("keydown", this.addEventListener);
  };

  addEventListener = event => {
    if (event && event.keyCode === 27) {
      const { onEscape } = this.props;
      onEscape();
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default EscapeListener;
