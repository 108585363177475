import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SmallText } from "../";

const DropdownFooterContainer = styled.div`
  display: block;
  padding: 0.75em 1em;
  background-color: inherit;
  font-size: 0.75em;
`;
DropdownFooterContainer.displayName = "DropdownFooterContainer";

const DropdownFooter = ({ children, onClick, ...rest }) => (
  <DropdownFooterContainer
    {...rest}
    onClick={event => {
      event.stopPropagation();

      onClick();
    }}
  >
    <SmallText color="neutral5">{children}</SmallText>
  </DropdownFooterContainer>
);
DropdownFooter.propTypes = {
  onClick: PropTypes.func
};

DropdownFooter.defaultProps = {
  onClick: () => {}
};

export default DropdownFooter;
