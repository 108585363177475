import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import {
  Link,
  Text,
  NavItem,
  Dropdown,
  DropdownFooter,
  DropdownItem
} from "../atomic";
import { logout } from "../../actions/auth";

const MenuContainer = styled.div`
  position: fixed;
  top: ${props => props.location.top};
  right: ${props => props.location.right};
  width: 100%;
  background-color: ${props => props.theme.colors.neutral1};
  color: ${props => props.theme.colors.neutral5};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 12rem;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;
RelativeContainer.displayName = "RelativeContainer";

const IconContainer = styled.div`
  font-size: 1.75em;
  display: flex;
`;
IconContainer.displayName = "IconContainer";

const Name = styled(Text)`
  margin-left: 0.5em;
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: inline-block;
  }
`;
Name.displayName = "Name";

const UserNavItem = ({ user }) => {
  const hasUser = user != null && user.id != null;
  const history = useHistory();
  const dispatch = useDispatch();

  const goToLogin = useCallback(async () => {
    history.push("/login");
  }, [history]);

  const dispatchLogout = useCallback(async () => {
    await dispatch(logout());
    goToLogin();
  }, [dispatch, goToLogin]);

  return (
    <Dropdown
      location={{ right: 0, top: "3rem" }}
      position="fixed"
      MenuContainer={MenuContainer}
      Trigger={
        <NavItem>
          <IconContainer>
            <FaUserCircle />
          </IconContainer>
          <Name color="inherit">{user.firstName}</Name>
        </NavItem>
      }
    >
      {hasUser && (
        <DropdownItem static onClick={dispatchLogout}>
          Logout
        </DropdownItem>
      )}
      {!hasUser && (
        <DropdownItem static onClick={goToLogin}>
          Login
        </DropdownItem>
      )}
      <DropdownFooter>
        <Link href="/policies/privacy">Privacy</Link>
        &nbsp;&#8226;&nbsp;
        <Link href="/policies/terms">Terms</Link>
        &nbsp;&#8226;&nbsp; ROBN Pty Ltd © 2020
      </DropdownFooter>
    </Dropdown>
  );
};

UserNavItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserNavItem;
