import { createAction } from "redux-actions";
import fileDownload from "js-file-download";
import TeamsAPI from "../api/teams";

export const types = {
  get: "TEAM_GET",
  fetch: "TEAMS_FETCH",
  fetchForClub: "CLUB_TEAMS_FETCH",
  create: "TEAMS_CREATE",
  update: "TEAMS_UPDATE",
  updateBulk: "TEAMS_UPDATE_BULK",
  remove: "TEAMS_REMOVE",
  set: "TEAMS_SET",
  updateFulfilled: "TEAMS_UPDATE_FULFILLED",
  upload: "TEAMS_UPLOAD",
};

export const get = createAction(types.get, TeamsAPI.get, id => ({ id }));
export const remove = createAction(
  types.remove,
  TeamsAPI.remove,
  (clubId, seasonId, competitionId, id) => ({
    id,
  }),
);
export const fetch = createAction(types.fetch, TeamsAPI.fetch);
export const fetchForClub = createAction(types.fetchForClub, TeamsAPI.fetchForClub);
export const create = createAction(types.create, TeamsAPI.create);
export const update = createAction(
  types.update,
  TeamsAPI.update,
  (clubId, seasonId, competitionId, team) => ({ id: team.id }),
);
export const updateBulk = createAction(
  types.updateBulk,
  TeamsAPI.updateBulk,
  (clubId, seasonId, competitionId, teams) => ({ payload: teams }),
);
export const updateFulfilled = createAction(types.updateFulfilled);

export const downloadTemplate = async fileName => {
  const data = await TeamsAPI.downloadTemplate();
  fileDownload(data.data, fileName);
};

export const set = createAction(types.set);

export const upload = createAction(types.upload, TeamsAPI.upload);
export const clearUpload = createAction(`${types.upload}_CLEAR`);
