import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const Club = ({ match }) => <Redirect to={`${match.url}/seasons`} />;

Club.propTypes = {
  match: PropTypes.object,
};

Club.defaultProps = {
  match: {},
};

export default Club;
