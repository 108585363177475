import React, { Component } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import ForgotFormEmail from "../components/ForgotFormEmail";
import ForgotFormPassword from "../components/ForgotFormPassword";
import { reset, forgot } from "../actions/auth";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Link as LinkText,
  HeadingThree,
} from "../components/atomic";

export class Signup extends Component {
  static propTypes = {
    user: PropTypes.object,
    token: PropTypes.string,
    dispatchReset: PropTypes.func,
  };

  static defaultProps = {
    user: {},
    token: null,
    dispatchReset: () => {},
  };

  sendForgotRequest = async user => {
    const { dispatchForgot } = this.props;
    await dispatchForgot(user);
  };

  resetPassword = async user => {
    const { token, dispatchReset, history } = this.props;
    await dispatchReset(user, token);

    history.push("/login");
  };

  render() {
    const { user, token } = this.props;

    if (user.id) {
      return <Redirect push to="/" />;
    }

    return (
      <Container>
        <Row center="xs">
          <Col xs={12} md={10}>
            <HeadingThree align="center">Forgot password</HeadingThree>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} md={10}>
            <Card>
              <CardBody>
                {!token && <ForgotFormEmail onSubmit={this.sendForgotRequest} />}
                {token && <ForgotFormPassword onSubmit={this.resetPassword} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} md={10} align="right">
            <LinkText as={Link} to="/login" align="right">
              Remembered your account details? Log in
            </LinkText>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(
  (state, props) => {
    const { user } = state;
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    return {
      user,
      token: query.token,
    };
  },
  { dispatchForgot: forgot, dispatchReset: reset },
)(Signup);
