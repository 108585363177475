import styled from "styled-components";

const TableRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors[props.border]};
  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

TableRow.defaultProps = {
  border: "neutral4",
};

export default TableRow;
