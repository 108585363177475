import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Link from "../typography/Link";

const TabContainer = styled.li`
  text-align: center;
  list-style: none;
  border-top-left-radius: ${props => props.theme.radius.element};
  border-top-right-radius: ${props => props.theme.radius.element};
  font-weight: ${props => (props.active ? "bold" : "normal")};
  background-color: ${props => (props.active ? props.theme.colors.neutral5 : "transparent")};
  cursor: pointer;
`;

const TabItem = styled(Link)`
  min-width: 10em;
  padding: 0.5em;
  display: inline-block;
`;

const Tab = ({ children, active, ...rest }) => (
  <TabContainer active={active} {...rest}>
    <TabItem underline={false} bold={active}>
      {children}
    </TabItem>
  </TabContainer>
);

Tab.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
};

Tab.defaultProps = {
  children: null,
  active: false,
};

export default Tab;
