import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import * as yup from "yup";
import keycode from "keycode";
import moment from "moment";
import SubmitButton from "../Buttons/SubmitButton";
import RemovableItemList from "../RemovableItemList/RemovableItemList";
import InputGroupWrapper from "../DatePickers/InputGroupWrapper";
import BlockTimePicker from "../DatePickers/BlockTimePicker";
import {
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  InputAddon,
  Button
} from "../atomic";

class CompetitionForm extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    clubId: PropTypes.number.isRequired,
    values: PropTypes.object,
    isValid: PropTypes.bool,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    isSubmitting: PropTypes.bool,
    saveButtonText: PropTypes.string
  };

  static defaultProps = {
    values: {},
    isValid: true,
    touched: {},
    errors: {},
    handleSubmit: () => {},
    handleChange: () => {},
    handleBlur: () => {},
    setFieldValue: () => {},
    isSubmitting: false,
    saveButtonText: "Add Competition"
  };

  constructor(props) {
    super(props);

    this.fieldInput = React.createRef();
    this.timeslotInput = React.createRef();
  }

  addField = () => {
    const { values, setFieldValue } = this.props;
    const { fields, fieldName } = values;

    if (!fieldName) return;

    setFieldValue("fields", fields.concat({ name: fieldName }));
    setFieldValue("fieldName", "");
    this.fieldInput.focus();
  };

  addFieldFromKeyPress = e => {
    if (keycode.isEventKey(e, "enter")) {
      e.preventDefault();
      this.addField();
    }
  };

  removeField = index => {
    const { values, setFieldValue } = this.props;
    setFieldValue(
      "fields",
      values.fields.filter((field, fieldIndex) => fieldIndex !== index)
    );
  };

  setTimeslot = timeslot => {
    const { setFieldValue } = this.props;
    setFieldValue("timeslot", timeslot);
  };

  addTimeslot = () => {
    const {
      values: { timeslot: valueTimeslot, timeslots },
      setFieldValue
    } = this.props;

    if (!valueTimeslot) return;

    setFieldValue("timeslots", timeslots.concat({ time: valueTimeslot }));

    this.setTimeslot(null);

    if (this.timeslotInput && this.timeslotInput.current) {
      this.timeslotInput.focus();
    }
  };

  removeTimeslot = index => {
    const { values, setFieldValue } = this.props;
    setFieldValue(
      "timeslots",
      values.timeslots.filter(
        (timeslot, timeslotIndex) => timeslotIndex !== index
      )
    );
  };

  render() {
    const {
      values,
      isValid,
      touched,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
      isSubmitting,
      saveButtonText
    } = this.props;

    const fields = values.fields.map(field => field.name);
    const timeslots = values.timeslots.map(timeslot =>
      moment(timeslot.time).format("h:mm A")
    );

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Label htmlFor="season-name">Competition Name</Label>
            <Input
              type="text"
              name="name"
              id="competition-name"
              placeholder="Wednesday Night"
              invalid={touched.name && !!errors.name}
              onChange={handleChange}
              autoFocus
              onBlur={handleBlur}
              value={values.name}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="location">Location (optional)</Label>
            <Input
              type="text"
              name="location"
              id="location"
              placeholder="Rosford Street Reserve"
              invalid={touched.location && !!errors.location}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="field-name">Fields</Label>
            <InputGroup>
              <Input
                type="text"
                name="fieldName"
                id="field-name"
                placeholder="Field 1"
                align="right"
                invalid={touched.fieldName && !!errors.fieldName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fieldName}
                onKeyPress={this.addFieldFromKeyPress}
                ref={input => {
                  this.fieldInput = input;
                }}
              />
              <InputAddon
                as={Button}
                color="primary"
                onClick={this.addField}
                disabled={!values.fieldName}
              >
                +
              </InputAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RemovableItemList items={fields} onRemove={this.removeField} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label htmlFor="timeslot">Time Slots</Label>
            <InputGroup>
              <InputGroupWrapper>
                <BlockTimePicker
                  id="timeslot"
                  name="timeslot"
                  inputGroup
                  customInput={<Input align="right" onBlur={handleBlur} />}
                  autoComplete="off"
                  selected={values.timeslot}
                  dateFormat="h:mm aa"
                  disabledKeyboardNavigation
                  onChange={this.setTimeslot}
                  onBlur={this.addTimeslot}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  shouldCloseOnSelect
                  popperPlacement="top-end"
                />
              </InputGroupWrapper>
              <InputAddon
                as={Button}
                color="primary"
                onClick={this.addTimeslot}
                disabled={!values.timeslot}
              >
                +
              </InputAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <RemovableItemList
              items={timeslots}
              onRemove={this.removeTimeslot}
            />
          </Col>
        </Row>
        <SubmitButton disabled={!isValid} isSubmitting={isSubmitting}>
          {saveButtonText}
        </SubmitButton>
      </form>
    );
  }
}

const mapPropsToValues = ({ clubId, seasonId, competition = {} }) => {
  const defaults = {
    clubId,
    seasonId,
    name: "",
    location: "",
    fieldName: "",
    timeslot: null,
    fields: [],
    timeslots: []
  };

  const { timeslots: competitionTimeslots = [] } = competition;
  const timeslots = competitionTimeslots.map(({ time, ...rest }) => ({
    time: moment(time),
    ...rest
  }));

  return { ...defaults, ...competition, timeslots };
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  location: yup.string(),
  fieldName: yup.string(),
  timeslot: yup.string().nullable(),
  fields: yup.array().required(),
  timeslots: yup.array().required()
});

export default withFormik({
  isInitialValid: props =>
    validationSchema.isValidSync(mapPropsToValues(props)),
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const { onSubmit } = props;
    const { fieldName, timeslot, timeslots, fields, ...cleanedValues } = values;

    const finalValues = {
      ...cleanedValues,
      fields: fields.map(field => ({ ...field, name: field.name.trim() })),
      timeslots
    };

    if (onSubmit) await onSubmit(finalValues);
  }
})(CompetitionForm);
