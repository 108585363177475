import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsClubOwner } from "../../selectors/user";
import TeamNameForm from "./TeamNameForm";
import AsyncStatus from "../../constants/AsyncStatus";
import Clashes from "./Clashes";
import { LinkButton, Toggle } from "../atomic";

const TeamContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.position === "left" ? "flex-end" : "flex-start")};
`;

const MatchTeam = ({ isDisabled, match, team, status, clashes, position, teams, onTeamUpdate }) => {
  const { clubId } = useParams();
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const isEditable = !isDisabled && onTeamUpdate != null;
  const name = team ? team.name : "No Team";

  return (
    <Toggle closeOnEscape closeOnClickOff>
      {({ active, toggle }) => {
        if (!active) {
          return (
            <TeamContainer position={position}>
              {isAuthenticated && position === "left" && clashes.length > 0 && (
                <Clashes id={`match-${match.id}-${position}-${team.id}`} clashes={clashes} />
              )}
              <LinkButton
                ellipsis
                isDisabled={!isEditable}
                underline="dotted"
                onClick={toggle}
                color={!team ? "neutral3" : "neutral0"}
              >
                {name}
              </LinkButton>
              {isAuthenticated && position === "right" && clashes.length > 0 && (
                <Clashes id={`match-${match.id}-${position}-${team.id}`} clashes={clashes} />
              )}
            </TeamContainer>
          );
        }
        return (
          <TeamNameForm
            teams={teams}
            onSubmit={async updatedTeam => {
              await onTeamUpdate(updatedTeam);
              toggle(false);
            }}
          />
        );
      }}
    </Toggle>
  );
};

MatchTeam.propTypes = {
  position: PropTypes.string,
  status: PropTypes.string,
  onTeamUpdate: PropTypes.func,
  team: PropTypes.object,
  match: PropTypes.object,
  teams: PropTypes.array,
  clashes: PropTypes.array,
};

MatchTeam.defaultProps = {
  position: "left",
  status: AsyncStatus.success,
  onTeamUpdate: null,
  team: null,
  match: null,
  teams: [],
  clashes: [],
};

export default MatchTeam;
