import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { getCurrentUser } from "../actions/users";
import { get as getClub } from "../actions/clubs";
import { Spinner, AbsoluteCenter } from "../components/atomic";
import { getClubByID, getClubStatusById } from "../selectors/clubs";
import { getUser } from "../selectors/user";
import AsyncStatus from "../constants/AsyncStatus";

function AuthGate({ authRoute, children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const match = useRouteMatch("/clubs/:clubId");
  const { id: userId, isAuthenticating } = user;

  const clubId = match != null ? Number(match.params.clubId) : null;
  const club = useSelector(getClubByID(clubId));
  const clubStatus = useSelector(getClubStatusById(clubId));

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (clubId != null && club === null && clubStatus !== AsyncStatus.pending) {
      dispatch(getClub(clubId));
    }
  }, [dispatch, club, clubId, userId, clubStatus]);

  useEffect(() => {
    if (
      !isAuthenticating &&
      ((clubId == null && userId == null) || (clubId != null && clubStatus === AsyncStatus.failed))
    ) {
      history.replace(authRoute);
    }
  }, [clubId, clubStatus, history, authRoute, userId, isAuthenticating]);

  if (isAuthenticating) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return children;
}

export default connect()(AuthGate);
