import React from "react";
import { Link } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { withTheme } from "styled-components";
import {
  Card,
  CardBody,
  Page,
  Container,
  NavBar,
  NavItem
} from "../components/atomic";

function PolicyContainer({ route, theme }) {
  return (
    <Page>
      <NavBar
        LeftItems={[
          <NavItem key="home" as={Link} to="/">
            Home
          </NavItem>
        ]}
      />
      <Container>
        <Card>
          <CardBody>{renderRoutes(route.routes)}</CardBody>
        </Card>
      </Container>
    </Page>
  );
}

const StyledPolicyContainer = withTheme(PolicyContainer);

export default StyledPolicyContainer;
