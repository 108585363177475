import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoGlobe, GoKebabVertical } from "react-icons/go";
import styled from "styled-components";
import LinkCard from "../components/LinkCard";
import AddClubModal from "../components/modals/add-club/AddClubModal";
import { fetch, create, update } from "../actions/clubs";
import { getClubs } from "../selectors/clubs";
import { getAppStatus } from "../selectors/status";
import { getIsAuthenticated } from "../selectors/user";
import {
  Row,
  Col,
  HeadingThree,
  Card,
  CardBody,
  DisabledParagraph,
  Button,
  Tooltip,
  DropdownMenu,
  DropdownItem
} from "../components/atomic";
import AppSpinner from "../components/AppSpinner";
import AsyncStatus from "../constants/AsyncStatus";
import useBoolean from "../hooks/useBoolean";

const CenterAligned = styled.div`
  display: flex;
  align-items: center;
`;

function Clubs({ clubs, status }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    value: isModalVisible,
    setTrue: showModal,
    toggle: toggleModal
  } = useBoolean(false);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const createClub = useCallback(
    async values => {
      try {
        const action = await dispatch(create(values));
        const { data } = action.value;

        history.push(`/clubs/${data.id}`);
      } catch (ex) {
        console.error(ex);
      }
    },
    [dispatch, history]
  );

  const publishClub = useCallback(
    club => {
      const confirmed = window.confirm(
        `Are you sure you want to publish ${club.name}? All season and competition information will be available to the public.`
      );

      if (confirmed) {
        dispatch(update({ ...club, isPublished: true }));
      }
    },
    [dispatch]
  );

  const unpublishClub = useCallback(
    club => {
      const confirmed = window.confirm(
        `Are you sure you want to unpublish ${club.name}? All season and competition information will no longer be available to the public.`
      );

      if (confirmed) {
        dispatch(update({ ...club, isPublished: false }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  const isPending = status === AsyncStatus.pending;

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <HeadingThree color="primary">Clubs</HeadingThree>
            {isAuthenticated && (
              <Button onClick={showModal}>Add New Club</Button>
            )}
          </CardBody>
        </Card>
      </Col>
      {isPending && <AppSpinner />}
      {!isPending &&
        clubs.map(club => (
          <Col xs={12} key={club.id}>
            <LinkCard
              to={`/clubs/${club.id}`}
              Icon={
                <DropdownMenu Trigger={<GoKebabVertical />}>
                  <DropdownItem
                    onClick={() =>
                      club.isPublished ? unpublishClub(club) : publishClub(club)
                    }
                  >
                    {club.isPublished ? "Unpublish" : "Publish"}
                  </DropdownItem>
                </DropdownMenu>
              }
            >
              <CenterAligned>
                {club.name}&nbsp;{club.isPublished ? `•` : null}&nbsp;
                {club.isPublished ? (
                  <Tooltip
                    Trigger={
                      <CenterAligned>
                        <GoGlobe title="Published" />
                      </CenterAligned>
                    }
                  >
                    Publicly Visible
                  </Tooltip>
                ) : null}
              </CenterAligned>
            </LinkCard>
          </Col>
        ))}
      {!isPending && clubs.length === 0 && (
        <DisabledParagraph
          align="center"
          display="block"
          width="100%"
          color="neutral2"
        >
          {`There doesn't seem to be any Clubs to view yet...`}
        </DisabledParagraph>
      )}
      <AddClubModal
        isOpen={isModalVisible}
        toggle={toggleModal}
        onSubmit={createClub}
      />
    </Row>
  );
}
export default connect(state => ({
  clubs: getClubs(state),
  status: getAppStatus(state)
}))(Clubs);
