import orderBy from "lodash.orderby";
import { types as AuthActions } from "../actions/auth";
import { types as TeamsActions } from "../actions/teams";
import AsyncStatus from "../constants/AsyncStatus";
import { groupTeamsByDivision } from "../../shared/utils/teams";

export const initialState = {
  list: [],
  list_MODERN: {},
  status: {},
  divisions: {},
};

const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    // SET
    case TeamsActions.set: {
      const status = action.payload.reduce(
        (result, team) => ({
          ...result,
          [team.id]: AsyncStatus.success,
        }),
        {},
      );

      const { payload: list } = action;
      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { list, list_MODERN, divisions, status };
    }
    // GET
    case `${TeamsActions.get}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${TeamsActions.get}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(team => team.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, list, list_MODERN, divisions, status };
    }

    // REMOVE
    case `${TeamsActions.remove}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${TeamsActions.remove}_FULFILLED`: {
      const { meta } = action;

      const team = state.list.find(_team => _team.id === meta.id);

      const otherTeams = [];
      const teamsInSameDivision = [];
      state.list.forEach(_team => {
        if (_team.division !== team.division) {
          otherTeams.push(_team);
        } else if (_team.id !== team.id) {
          teamsInSameDivision.push(_team);
        }
      });

      const newDivisionOrder = orderBy(teamsInSameDivision, "order", "asc").map((_team, index) => ({
        ..._team,
        order: index + 1,
      }));

      const status = {
        ...state.status,
      };

      const list = otherTeams.concat(newDivisionOrder);
      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, list, list_MODERN, divisions, status };
    }

    // UPDATE
    case `${TeamsActions.update}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${TeamsActions.update}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(team => team.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };
      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, list, list_MODERN, status, divisions };
    }

    // BULK UPDATE
    case `${TeamsActions.updateBulk}_PENDING`: {
      const { meta } = action;
      const { payload } = meta;
      const teamsById = payload.reduce(
        (accumulator, team) => ({ ...accumulator, [team.id]: team }),
        {},
      );

      const status = payload.reduce(
        (accumulator, team) => ({
          ...accumulator,
          [team.id]: AsyncStatus.pending,
        }),
        { ...state.status },
      );

      const list = state.list.map(team => {
        if (typeof teamsById[team.id] !== "undefined") {
          const newTeam = teamsById[team.id];

          return {
            ...team,
            division: newTeam.division,
            order: newTeam.order,
          };
        }

        return { ...team };
      });

      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, status, list, list_MODERN, divisions };
    }
    case `${TeamsActions.updateBulk}_FULFILLED`: {
      const { payload } = action;
      const { teams } = payload;
      const teamsById = teams.reduce(
        (accumulator, team) => ({ ...accumulator, [team.id]: team }),
        {},
      );

      const list = state.list
        .filter(team => typeof teamsById[team.id] === "undefined")
        .concat(teams);

      const status = teams.reduce(
        (accumulator, team) => ({
          ...accumulator,
          [team.id]: AsyncStatus.success,
        }),
        { ...state.status },
      );

      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, list, list_MODERN, divisions, status };
    }

    // CREATE
    case `${TeamsActions.create}_FULFILLED`: {
      const { payload } = action;
      const list = [...state.list, payload];
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };
      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { ...state, list, list_MODERN, divisions, status };
    }

    // FETCH
    case `${TeamsActions.fetch}_PENDING`: {
      return { ...state };
    }
    case `${TeamsActions.fetch}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, team) => ({
          ...result,
          [team.id]: AsyncStatus.success,
        }),
        {},
      );

      const { payload: list } = action;
      const divisions = groupTeamsByDivision(list);
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      return { list, list_MODERN, divisions, status };
    }
    case `${TeamsActions.fetch}_REJECTED`: {
      return { ...state };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default TeamsReducer;
