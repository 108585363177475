import styled from "styled-components";
import TableData from "./TableData";

const TableHeading = styled(TableData)``;

TableHeading.defaultProps = {
  as: "th",
};

export default TableHeading;
