import React from "react";
import PropTypes from "prop-types";
import AddSeasonModalForm from "./AddSeasonModalForm";
import { Modal, ModalHeader } from "../../atomic";

export const AddSeasonModal = ({ toggle, onSubmit, ...rest }) => (
  <Modal toggle={toggle} {...rest}>
    <ModalHeader toggle={toggle}>Add New Season</ModalHeader>
    <AddSeasonModalForm onSubmit={onSubmit} />
  </Modal>
);

AddSeasonModal.propTypes = {
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddSeasonModal.defaultProps = {
  toggle: () => {},
  onSubmit: () => {},
};

export default AddSeasonModal;
