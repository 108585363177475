import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text, Link as LinkText } from "../atomic";
import HeadingThree from "../atomic/typography/HeadingThree";
import HeadingFour from "../atomic/typography/HeadingFour";

const HeaderText = styled(Text)`
  font-weight: inherit;
  color: inherit;
`;
HeaderText.displayName = "HeaderText";

const ClubHeader = ({ club, season, competition, ...rest }) => {
  if (!club) {
    return null;
  }

  return (
    <div {...rest}>
      <HeadingThree color="primary">
        <LinkText as={Link} to={`/clubs/${club.id}`}>
          {club.name}
        </LinkText>
      </HeadingThree>
      {season && (
        <HeadingFour color="primary">
          <LinkText as={Link} to={`/clubs/${club.id}/seasons/${season.id}`}>
            {season.name}
          </LinkText>
          {competition && (
            <>
              <Text color="primary">&nbsp;/&nbsp;</Text>
              <LinkText
                as={Link}
                to={`/clubs/${club.id}/seasons/${season.id}/competitions/${competition.id}`}
              >
                {competition.name}
              </LinkText>
            </>
          )}
        </HeadingFour>
      )}
    </div>
  );
};

export default ClubHeader;
