import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledListGroup = styled.div`
  position: absolute;
  z-index: 1;
  max-height: 10em;
  overflow: auto;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
`;

// eslint-disable-next-line
class AutocompleteMenu extends Component {
  static propTypes = {
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    const { style, ...rest } = this.props;
    const styles = {
      minWidth: style.minWidth,
    };

    return <StyledListGroup style={styles} {...rest} />;
  }
}

export default AutocompleteMenu;
