import React, { useCallback, useRef } from "react";
import { GoCircleSlash } from "react-icons/go";
import ScoreForm from "./ScoreForm";
import MatchTeamStatus from "../../../shared/constants/MatchTeamStatus";
import { LinkButton, Overlay } from "../atomic";
import useOverlay from "../../hooks/useOverlay";

function Score({ isDisabled, score, status, onSubmit, onForfeit }) {
  const triggerRef = useRef();
  const { value: isVisible, toggle, containerRef } = useOverlay({
    value: false,
  });
  const isEditable = !isDisabled && onSubmit != null;

  const submitAndDismiss = useCallback(
    async _score => {
      await onSubmit(_score);
      toggle();
    },
    [toggle, onSubmit],
  );

  const forfeitAndDismiss = useCallback(async () => {
    await onForfeit();
    toggle();
  }, [toggle, onForfeit]);

  return (
    <>
      <LinkButton
        color={status === MatchTeamStatus.forfeit ? "error" : "secondary"}
        align="center"
        isDisabled={!isEditable}
        underline="dotted"
        onClick={toggle}
        ref={triggerRef}
        title="Click to enter score"
      >
        {status !== MatchTeamStatus.forfeit && (score === null ? "-" : score)}
        {status === MatchTeamStatus.forfeit && <GoCircleSlash />}
      </LinkButton>
      {isVisible && (
        <>
          <Overlay />
          <ScoreForm
            score={score}
            triggerRef={triggerRef}
            onSubmit={submitAndDismiss}
            onForfeit={forfeitAndDismiss}
            forwardRef={containerRef}
          />
        </>
      )}
    </>
  );
}

export default Score;
