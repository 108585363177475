import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GoKebabVertical } from "react-icons/go";
import { Text, ListItem, DropdownMenu, DropdownItem, Spinner } from "../atomic";
import { remove as removeTeam } from "../../actions/teams";
import { getTeamByID, getStatusByID } from "../../selectors/teams";
import { getIsClubOwner } from "../../selectors/user";
import AsyncStatus from "../../constants/AsyncStatus";

const SpinnerContainer = styled.div`
  height: 1.5em;
`;
SpinnerContainer.displayName = "SpinnerContainer";

function Team({ teamID, draggable }) {
  const dispatch = useDispatch();
  const { clubId, seasonId, competitionId } = useParams();

  const team = useSelector(getTeamByID(teamID));
  const status = useSelector(getStatusByID(teamID));
  const isAuthenticated = useSelector(getIsClubOwner(clubId));
  const location = useLocation();

  const dispatchRemoveTeam = useCallback(() => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${team.name}? This can't be undone.`,
    );

    if (confirmed) {
      dispatch(removeTeam(clubId, seasonId, competitionId, team.id));
    }
  }, [team, dispatch, clubId, seasonId, competitionId]);

  const isPending = status === AsyncStatus.pending;
  const editURI = `${location.pathname}/${teamID}/edit`;

  return (
    <ListItem {...draggable} border="neutral4" color="neutral5">
      {isAuthenticated && <Link to={editURI}>{team.name}</Link>}
      {!isAuthenticated && <Text>{team.name}</Text>}
      {isPending && (
        <SpinnerContainer>
          <Spinner size={4} color="neutral3" />
        </SpinnerContainer>
      )}
      {isAuthenticated && !isPending && (
        <DropdownMenu Trigger={<GoKebabVertical />}>
          <DropdownItem as={Link} to={editURI}>
            Edit
          </DropdownItem>
          <DropdownItem
            as={Text}
            color="error"
            display="block"
            onClick={() => dispatchRemoveTeam(team)}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      )}
    </ListItem>
  );
}

export default React.memo(Team);
