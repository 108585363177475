import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { GoChevronRight } from "react-icons/go";
import PropTypes from "prop-types";
import { Card, CardBody, Link } from "./atomic";

const FlexCardBody = styled(CardBody)`
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.neutral1};
`;

const IconContainer = styled.div`
  display: inline-flex;
  color: ${props => props.theme.colors.primary};
  font-size: 1.5em;
`;

const InteractiveCard = styled(Card)`
  cursor: pointer;
`;

const LinkCard = ({ children, to, history, Icon, ...rest }) => (
  <InteractiveCard as={Link} onClick={() => history.push(to)} {...rest}>
    <FlexCardBody display="flex">
      <div>{children}</div>
      {Icon && <IconContainer>{Icon}</IconContainer>}
    </FlexCardBody>
  </InteractiveCard>
);

LinkCard.propTypes = {
  to: PropTypes.string,
  history: PropTypes.object,
  children: PropTypes.any.isRequired,
  Icon: PropTypes.object,
};

LinkCard.defaultProps = {
  to: null,
  history: {},
  Icon: <GoChevronRight />,
};

export default withRouter(LinkCard);
