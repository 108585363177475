import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import LoginForm from "../components/LoginForm";
import { login } from "../actions/auth";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Link as LinkText,
  HeadingThree,
} from "../components/atomic";

export const Login = ({ dispatchLogin, user }) => {
  if (user.id) {
    return <Redirect push to="/" />;
  }

  return (
    <Container>
      <Row center="xs">
        <Col xs={12} md={10}>
          <HeadingThree align="center">Login to your account</HeadingThree>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} md={10}>
          <Card>
            <CardBody>
              <LoginForm onSubmit={dispatchLogin} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} md={10} align="right">
          <LinkText as={Link} to="/forgot">
            Forgot your password?
          </LinkText>
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  dispatchLogin: PropTypes.func,
  user: PropTypes.object,
};

Login.defaultProps = {
  dispatchLogin: () => {},
  user: {},
};

export default connect(
  state => {
    const { user } = state;

    return {
      user,
    };
  },
  { dispatchLogin: login },
)(Login);
