import React, { useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { withFormik } from "formik";
import * as yup from "yup";
import { GoCheck } from "react-icons/go";
import { Input, Button, getAbsolutePosition, Portal, Row, Col } from "../atomic";

const ScoreFormContainer = styled.div`
  position: absolute;
  left: calc(${props => props.location.left}px - 1.5em);
  top: calc(${props => props.location.top}px - 0.75em);
  z-index: 5;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 4em;
  }
`;

function ScoreForm({
  values,
  forwardRef,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  isSubmitting,
  isValid,
  onForfeit,
  id,
  triggerRef,
}) {
  const scoreValue = useRef(values.score);
  const inputRef = useRef();
  const location = useMemo(() => getAbsolutePosition(triggerRef.current), [triggerRef]);

  useEffect(() => {
    const node = inputRef.current;
    const score = scoreValue.current;
    node.focus();

    if (score !== null) {
      node.setSelectionRange(0, node.value != null ? node.value.length : 0);
    }
  }, [scoreValue, inputRef]);

  return (
    <Portal>
      <ScoreFormContainer id={id} location={location} ref={forwardRef}>
        <form onSubmit={handleSubmit}>
          <Row alignItems="center">
            <Col xs={12} sm={3}>
              <Input
                id="score"
                name="score"
                align="center"
                placeholder="0"
                value={values.score}
                invalid={touched.score && !!errors.score}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                ref={inputRef}
                pattern="\d*"
              />
            </Col>
            <Col xs={12} sm={3}>
              <Button type="submit" color="success" disabled={!isValid || isSubmitting}>
                <GoCheck />
              </Button>
            </Col>
            {onForfeit && (
              <Col xs={12} sm={6}>
                <Button
                  color="error"
                  onClick={e => {
                    e.preventDefault();
                    onForfeit();
                  }}
                  disabled={isSubmitting}
                >
                  Forfeit
                </Button>
              </Col>
            )}
          </Row>
        </form>
      </ScoreFormContainer>
    </Portal>
  );
}

const mapPropsToValues = ({ score }) => {
  const defaults = {
    score: null,
  };

  return { ...defaults, score };
};

const validationSchema = yup.object().shape({
  score: yup.string(),
});

export default withFormik({
  isInitialValid: props => validationSchema.isValidSync(mapPropsToValues(props)),
  mapPropsToValues,
  validationSchema,
  handleSubmit: async (values, { props }) => {
    const { onSubmit } = props;
    const { score } = values;

    if (onSubmit) await onSubmit(score);
  },
})(ScoreForm);
