import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

const BlockTimePickerContainer = styled.div`
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    display: block;
  }

  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-left: 0;
  }

  & .react-datepicker__time-container {
    width: 80px;
  }
`;

const BlockTimePicker = props => (
  <BlockTimePickerContainer inputGroup>
    <DatePicker {...props} />
  </BlockTimePickerContainer>
);

export default BlockTimePicker;
