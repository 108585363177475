import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ToggleContainer = styled.div`
  text-align: ${props => props.align};
`;
ToggleContainer.displayName = "ToggleContainer";
ToggleContainer.defaultProps = {
  align: "inherit",
};

class Toggle extends Component {
  static propTypes = {
    defaultActive: PropTypes.bool,
    children: PropTypes.func.isRequired,
    identifier: PropTypes.string,
    disableEscape: PropTypes.bool,
    disableClickOff: PropTypes.bool,
  };

  static defaultProps = {
    defaultActive: false,
    identifier: null,
    disableEscape: false,
    disableClickOff: false,
  };

  state = {
    active: this.props.defaultActive,
  };

  containerRef = React.createRef();

  toggle = state => {
    this.setState(prevState => {
      const active = typeof state === "boolean" ? state : !prevState.active;

      if (active) {
        this.bindListeners();
      } else {
        this.unbindListeners();
      }

      return { active };
    });
  };

  closeOnEvent = event => {
    const { identifier } = this.props;
    const ignoreElement = document.getElementById(identifier);
    const currentRef = this.containerRef.current;

    const handleClick =
      event.type === "click" &&
      currentRef &&
      !currentRef.contains(event.target) &&
      (!ignoreElement || !ignoreElement.contains(event.target));

    const handleEscape = event.keyCode === 27;

    if (handleClick || handleEscape) {
      this.toggle(false);
    }
  };

  bindListeners = () => {
    const { disableEscape, disableClickOff } = this.props;

    if (!disableEscape) {
      document.addEventListener("keydown", this.closeOnEvent);
    }

    if (!disableClickOff) {
      document.addEventListener("click", this.closeOnEvent);
    }
  };

  unbindListeners = () => {
    const { disableEscape, disableClickOff } = this.props;

    if (!disableEscape) {
      document.removeEventListener("keydown", this.closeOnEvent);
    }

    if (!disableClickOff) {
      document.removeEventListener("click", this.closeOnEvent);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    const { active } = this.state;

    return (
      <ToggleContainer ref={this.containerRef} {...rest}>
        {children({ active, toggle: this.toggle })}
      </ToggleContainer>
    );
  }
}

export default Toggle;
