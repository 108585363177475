import styled from "styled-components";
import Text from "./Text";

const LinkButton = styled(Text)`
  text-decoration: ${props => (props.isDisabled ? "none" : "underline")};
  cursor: pointer;
  background-color: transparent;
  border: none;
  align-items: center;
  flex-shrink: 0;
  pointer-events: ${props => (props.isDisabled ? "none" : "auto")};
  padding: ${props => props.padding};
  ${props =>
    !props.isDisabled && props.underline ? props.theme.colors[props.color] : "transparent"};
  ${props =>
    props.ellipsis &&
    `
      text-overflow: ellipsis;
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
    `}
  ${props => (props.isDisabled ? "pointer-events: none;" : null)}
`;
LinkButton.defaultProps = {
  underline: false,
  color: "neutral0",
  padding: "0.25em",
  isDisabled: false,
};

export default LinkButton;
