import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getFinals } from "../selectors/finals";
import { getRounds } from "../selectors/rounds";
import { getDivisions } from "../selectors/teams";
import { getCompetitionByID } from "../selectors/competitions";
import { getIsClubOwner } from "../selectors/user";
import { createBatch as createBatchFinals } from "../actions/finals";
import { DisabledParagraph } from "../components/atomic";
import CreateFinalsForm from "../components/Finals/CreateFinalsForm";
import CreateFinalsPreview from "../components/Finals/CreateFinalsPreview";
import FinalsWrapper from "../components/Finals/FinalsWrapper";
import useCounter from "../hooks/useCounter";
import { calculateLadders } from "../../shared/utils/ladder";

function Finals() {
  const { clubId, seasonId, competitionId } = useParams();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(4);
  const [date, setDate] = useState(new Date());
  const { value: step, increment: incrementStep } = useCounter(0);

  const competition = useSelector(getCompetitionByID(competitionId));
  const teamsByDivision = useSelector(getDivisions);
  const rounds = useSelector(getRounds);
  const finals = useSelector(getFinals);
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  const submitTemplate = useCallback(
    ({ limit: _limit, date: _date }) => {
      setLimit(_limit);
      setDate(_date);
      incrementStep();
    },
    [setLimit, setDate, incrementStep],
  );

  const createFinalRounds = useCallback(
    async newRounds =>
      dispatch(
        createBatchFinals(clubId, seasonId, competitionId, {
          rounds: newRounds,
        }),
      ),
    [dispatch, clubId, seasonId, competitionId],
  );

  const ladders = calculateLadders(teamsByDivision, rounds, competition.exclusions);

  if (finals.length === 0) {
    return isAuthenticated ? (
      <>
        {step === 0 && <CreateFinalsForm limit={limit} date={date} onSubmit={submitTemplate} />}
        {step === 1 && (
          <CreateFinalsPreview
            ladders={ladders}
            date={date}
            limit={limit}
            onSubmit={createFinalRounds}
          />
        )}
      </>
    ) : (
      <DisabledParagraph>{`There aren't any finals yet for ${competition.name}...`}</DisabledParagraph>
    );
  }

  return <FinalsWrapper />;
}

export default connect()(Finals);
