import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "../../typography/Link";

const TabsContainer = styled.ul`
  color: ${props => props.color};
  display: flex;
  padding: 0;
  margin: 1rem 0 0 0;
`;

TabsContainer.defaultProps = {
  color: "primary",
};

const Tab = styled.li`
  text-align: center;
  list-style: none;
  border-top-left-radius: ${props => props.theme.radius.element};
  border-top-right-radius: ${props => props.theme.radius.element};
  font-weight: ${props => (props.active ? "bold" : "normal")};
  background-color: ${props => (props.active ? props.theme.colors.neutral5 : "transparent")};
  cursor: pointer;
`;

const Item = styled(Link)`
  min-width: 10em;
  padding: 0.5em;
  display: inline-block;
`;

const StandardTabs = ({ tabs, onChange, activeTabId }) => (
  <TabsContainer>
    {tabs.map(tab => (
      <Tab key={tab.id} active={activeTabId === tab.id} onClick={() => onChange(tab.id)}>
        <Item underline={false}>{tab.name}</Item>
      </Tab>
    ))}
  </TabsContainer>
);

StandardTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  activeTabId: PropTypes.string,
};

StandardTabs.defaultProps = {
  onChange: () => {},
  activeTabId: null,
};

StandardTabs.displayName = "StandardTabs";

export default StandardTabs;
