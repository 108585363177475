import styled from "styled-components";

const Input = styled.input`
  border-radius: ${props => props.theme.radius.input};
  padding: 0.5em 0.75em;
  border: 1px solid ${props => props.theme.colors.neutral3};
  display: ${props => props.display};
  width: 100%;
  text-align: ${props => props.align};
  line-height: 1.5em;
`;

Input.defaultProps = {
  type: "text",
  align: "initial",
  display: "block"
};

export default Input;
