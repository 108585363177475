import { types as AuthActions } from "../actions/auth";
import { types as RoundsActions } from "../actions/rounds";
import AsyncStatus from "../constants/AsyncStatus";

export const initialState = {
  list: [],
  list_MODERN: {},
  status: {},
};

const RoundsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case `${RoundsActions.get}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${RoundsActions.get}_FULFILLED`: {
      const { payload } = action;
      const list = state.list.filter(round => round.id !== payload.id).concat(payload);
      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      const ids = list.map(item => item.id);

      return { ...state, list, list_MODERN, ids, status, fetching: false };
    }

    // UPDATE
    case `${RoundsActions.update}_PENDING`: {
      const { meta } = action;
      const status = {
        ...state.status,
        [meta.id]: AsyncStatus.pending,
      };

      return { ...state, status };
    }
    case `${RoundsActions.update}_FULFILLED`: {
      const { payload } = action;
      const existingIndex = state.list.findIndex(round => round.id === payload.id);

      if (existingIndex < 0) {
        return { ...state };
      }

      const list = [
        ...state.list.slice(0, existingIndex),
        payload,
        ...state.list.slice(existingIndex + 1),
      ];

      const status = {
        ...state.status,
        [payload.id]: AsyncStatus.success,
      };

      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          [item.id]: item,
          ...aggregate,
        }),
        {},
      );

      const ids = list.map(item => item.id);

      return { ...state, list, list_MODERN, ids, status, fetching: false };
    }

    // DELETE
    case `${RoundsActions.remove}_PENDING`: {
      const { meta } = action;
      const status = { ...state.status, [meta.id]: AsyncStatus.pending };

      return { ...state, status };
    }
    case `${RoundsActions.remove}_FULFILLED`: {
      const { meta } = action;
      const list = state.list.filter(round => round.id !== meta.id);

      const status = {
        ...state.status,
        [meta.id]: AsyncStatus.success,
      };

      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      const ids = list.map(item => item.id);

      return { ...state, list, list_MODERN, ids, status, fetching: false };
    }

    // FETCH
    case `${RoundsActions.fetch}_PENDING`: {
      return { ...state, fetching: true };
    }
    case `${RoundsActions.fetch}_FULFILLED`: {
      const status = action.payload.reduce(
        (result, round) => ({
          ...result,
          [round.id]: AsyncStatus.success,
        }),
        {},
      );

      const list = action.payload;
      const list_MODERN = action.payload.reduce(
        (aggregate, item) => ({
          ...aggregate,
          [item.id]: item,
        }),
        {},
      );

      const ids = list.map(item => item.id);

      return { list, list_MODERN, ids, status, fetching: false };
    }
    case `${RoundsActions.fetch}_REJECTED`: {
      return { ...state, fetching: false };
    }

    // CREATE BATCH
    case `${RoundsActions.createBatch}_FULFILLED`: {
      const { payload } = action;
      const { rounds } = payload;
      const list = rounds.concat(state.list);
      const status = { [payload.id]: AsyncStatus.success, ...state.status };
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          [item.id]: item,
          ...aggregate,
        }),
        {},
      );

      const ids = list.map(item => item.id);

      return { ...state, list, list_MODERN, ids, status, fetching: false };
    }

    // SET
    case RoundsActions.set: {
      const status = action.payload.reduce(
        (result, round) => ({
          ...result,
          [round.id]: AsyncStatus.success,
        }),
        {},
      );
      const list = action.payload;
      const list_MODERN = list.reduce(
        (aggregate, item) => ({
          [item.id]: item,
          ...aggregate,
        }),
        {},
      );

      return { list, list_MODERN, status, fetching: false };
    }
    // LOGOUT
    case `${AuthActions.logout}_FULFILLED`:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default RoundsReducer;
