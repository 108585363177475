import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer/polyfilled";
import { getIsClubOwner } from "../../selectors/user";
import Points from "./Points";
import {
  Card,
  CardHeader,
  CardBody,
  HeadingFive,
  Table,
  TableBody,
  TableHeader,
  TableHeading,
  TableRow,
  TableData,
  ScrollContainer
} from "../atomic";

const FixedColumnContainer = styled.div`
  display: inline-block;
  z-index: 1;
  position: absolute;
  max-width: 40%;
  background-color: ${props => props.theme.colors.neutral5};
  box-shadow: ${props =>
    !props.isScrollable
      ? "none"
      : `16px 0 8px -16px ${props.theme.colors.neutral2}`};
`;

const FixedCell = styled.div`
  display: inline-block;
`;

const Ladder = ({ title, ladder, onAdjustmentSubmit, ...rest }) => {
  const [isScrollable, setScrollable] = useState(false);
  const [teamColumnWidth, setTeamColumnWidth] = useState(0);

  const { ref: scrollRef } = useResizeObserver({
    onResize: rect => {
      setScrollable(
        scrollRef.current.scrollWidth > scrollRef.current.offsetWidth
      );
    }
  });

  const { ref: teamNameRef } = useResizeObserver({
    onResize: rect => {
      setTeamColumnWidth(rect.width);
    }
  });

  const { clubId } = useParams();
  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  return (
    <Card border="neutral4" {...rest}>
      <CardHeader>
        <HeadingFive>{title}</HeadingFive>
      </CardHeader>
      <CardBody>
        <FixedColumnContainer isScrollable={isScrollable}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeading bold>#</TableHeading>
                <TableHeading bold ref={teamNameRef}>
                  Team
                </TableHeading>
              </TableRow>
            </TableHeader>
            <TableBody>
              {ladder.map(team => (
                <TableRow key={team.team.id}>
                  <TableData>{team.ranking}</TableData>
                  <TableData breakword>{team.team.name}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FixedColumnContainer>
        <ScrollContainer ref={scrollRef}>
          <Table
            style={{
              width: "100%"
            }}
          >
            <TableHeader>
              <TableRow>
                <TableHeading bold invisible>
                  #
                </TableHeading>
                <TableHeading bold invisible>
                  <FixedCell style={{ width: `${teamColumnWidth}px` }}>
                    Team
                  </FixedCell>
                </TableHeading>
                <TableHeading bold>P</TableHeading>
                <TableHeading bold>W</TableHeading>
                <TableHeading bold>D</TableHeading>
                <TableHeading bold>L</TableHeading>
                <TableHeading bold>F</TableHeading>
                <TableHeading bold>B</TableHeading>
                <TableHeading bold>PF</TableHeading>
                <TableHeading bold>PA</TableHeading>
                <TableHeading bold>PD</TableHeading>
                <TableHeading bold>Adj</TableHeading>
                <TableHeading bold>Pts</TableHeading>
                <TableHeading bold>SR</TableHeading>
              </TableRow>
            </TableHeader>
            <TableBody>
              {ladder.map(team => (
                <TableRow key={team.team.id}>
                  <TableData invisible>{team.ranking}</TableData>
                  <TableData invisible breakword>
                    {team.team.name}
                  </TableData>
                  <TableData>{team.gamesPlayed}</TableData>
                  <TableData>{team.gamesWon}</TableData>
                  <TableData>{team.gamesDrawn}</TableData>
                  <TableData>{team.gamesLost}</TableData>
                  <TableData>{team.gamesForfeit}</TableData>
                  <TableData>{team.gamesByed}</TableData>
                  <TableData>{team.pointsFor}</TableData>
                  <TableData>{team.pointsAgainst}</TableData>
                  <TableData>{team.pointsDifference}</TableData>
                  <TableData>{team.team.pointAdjustment}</TableData>
                  <TableData>
                    <Points
                      id={`${team.team.id}-points`}
                      isDisabled={!isAuthenticated}
                      unadjustedPoints={team.totalPoints}
                      onSubmit={pointAdjustment =>
                        onAdjustmentSubmit({ ...team.team, pointAdjustment })
                      }
                    >
                      {team.adjustedPoints}
                    </Points>
                  </TableData>
                  <TableData>{team.strikeRate}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollContainer>
      </CardBody>
    </Card>
  );
};

export default connect()(Ladder);
