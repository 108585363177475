import styled from "styled-components";
import Text from "./Text";

const HeadingFour = styled(Text).attrs({ as: "h4" })`
  font-weight: 300;
  display: block;
  margin: 0.25em 0;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.25em;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1.5em;
  }
`;

export default HeadingFour;
