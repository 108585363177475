import React, { useEffect, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { useMediaQuery } from "react-responsive";
import { withTheme } from "styled-components";
import { Page, Container, NavBar, NavItem } from "../components/atomic";
import AuthGate from "../gates/AuthGate";
import UserNavItem from "../components/Navigation/UserNavItem";
import ClubHeader from "../components/Navigation/ClubHeader";
import AppSpinner from "../components/AppSpinner";
import { getClubByID, getClubStatusById } from "../selectors/clubs";
import { getSeasonByID, getSeasonStatusByID } from "../selectors/seasons";
import {
  getCompetitionByID,
  getCompetitionStatusByID
} from "../selectors/competitions";
import { getUser, getIsAuthenticated } from "../selectors/user";
import { get as getClub } from "../actions/clubs";
import { get as getSeason } from "../actions/seasons";
import { get as getCompetition } from "../actions/competitions";
import AsyncStatus from "../constants/AsyncStatus";
import MobileContext from "../context/MobileContext";

function AppContent({ route, theme }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);

  const clubsMatch = useRouteMatch("/clubs/:clubId(\\d+)");
  const seasonsMatch = useRouteMatch(
    "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)"
  );
  const competitionsMatch = useRouteMatch(
    "/clubs/:clubId(\\d+)/seasons/:seasonId(\\d+)/competitions/:competitionId(\\d+)"
  );

  const clubId = clubsMatch != null ? clubsMatch.params.clubId : null;
  const seasonId = seasonsMatch != null ? seasonsMatch.params.seasonId : null;
  const competitionId =
    competitionsMatch != null ? competitionsMatch.params.competitionId : null;

  const club = useSelector(getClubByID(clubId));
  const clubStatus = useSelector(getClubStatusById(clubId));
  const season = useSelector(getSeasonByID(seasonId));
  const seasonStatus = useSelector(getSeasonStatusByID(seasonId));
  const competition = useSelector(getCompetitionByID(competitionId));
  const competitionStatus = useSelector(
    getCompetitionStatusByID(competitionId)
  );

  const isReady =
    (clubId == null || club != null) &&
    (seasonId == null || season != null) &&
    (competitionId == null || competition != null);

  useEffect(() => {
    if (clubId && club == null && clubStatus !== AsyncStatus.pending) {
      dispatch(getClub(clubId));
    }

    if (seasonId && season == null && seasonStatus !== AsyncStatus.pending) {
      dispatch(getSeason(clubId, seasonId));
    }

    if (
      competitionId &&
      competition == null &&
      competitionStatus !== AsyncStatus.pending
    ) {
      dispatch(getCompetition(clubId, seasonId, competitionId));
    }
  }, [
    clubId,
    club,
    seasonId,
    season,
    competitionId,
    competition,
    dispatch,
    clubStatus,
    competitionStatus,
    seasonStatus
  ]);

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`
  });

  const mobileContextValue = useMemo(() => ({ isMobile }), [isMobile]);

  return (
    <MobileContext.Provider value={mobileContextValue}>
      <Page>
        <NavBar
          LeftItems={
            isAuthenticated
              ? [
                  <NavItem key="clubs" as={Link} to="/clubs">
                    Clubs
                  </NavItem>
                ]
              : undefined
          }
          RightItems={[<UserNavItem key="user" user={user} />]}
        />
        <Container>
          {club && (
            <ClubHeader club={club} season={season} competition={competition} />
          )}
          {!isReady ? <AppSpinner /> : renderRoutes(route.routes)}
        </Container>
      </Page>
    </MobileContext.Provider>
  );
}

const StyledAppContent = withTheme(AppContent);

const AppContainer = ({ route }) => (
  <AuthGate authRoute="/login">
    <StyledAppContent route={route} />
  </AuthGate>
);

export default connect()(AppContainer);
