import React from "react";
import PropTypes from "prop-types";
import { GoIssueOpened } from "react-icons/go";
import { Text, Tooltip } from "../atomic";

const Clashes = ({ id, clashes, ...rest }) => (
  <Tooltip
    Trigger={
      <Text color="error">
        <GoIssueOpened id={id} {...rest} />
      </Text>
    }
  >
    Clashes with: {clashes.map(clash => clash.name).join(", ")}
  </Tooltip>
);

Clashes.propTypes = {
  id: PropTypes.string.isRequired,
  clashes: PropTypes.array,
};

Clashes.defaultProps = {
  clashes: [],
};

export default Clashes;
