import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DropdownItemContainer = styled.div`
  display: block;
  padding: 0.75em 1em;
  background-color: inherit;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${props =>
        props.static ? "inherit" : props.theme.colors.neutral4};
    }
  }
`;
DropdownItemContainer.displayName = "DropdownItemContainer";

const DropdownItem = ({ onClick, ...rest }) => (
  <DropdownItemContainer
    {...rest}
    onClick={event => {
      event.stopPropagation();

      onClick();
    }}
  />
);
DropdownItem.propTypes = {
  onClick: PropTypes.func
};

DropdownItem.defaultProps = {
  onClick: () => {}
};

export default DropdownItem;
