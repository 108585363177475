import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { GoKebabVertical } from "react-icons/go";
import {
  fetch as fetchCompetitions,
  update as updateCompetition,
  paginate as paginateCompetitions,
} from "../actions/competitions";
import { getClubByID } from "../selectors/clubs";
import { getSeasonByID } from "../selectors/seasons";
import { getIsClubOwner } from "../selectors/user";
import { getActiveCompetitions, getArchivedCompetitions } from "../selectors/competitions";
import CompetitionStatus from "../constants/CompetitionStatus";
import LinkCard from "../components/LinkCard";
import ArchivedCompetitions from "../components/Competitions/ArchivedCompetitions";
import {
  HeadingFour,
  Card,
  CardBody,
  DisabledParagraph,
  Button,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "../components/atomic";
import AppSpinner from "../components/AppSpinner";
import AsyncStatus from "../constants/AsyncStatus";
import { getAppStatus } from "../selectors/status";

function Competitions({ status }) {
  const { clubId, seasonId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const club = useSelector(getClubByID(clubId));
  const season = useSelector(getSeasonByID(seasonId));
  const activeCompetitions = useSelector(getActiveCompetitions);
  const archivedCompetitions = useSelector(getArchivedCompetitions);

  const isAuthenticated = useSelector(getIsClubOwner(clubId));

  useEffect(() => {
    dispatch(
      fetchCompetitions(clubId, seasonId, {
        status: CompetitionStatus.active,
      }),
    );
  }, [club, dispatch, clubId, season, seasonId]);

  const fetchArchivedCompetitions = useCallback(() => {
    dispatch(
      paginateCompetitions(clubId, seasonId, {
        status: CompetitionStatus.archived,
      }),
    );
  }, [dispatch, clubId, seasonId]);

  const editCompetition = useCallback(
    competitionId => {
      history.push(`${competitionId}/edit`);
    },
    [history],
  );

  const archiveCompetition = useCallback(
    competition => {
      dispatch(
        updateCompetition(clubId, seasonId, {
          ...competition,
          status: CompetitionStatus.archived,
        }),
      );
    },
    [dispatch, clubId, seasonId],
  );

  const makeCompetitionActive = useCallback(
    competition => {
      dispatch(
        updateCompetition(clubId, seasonId, {
          ...competition,
          status: CompetitionStatus.active,
        }),
      );
    },
    [dispatch, clubId, seasonId],
  );

  const isPending = status === AsyncStatus.pending;

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <HeadingFour color="primary" bold>
                Competitions
              </HeadingFour>
              {isAuthenticated && (
                <Button as={Link} to="competitions/add">
                  Add New Competition
                </Button>
              )}
            </CardBody>
          </Card>
        </Col>
        {isPending && <AppSpinner />}
        {!isPending &&
          activeCompetitions.map(competition => (
            <Col xs={12} key={competition.id}>
              <LinkCard
                to={`/clubs/${clubId}/seasons/${seasonId}/competitions/${competition.id}`}
                Icon={
                  isAuthenticated ? (
                    <DropdownMenu Trigger={<GoKebabVertical />}>
                      <DropdownItem as={Link} to={`competitions/${competition.id}/edit`}>
                        Edit
                      </DropdownItem>
                      <DropdownItem onClick={() => archiveCompetition(competition)}>
                        Archive
                      </DropdownItem>
                    </DropdownMenu>
                  ) : (
                    undefined
                  )
                }
              >
                {competition.name}
              </LinkCard>
            </Col>
          ))}
        {!isPending && activeCompetitions.length === 0 && (
          <DisabledParagraph>{`There doesn't seem to be any Active Competitions yet in ${season.name}...`}</DisabledParagraph>
        )}
      </Row>
      {!isPending && (
        <Row>
          <Col xs={12}>
            <ArchivedCompetitions title="Archived Competitions" onClick={fetchArchivedCompetitions}>
              <Row>
                {archivedCompetitions.length === 0 && (
                  <DisabledParagraph>{`There aren't any Archived Competitions in ${season.name}...`}</DisabledParagraph>
                )}
                {archivedCompetitions.length > 0 &&
                  archivedCompetitions.map(competition => (
                    <Col xs={12} key={competition.id}>
                      <LinkCard
                        to={`/clubs/${clubId}/seasons/${seasonId}/competitions/${competition.id}`}
                        Icon={
                          isAuthenticated ? (
                            <DropdownMenu Trigger={<GoKebabVertical />}>
                              <DropdownItem onClick={() => editCompetition(competition.id)}>
                                Edit
                              </DropdownItem>
                              <DropdownItem onClick={() => makeCompetitionActive(competition)}>
                                Unarchive
                              </DropdownItem>
                            </DropdownMenu>
                          ) : (
                            undefined
                          )
                        }
                      >
                        {competition.name}
                      </LinkCard>
                    </Col>
                  ))}
              </Row>
            </ArchivedCompetitions>
          </Col>
        </Row>
      )}
    </>
  );
}

export default connect(state => ({
  status: getAppStatus(state),
}))(Competitions);
