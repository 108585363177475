import React from "react";
import styled from "styled-components";
import { BeatLoader } from "react-spinners";
import Theme from "../theme";

const StyledLoader = styled(BeatLoader)``;
const Spinner = ({ color, ...rest }) => {
  const colorString = Theme.colors[color];

  return <StyledLoader color={colorString} {...rest} />;
};

Spinner.defaultProps = {
  size: 14,
  color: Theme.colors.primary,
};

export default Spinner;
