import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Portal from "../utility/Portal";
import Card from "./Card";
import Overlay from "../utility/Overlay";
import EscapeListener from "../utility/EscapeListener";

const ModalContainer = styled(Card)`
  max-width: 500px;
  margin: 1em auto;
`;

const Modal = ({ isOpen, children, toggle, ...rest }) => (
  <Portal>
    {isOpen && (
      <EscapeListener onEscape={toggle}>
        <Overlay onClick={toggle}>
          <ModalContainer onClick={e => e.stopPropagation()} {...rest}>
            {children}
          </ModalContainer>
        </Overlay>
      </EscapeListener>
    )}
  </Portal>
);

Modal.propTypes = {
  children: PropTypes.any,
};
Modal.defaultProps = {
  children: null,
};

export default Modal;
