import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

const BlockDatePickerContainer = styled.div`
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    display: block;
  }
`;

const BlockDatePicker = props => (
  <BlockDatePickerContainer>
    <DatePicker {...props} dateFormat="dd/MM/yyyy" />
  </BlockDatePickerContainer>
);

export default BlockDatePicker;
