import { createAction } from "redux-actions";
import authAPI from "../api/auth";

export const types = {
  login: "AUTH_LOGIN",
  logout: "AUTH_LOGOUT",
  forgot: "AUTH_FORGOT",
  reset: "AUTH_RESET",
};

export const login = createAction(types.login, authAPI.login);
export const logout = createAction(types.logout, authAPI.logout);
export const forgot = createAction(types.forgot, authAPI.forgot);
export const reset = createAction(types.reset, authAPI.reset);
