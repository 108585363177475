import styled from "styled-components";

const Checkbox = styled.input`
  display: ${props => props.display};

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  &:not(:last-child) {
    margin-right: 0.5em;
  }
`;

Checkbox.defaultProps = {
  type: "checkbox",
  display: "inline-block",
};

export default Checkbox;
