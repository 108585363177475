import styled from "styled-components";

const Select = styled.select`
  border-radius: ${props => props.theme.radius.input};
  padding: 0.5em 0.75em;
  border: 1px solid ${props => props.theme.colors.neutral3};
  display: block;
  width: 100%;
  line-height: 1.5em;
  background-color: ${props => props.theme.colors.neutral5};
  height: calc(2.5em + 2px);
`;

export default Select;
