import styled from "styled-components";

const ListItem = styled.li`
  background-color: ${props => props.theme.colors[props.color]};
  display: ${props => props.display};
  justify-content: space-between;
  align-items: center;
  padding: 0.75em;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors[props.border]};
  }
`;

ListItem.defaultProps = {
  color: "transparent",
  border: "transparent",
  display: "flex",
};

export default ListItem;
