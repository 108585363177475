import React from "react";
import ImportExample from "../../../images/import-example.png";
import { ModalBody, Paragraph } from "../../atomic";

const ImportTeamsStep2 = () => (
  <ModalBody>
    <Paragraph>
      Now, fill in the Form with the required details. Check out the image below as a guide:
    </Paragraph>
    <img src={ImportExample} width="100%" alt="How a completed Team Import Form should look" />
    <Paragraph>Make sure you fill in every field, as they are all needed.</Paragraph>
  </ModalBody>
);

export default ImportTeamsStep2;
